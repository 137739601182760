// export const setting = {
//     apiUrl: "http://10.237.0.68:5101/start_calculation"
// };

function getApiAdres() {
    return document.location.origin+"/api/";
}

export const setting = {
    apiUrl: getApiAdres()
};