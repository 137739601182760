import React from "react";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

function ProductMenuMobile() {

    const history = useHistory();
    const curr = history.location.pathname;
    const currs = curr.split('/');
    const data = useSelector(state => state.load.data);

    return(
        <div>
            <div className="pt-5"/>
            <div className="modals product onmobile">
                    <div className="container d-flex mobilemenus">
                        <a href="../products/flowviewer" className="project_href ">
                            <p className={currs['2'] === 'flowviewer' ? "modal_project_begin active" : 'modal_project_begin'}>{data && data.ProductMenu[0].upmenu[1]}</p>
                            <p className="bottom_flow_menu">{data && data.ProductMenu[0].undermenu[1]}</p>
                        </a>
                        <a href="../products/biometer" className="project_href ">
                            <p className={currs['2'] === 'biometer' ? "modal_project_begin active" : 'modal_project_begin'}>{data && data.ProductMenu[0].upmenu[0]}</p>
                            <p className="bottom_flow_menu">{data && data.ProductMenu[0].undermenu[0]}</p>
                        </a>
                    </div>
            </div>
            <div className="pb-5"/>

        </div>
    )
}

export default ProductMenuMobile;
