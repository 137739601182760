import React from "react";
import logo from "../../../images/demo/pages/biglogo.svg";
import web from "../../../images/demo/pages/web.svg";
import contact from "../../../images/demo/pages/contact.svg";

function Biome() {
    return (
        <section className="section-left">
            <div className="d-flex align-items-center pt-5 pb-5 mt-3 w-100">
                <img className="img-fluid mr-auto" src={logo} alt=""/>
                {/*<div className="button-group">*/}
                {/*    <a href="#" className="button-company d-flex align-items-center position-relative mb-2"*/}
                {/*       target="_blank">*/}
                {/*        <img className="position-absolute mlbi" src={web} alt=""/>*/}
                {/*        <p className="mb-0 mlb m-auto">Website</p>*/}
                {/*    </a>*/}
                {/*    <a href="#" className="button-company d-flex align-items-center position-relative mb-2"*/}
                {/*       target="_blank">*/}
                {/*        <img className="position-absolute mlbi" src={contact} alt=""/>*/}
                {/*        <p className="mb-0 mlb m-auto">Contact</p>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
            <p className="mb-0 pt-3 pb-2 text-white">
                Electronic health records contain valuable information about patient’s health: it’s current state, past
                dynamics that has led to observable conditions, and future trends. The problem is that the amount of
                data is too big for a clinician to be able to identify relevant component, gather and process it, all in
                a limited time period. That is why BioME offers the solution in form of deep learning algorithms to
                analyze electronic health records stored in medical information systems for accurate prediction and
                diagnosis of various medical conditions. Machine learning (specifically, artificial neural network-based
                deep learning) is rapidly becoming the most powerful tool of digital personalized medicine, as it
                recognizes patterns in massive volumes of data. Therefore, clinical decision powered by BioME models is
                guaranteed to be the most informed.
            </p>
            <hr style={{background: "#6B7BB5"}}/>
            <h1 className="model-name pt-2 pb-2">MODELS</h1>
            <div className="d-flex align-items-center">
                <button onClick={() => document.location.href = "/demo/model/?id=type2diabet"}
                        className="model-btn d-flex align-items-center">
                    <p className="mb-0 ml-4">Type 2 Diabetes Model</p>
                </button>
            </div>
        </section>
    );
}

export default Biome;