import React from "react";

function ResultAcidBaseHistory({ result }) {
    return (
        "diagnosis" in result &&
        <div className="result-model-history pt-2">
            <div className="form-result-history">
                <table className="result-model-table" cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th>Measurement</th>
                            <th>Value</th>
                            <th>Unit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            [...result.measurements].sort((a, b) => {
                                const titleA = a.title.toLowerCase();
                                const titleB = b.title.toLowerCase();

                                if (titleA < titleB) return -1;
                                if (titleA > titleB) return 1;
                                return 0;
                            }).map((item, index) => (
                                <tr key={index}>
                                    <td dangerouslySetInnerHTML={{ __html: item.title }}></td>
                                    <td>{item.value}</td>
                                    <td>{item.unit}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <b>Diagnosis: {result.diagnosis}</b>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

    );
}

export default ResultAcidBaseHistory;