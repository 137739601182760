import React from "react";
import close from "../../../../images/demo/pages/closeresult.svg";
import ResultViewThree from "../ResultViewThree";

function Result({result, closeResult, min, max, middle, moderate, data}) {
    return (
        result.length > 0 &&
        <div className="result-model">
            <div className="inset-result">
                <div className="form-result">
                    <img onClick={() => closeResult()}
                         className="close-result" src={close} alt=""/>
                    <h1 className="title-result">ASCVD Model</h1>
                    <h1 className="title-result">calculation results</h1>
                    {
                        result.map((r, index) =>
                                r.data ?
                                <div key={index} className="mb-4 mt-4">
                                    {
                                        r.data.map((rd, index) =>
                                                <div key={index}>
                                                    <ResultViewThree dataText={data[0].ascvd} max={max} min={min} middle={middle} moderate={moderate} data={rd.result}/>
                                                </div>
                                        )
                                    }
                                </div> :
                                    <div key={index} className="mb-4 mt-4">
                                        <h1 className="title-result text-danger">Data error</h1>
                                    </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Result;