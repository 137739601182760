
import React from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import TopArrow from "../TopArrow/TopArrow";
import News1 from "../News/News1";
import News2 from "../News/News2";
import News3 from "../News/News3";
import News4 from "../News/News4";
import { useSelector } from "react-redux";

function PagePrivacyPolicy(props) {

    const { history } = props.history;
    const data = useSelector(state => state.load.data);

    return (
        <div>
            <title>{data && data.PagePrivacyPolicy[0].pageTitle}</title>
            <MobileMenu history={history} />
            <Menu history={history} />
            <div id="products" className="container pt-5 pb-5">
                <p className="begin_product pt-5">{data && data.PagePrivacyPolicy[0].caption}</p>
            </div>
            <div className="container">
                <div className="current_new">
                    <div className="end_current_news">
                        <b>
                            1. What personal data is processed?
                        </b>
                        <p>
                            The personal data concerning you that are collected and processed are only depending on the contact form service of the https://biome-science.com/ website, namely: your surname, first name, title, email address and telephone number (hereinafter the “Data”). No cookies are used in the website.
                        </p>
                        <b>2. How do we collect your personal data?</b>
                        <p>These data are collected on the basis of your consent.</p>
                        <b>3. Who is responsible for the processing of this data?</b>
                        <p>The data controller is the company that defines for what purpose and how your personal data is used. The personal data collected on the website are processed by BIOME.</p>
                        <b>4. Why do we collect your personal data?</b>
                        <p>Your Data is collected in order to: Send you information on our offers, news and events; Carry out statistics and audience measurements. Typically, this data shall be used to exchange with those who are interested in our products.</p>
                        <b>5. To whom do we pass on your personal data?</b>
                        <p>Your Data is processed only by BIOME and shall not be transferred or made accessible by any third party.</p>
                        <b>6. What are your rights?</b>
                        <p>In accordance with the regulations on the protection of personal data, you have the right to access and rectify your Data, as well as the right to request its deletion, to oppose its processing and to obtain its limitation or portability insofar as applicable.
                            <br />
                            These rights may be exercised directly with BIOME by emailing: info@biome-science.com
                            Proof of identity may be requested. If you have any questions about the collection and processing of your Data by BIOME, you can contact the Data Protection Officer by email.
                            <br />
                            You also have the right to refer any complaint relating to the way BIOME collects and processes your Data to the National Information Science and Liberties Commission (CNIL), 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.</p>
                        <b>7. How long do we keep your personal data?</b>
                        <p>The Data shall be kept by BIOME for a period that are not exceeding the applicable legal prescription time, i.e. 12 months.</p>
                    </div>
                </div>
            </div>


            <div className="pb-5" />
            <Footer />
            <TopArrow />
        </div>
    );
}

export default PagePrivacyPolicy;
