
    import React from "react";
    import numedy from './numedy.png';
    import kaggle from './kaggle.png';
    import vpn from './vpn.png';
    
    function Partner() {
        // return (
        //     <section className="wow animate__fadeIn" data-wow-duration="2s">
        //         <div className="container">
        //             <div className="wrap_partner">
        //                 <img className="partner" src={numedy} alt=""/>
        //                 <img className="partner" src={kaggle} alt=""/>
        //                 <img className="partner" src={vpn} alt=""/>
        //             </div>
        //         </div>
        //     </section>
        // );
        return <div className="mb-5"></div>
    }

export default Partner;
