import React from "react";
import Menu from "../Menu/Menu";
import MobileMenu from "../MobileMenu/MobileMenu";
import Footer from "../Footer/Footer";
import TopArrow from "../TopArrow/TopArrow";
import ProductMenu from "../ProductMenu/ProductMenu";
import ProductMenuMobile from "../ProductMenuMobile/ProductMenuMobile";
import {useSelector} from "react-redux";

function CurrBiometer(props) {

    const { history } = props.history;
    const data = useSelector(state => state.load.data);

    return (
        <div>
            <title>{data && data.CurrBiometer[0].pageTitle}</title>
            <MobileMenu history={history}/>
            <Menu history={history}/>
            <ProductMenu history={history}/>
            <div className="container">
                <div className="wrap-product">
                    <div id="product1" className="product_card productm">
                        <div className="current_card">
                            <div className="wrap_text_card order-1">
                                <div className="text_card">
                                    <div className="begin_products wow animate__slideInRight" data-wow-duration="2s">{data && data.CurrBiometer[0].caption}
                                    </div>
                                    <div className="back_gradient wow animate__slideInRight" data-wow-duration="2s"/>
                                </div>
                                <div className="begin_text_product">{data && data.CurrBiometer[0].subtitle}</div>
                                <div className="text_product">
                                    {data && data.CurrBiometer[0].text[0]}
                                </div>
                            </div>
                            <div id="product_img1" className="wrap_notext_card"/>
                        </div>
                    </div>
                </div>
                <div className="item_image" id="item1">
                    <img src="../images/product/bio1.jpg" className="image_productes" alt=""/>
                </div>
                <div className="row pb-5 mb-5">
                    <div className="col">
                            <p className="project_about">
                                {data && data.CurrBiometer[0].text[1]}
                            </p>
                    </div>
                    <div className="col">
                            <p className="project_about">
                                {data && data.CurrBiometer[0].text[2]}
                            </p>
                    </div>
                </div>
                <div className="item_image" id="item2">
                    <img src="../images/product/bio2.jpg" className="image_productes" alt=""/>
                </div>
                <div className="row pb-5 mb-5">
                    <div className="col">
                        <p className="project_about">
                            {data && data.CurrBiometer[0].text[3]}
                        </p>
                    </div>
                    <div className="col">
                        <p className="project_about">
                            {data && data.CurrBiometer[0].text[4]}
                        </p>
                    </div>
                </div>
                <div className="item_image" id="item3">
                    <img src="../images/product/bio3.jpg" className="image_productes" alt=""/>
                </div>
                <div className="row pb-5 mb-5">
                    <div className="col">
                        <p className="project_about">
                            {data && data.CurrBiometer[0].text[5]}
                        </p>
                    </div>
                </div>
                <div className="bottom_btn_gen">
                    <a href="/contact" className="btn-violet">
                        {data && data.CurrBiometer[0].btn}
                    </a>
                </div>
            </div>

            <ProductMenuMobile history={history}/>
            <Footer/>
            <TopArrow/>
        </div>
    );
}

export default CurrBiometer;
