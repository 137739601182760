import React from "react";

function ResultViewThree({data, max, min, middle, moderate, dataText, print}) {

    const getColours = () => {
        if (data <= moderate - 0.1) {
            return "#08D140";
        } else if (data <= middle - 0.1) {
            return "#FF9900";
        } else {
            return "#FF0000";
        }
    };

    const getRisk = () => {
        if (data <= moderate - 0.1) {
            return dataText[0];
        } else if (data <= middle - 0.1) {
            return dataText[1];
        }else {
            return dataText[2];
        }
    };

    const getM = () => {
      return 100 / max;
    };

    return (
        <React.Fragment>
            <div className="listen-result-wrap mb-3">
                <div className="listen-result-container">
                    <div className="listen-result">
                        <div className="lines-green" style={{width: getM() * moderate + "%"}}/>
                        <div className="lines-orange" style={{width: getM() * (middle - moderate) + "%"}}/>
                        <div className="lines-red" style={{width: 100 - (getM() * middle) + "%"}}/>

                        <div className="lines-green-line" style={{left: min + "%"}}>
                            <p className="lines-green-line-text mb-0">{min}</p>
                        </div>

                        <div className="lines-red-orange" style={{left: getM() * moderate + "%"}}>
                            <p className="lines-orange-line-text mb-0">{moderate}</p>
                        </div>

                        <div className="lines-red-line" style={{left: getM() * middle + "%"}}>
                            <p className="lines-red-line-text mb-0">{middle}</p>
                        </div>

                        <div className="results" style={{left: data > 20 ? 100+"%" : getM() * data + "%"}}>
                            <div className="d-grid">
                                <svg className="marker" width="6" height="31" viewBox="0 0 18 19" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 0L9 19L0 0H18Z" style={{fill: getColours()}}/>
                                </svg>
                                <div className="result-line" style={{background: getColours()}}/>
                                <div className="lines-red-result-line-text text-white" style={{background: getColours()}}>{data}</div>
                            </div>
                        </div>
                        <div className="lines-red-line" style={{left: 100 + "%"}}>
                            <p className="lines-red-final-line-text mb-0">{max}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center pb-3">
                <div className={print ? "result-nums mr-4 print-border":"result-nums mr-4"} style={{color: getColours()}}>
                    {data}%
                </div>
                <div style={{width: "35rem"}}>
                    <div className="d-flex align-items-center">
                        <h1 className="result-about mr-1" style={{color: getColours()}}>{getRisk().title}</h1>
                        <h1 className={print ? "result-about print":"result-about"}>risk</h1>
                    </div>
                    <p className={print ? "mb-0 text-white print":"mb-0 text-white"}>
                        {getRisk().text}
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ResultViewThree;