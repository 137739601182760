import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {setting_auth} from "../../setting_auth";


function Authentication(props) {
    const apiUrl = setting_auth.apiUrl;
    const btn_center = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    };
    const styleMsgSuccess = {
        color: '#FFFFFF',
        fontSize: "18px",
        fontWeight: "400",
        paddingLeft: '15px'
    };
    const styleMsgError = {
        fontStyle: "normal",
        fontSize: "18px",
        color: "red",
        fontWeight: "400",
    };

    const {history} = props.history;
    const dataStore = useSelector(state => state.load.data);
    const [msgSuccess, setMsgSuccess] = useState('');
    const [msgError, setMsgError] = useState('');

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({
        defaultValues: {
            email: "",
            name: "",
            surname: "",
            phone: "",
            about: ""
        }
    });

    const onSubmit = async function (data, event) {
        event.preventDefault();
        console.log('onSubmit')
        // console.log(dataStore);
        //
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                {
                    email: data.email,
                    name: data.name,
                    surname: data.surname,
                    phone: data.phone,
                    about: data.about
                })
        };
        fetch(apiUrl + 'save_user', requestOptions)
            .then(response => response.json())
            .then(response => {
                    console.log(response);
                    if (response.saveUser) {
                        console.log(dataStore.Registration[0].success);
                        setMsgSuccess(dataStore.Registration[0].success);
                        setMsgError('');
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 5000)
                    } else {
                        setMsgSuccess('');
                        setMsgError(dataStore.Registration[0].error);
                    }
                }
            );
    }

    const keys = window.localStorage.getItem('key');
    return (
        !keys ?
            <React.Fragment>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div class="wrap-registr
                            col-md-8 mb-4"
                    >

                        <div id="registr"><p className="begin_registr">REGISTRATION</p></div>
                    <div className="col-md-8 mb-4">
                        <label>{dataStore && dataStore.Registration[0].email}</label>
                        <input
                            className={errors.email || msgError!=='' ? "name form-control error" : "name form-control" }
                            placeholder="__@__.__"
                            {...register("email", {
                                required: true,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })}
                            type="email"/>
                        <div className={"text_registr_error"}>
                            {errors.email && <p>{dataStore && dataStore.Registration[0].errorEmail}</p> }
                        </div>
                        <div  style={styleMsgError}>
                            {msgError}
                        </div>
                    </div>
                    <div className="col-md-8 mb-4">
                        <label>{dataStore && dataStore.Registration[0].name}</label>
                        <input
                            className={errors.name ? "name form-control error" : "name form-control" }
                               {...register("name", {
                                required: true,
                            })}
                            type="text"/>
                        <div className={"text_registr_error"}>
                            {errors.name && <p>{dataStore && dataStore.Registration[0].errorRequired}</p> }
                        </div>
                    </div>
                    <div className="col-md-8 mb-4">
                        <label>{dataStore && dataStore.Registration[0].surname}</label>
                        <input
                            className="name form-control"
                            {...register("surname")}
                            type="text"/>
                    </div>
                    <div className="col-md-8 mb-4">
                        <label>{dataStore && dataStore.Registration[0].phone}</label>
                        <input className="name form-control"
                               placeholder="_ (___) ___-__-__"
                               {...register("phone")}
                               type="phone"/>
                    </div>
                    <div className="col-md-8 mb-4">
                        <label>{dataStore && dataStore.Registration[0].about}</label>
                        <textarea
                            className={errors.about ? "name form-control error" : "name form-control" }
                            {...register("about", {required: true})}/>
                        <div className={"text_registr_error"}>
                            {errors.about && <p>{dataStore && dataStore.Registration[0].errorRequired}</p> }
                        </div>
                    </div>
                     <div className="col-md-8 mb-4" style={styleMsgSuccess}>
                        {msgSuccess}
                    </div>

                    <div className="col col-md-8"
                         style={btn_center}>
                        <input className="btn-violet text-center mt-3 mb-4"
                               type="submit" value={dataStore && dataStore.Registration[0].signIn}/>
                        <a href="/login" class="top_menu_href">
                            {dataStore && dataStore.Registration[0].logIn}
                        </a>
                    </div>
                    </div>
                </form>
            </React.Fragment> :
            window.location.replace('/demo')
    )
}

export default Authentication;

