import React, {useEffect, useState} from "react";
import axios from "axios";
import {setting} from "../../../CurrBiometer/Demo/setting";
import InputDate from "../Inputs/InputDate";
import InputNumber from "../Inputs/InputNumber";
import InputSelect from "../Inputs/InputSelect";
import defaultParam from './defaultParam';
import input from './input';
import ResultScore from "../Inputs/ResultScore";

function Score() {
    const apiUrl = setting.apiUrl;
    const [block, setBlock] = useState(1);
    const [birthday_time, setBirthday_time] = useState(false);
    const [gender, setGender] = useState(false);
    const [cholesterol, setCholesterol] = useState(false);
    const [sbp, setSbp] = useState(false);
    const [result, setResult] = useState([]);
    const [findPram, setFindParam] = useState(true);
    const [isDefault, setDefault] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [defaultTexts, setDefaultTexts] = useState(false);

    useEffect(() => {
        findParam();
    }, [birthday_time, gender, cholesterol, sbp]);

    const findParam = () => {
        if (birthday_time && gender && cholesterol && sbp) {
            setFindParam(true);
        } else {
            setFindParam(false);
        }
    };

    const start_calculation = () => {
        setShowResult(true);
        const dates = new Date();
        const bt = new Date(birthday_time);
        const year = dates.getFullYear();
        const month = dates.getMonth() + 1;
        if (month <= 9) {
            var month_ = "0" + (Number(month));
        } else {
            var month_ = Number(month);
        }
        const dat = dates.getDate();
        if (dat <= 9) {
            var dat_ = "0" + (Number(dat));
        } else {
            var dat_ = Number(dat);
        }
        const hour = dates.getHours();
        if (hour <= 9) {
            var hour_ = "0" + (Number(hour));
        } else {
            var hour_ = Number(hour);
        }
        const min = dates.getMinutes();
        if (min <= 9) {
            var min_ = "0" + (Number(min));
        } else {
            var min_ = Number(min);
        }
        const sec = dates.getSeconds();
        if (sec <= 9) {
            var sec_ = "0" + (Number(sec));
        } else {
            var sec_ = Number(sec);
        }

        const date = year + '-' + month_ + '-' + dat_ + ' ' + hour_ + ':' + min_ + ':' + sec_;

        const data = {
            "service_name": "biometer",
            "biometer_run_item_id": 11,
            "biometer_run_result_id": 781568844,
            "models": [
                {
                    "model": {
                        "service_name": "biometer",
                        "biometer_run_item_id": 11,
                        "biometer_run_result_id": 781568844,
                        "technical_model_name": "ScoreAbsoluteRiskValue",
                        "biometer_model_item_id": 8979879,
                        "birthday_day": bt.getDate(),
                        "birthday_month": bt.getMonth() + 1,
                        "birthday_year": bt.getFullYear(),
                        "gender_id": Number(gender)
                    },
                    "data": [
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 37,
                            "id_result": 313131,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(cholesterol),
                            "reference_interval_min": 4,
                            "reference_interval_max": 8,
                            "measure_time": date
                        },
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 1581,
                            "id_result": 12312312,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(sbp),
                            "reference_interval_min": 110,
                            "reference_interval_max": 180,
                            "measure_time": date
                        }
                    ]
                },
                {
                    "model": {
                        "service_name": "biometer",
                        "biometer_run_item_id": 11,
                        "biometer_run_result_id": 781568844,
                        "technical_model_name": "ScoreRelativeRiskValue",
                        "biometer_model_item_id": 8979879,
                        "birthday_day": bt.getDate(),
                        "birthday_month": bt.getMonth() + 1,
                        "birthday_year": bt.getFullYear(),
                        "gender_id": Number(gender)
                    },
                    "data": [
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 37,
                            "id_result": 313131,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(cholesterol),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        },
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 1581,
                            "id_result": 12312312,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(sbp),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        }
                    ]
                },
            ],
            "create_time": date
        };

        var UH = [];
        const UH_r = JSON.parse(window.localStorage.getItem("usage_history"));

        if (UH_r !== null) {
            UH = UH_r;
        }

        axios.post(apiUrl, data)
            .then(result => {
                setFindParam(false);
                setResult(result.data.models);

                const result_ = {
                    id: Math.floor(Math.random() * (999999 - 111111) + 111111),
                    title: "Score",
                    date: dat_ + '-' + month_ + '-' + year,
                    time: hour_ + ':' + min_,
                    result: result.data.models
                };
                UH.push(result_);
                window.localStorage.setItem("usage_history", JSON.stringify(UH));
            });
    };

    function getAge(dateString, min, max) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return !(age < min || age > max);
    }

    const setValueByType = (type, value) => {
        switch (type) {
            case "birthday_time":
                getAge(value, 20, 65) ? setBirthday_time(value) : setBirthday_time(false);
                break;
            case "gender":
                setGender(value);
                break;
            case "cholesterol":
                setCholesterol(value);
                break;
            case "sbp":
                setSbp(value);
                break;
        }
    };

    const setDefaultBySelect = (id) => {
        if (id !== "0") {
            const find = defaultParam.find(item => item.id == id);
            const finds = defaultText.find(item => item.id == id);
            setDefaultTexts(finds.text);
            setDefault(id);
            setResult(find.result);
            setBirthday_time(find.birthday_time);
            setGender(find.gender);
            setCholesterol(find.cholesterol);
            setSbp(find.sbp);

            const dates = new Date();
            const year = dates.getFullYear();
            const month = dates.getMonth() + 1;
            if (month <= 9) {
                var month_ = "0" + (Number(month));
            } else {
                var month_ = Number(month);
            }
            const dat = dates.getDate();
            if (dat <= 9) {
                var dat_ = "0" + (Number(dat));
            } else {
                var dat_ = Number(dat);
            }
            const hour = dates.getHours();
            if (hour <= 9) {
                var hour_ = "0" + (Number(hour));
            } else {
                var hour_ = Number(hour);
            }
            const min = dates.getMinutes();
            if (min <= 9) {
                var min_ = "0" + (Number(min));
            } else {
                var min_ = Number(min);
            }
            const sec = dates.getSeconds();
            if (sec <= 9) {
                var sec_ = "0" + (Number(sec));
            } else {
                var sec_ = Number(sec);
            }

            var UH = [];
            const UH_r = JSON.parse(window.localStorage.getItem("usage_history"));

            if (UH_r !== null) {
                UH = UH_r;
            }

            const result_ = {
                id: Math.floor(Math.random() * (999999 - 111111) + 111111),
                title: "Score",
                date: dat_ + '-' + month_ + '-' + year,
                time: hour_ + ':' + min_,
                result: find.result
            };
            UH.push(result_);
            window.localStorage.setItem("usage_history", JSON.stringify(UH));
        } else {
            setDefaultTexts(false);
            setDefault(false);
            setResult([]);
            setBirthday_time(false);
            setGender(false);
            setCholesterol(false);
            setSbp(false);
            document.getElementById("input-date").value = " ";
            document.getElementById("input-gender").getElementsByTagName('option')[0].selected = true;
            document.getElementById("default").getElementsByTagName('option')[0].selected = true;
            document.getElementById("input-cholesterol").value = " ";
            document.getElementById("input-sbp").value = " "
        }
    };

    const closeResult = () => {
        setShowResult(false);
    };

    const getByType = (key, type, title, uid, utype, setValueByType, unit, min, max, step, isDefault = false) => {
        switch (type) {
            case "date":
                return <InputDate key={key} title={title}
                                  uid={uid}
                                  utype={utype}
                                  setValueByType={setValueByType}
                                  isDefault={isDefault}
                />;
            case "select":
                return <InputSelect key={key} title={title}
                                    uid={uid}
                                    unit={unit}
                                    setValueByType={setValueByType}
                                    utype={utype}
                                    isDefault={isDefault}
                />;
            case "number":
                return <InputNumber key={key} title={title}
                                    uid={uid}
                                    unit={unit}
                                    utype={utype}
                                    step={step}
                                    setValueByType={setValueByType}
                                    min={min}
                                    max={max}
                                    isDefault={isDefault}
                />;
        }
    };

    const data = [
        {
            absolute: [
                {
                    title: "Low",
                    text: "To maintain current low risk, it is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <140 mmHg, LDL-C <2.6 mmol/L."
                },
                {
                    title: "High",
                    text: "It is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <130 mmHg, LDL-C <1.8 mmol/L."
                },
                {
                    title: "Very high",
                    text: "It is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <130 mmHg, LDL-C <1.4 mmol/L."
                },
            ],
            relative: [
                {
                    title: "Low",
                    text: "To maintain current low risk, it is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <140 mmHg, LDL-C <2.6 mmol/L"
                },
                {
                    title: "High",
                    text: "It is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <130 mmHg, LDL-C <1.8 mmol/L."
                }
            ]
        }
    ];

    const defaultText = [
        {
            id: 1,
            text: "Male, 55. Examination of Patient 1 shows high fatal CVD risk (16%) despite previously chosen risk reduction strategy (statin use). Based on obtained results, the specialist decides to increase the dose of statins and sets the date of the next appointment to assess SCORE risk and, therefore, the effectiveness of current therapy mode."
        },
        {
            id: 2,
            text: "Female, 40. BioMeter is used by a cardiologist to communicate to the patient the significance of certain risk factors and to demonstrate the effect of risk factors variations on fatal CVDs risk."
        }
    ];

    return (
        <section className="section-left">
            {
                showResult &&
                <ResultScore data={data}
                             result={result} closeResult={closeResult} min={0} max={47} middle={9} moderate={5}/>
            }
            <div className="d-flex align-items-center pt-5 pb-3 mt-3 w-100">
                <h1 className="mr-auto text-white">Score</h1>
            </div>

            <div className="switch_block">
                <div className="d-flex align-items-center ml-auto" style={{width: "fit-content"}}>
                    <button onClick={() => setBlock(1)}
                            className={block === 1 ? "switch-btn active" : "switch-btn"}>description
                    </button>
                    <button onClick={() => setBlock(2)}
                            className={block === 2 ? "switch-btn active" : "switch-btn"}>context
                    </button>
                    <button onClick={() => setBlock(3)}
                            className={block === 3 ? "switch-btn active" : "switch-btn"}>reference
                    </button>
                </div>
                {
                    block === 1 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            Systematic Coronary Risk Evaluation algorithm developed by European Society of Cardiology
                            estimates 10-year risk of fatal cardiovascular event. According to clinical recommendations,
                            effective prevention of cardiovascular disease (CVD) relies on total risk estimation, rather
                            than assessment of individual risk factors impact. SCORE algorithm, derived as a result of
                            European SCORE project, assesses risk of fatal CVD outcomes based on combination of several
                            risk factors: age, sex, smoking status, plasma total cholesterol and systolic blood
                            pressure. These variables have several important characteristics: a) they reflect both prior
                            (age-dependent) and adjusted (influenced by lifestyle choices and genetic predisposition)
                            risks; b) information about these risk factors can be easily obtained in practically every
                            clinical setting, making SCORE one of the most affordable clinical risk assessment tools.
                        </p>
                    </div>
                }
                {
                    block === 2 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            The model is intended for use by primary care providers and cardiologists for either
                            first-time examination or follow-up of patients as a part of screening for CVD. Obtained
                            results serve as rationale for recommending medications or other treatment strategies, as
                            well as basis for having data-supported doctor-patient risk conversation. Depending on
                            patient’s age, resulting risk is either relative (ages 20-39) or absolute (ages 40-65). The
                            correct calculation algorithm is selected automatically based on input, and appropriate risk
                            type is shown.
                        </p>
                    </div>
                }
                {
                    block === 3 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            Conroy RM, Pyörälä K, Fitzgerald AP, Sans S, Menotti A, De Backer G, De Bacquer D,
                            Ducimetière P, Jousilahti P, Keil U, Njølstad I, Oganov RG, Thomsen T, Tunstall-Pedoe H,
                            Tverdal A, Wedel H, Whincup P, Wilhelmsen L, Graham IM; SCORE project group. Estimation of
                            ten-year risk of fatal cardiovascular disease in Europe: the SCORE project. Eur Heart J.
                            2003 Jun;24(11):987-1003. doi: 10.1016/s0195-668x(03)00114-3.
                        </p>
                    </div>
                }
            </div>
            <div className="parameter">
                <div className="select-wrapper">
                    <select id="default" className="form-control default-case"
                            onChange={(e) => setDefaultBySelect(e.target.value)}>
                        <option value="0">Select case</option>
                        <option value="1">Patient 1, male, 55 years old</option>
                        <option value="2">Patient 2, female, 40 years old</option>
                    </select>
                    <div className="select"/>
                </div>
                {
                    isDefault &&
                    <div className="about-default">
                        {defaultTexts}
                    </div>
                }
                <div className="text-center pt-3">

                    <div className="d-flex align-items-center text-left">
                        {
                            input.map((i, index) =>
                                isDefault ?
                                    getByType(index, i.type, i.title, i.uid, i.utype, setValueByType, i.unit, i.min, i.max, i.step, defaultParam[isDefault - 1]) :
                                    getByType(index, i.type, i.title, i.uid, i.utype, setValueByType, i.unit, i.min, i.max, i.step)
                            )
                        }
                    </div>
                    {
                        isDefault ?
                            <button className="submit-model mt-4" onClick={() => setShowResult(true)}>Submit</button> :
                            findPram ?
                                <button className="submit-model mt-4"
                                        onClick={() => start_calculation()}>Submit</button> :
                                <button className="submit-model mt-4 disabled" disabled>Submit</button>
                    }
                </div>
            </div>
        </section>
    );
}

export default Score;