
    import React from "react";

function TopArrow() {
    return (
        <div className="to_top_arrow active">
            <img src="../images/totop.png" alt=""/>
        </div>
    );
}

export default TopArrow;
