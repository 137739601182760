import React from "react";
import './MetaModel.css';
import sb from '../../../../images/demo/bottomspawn.svg'
import PieComponentsHistory from "./PieComponentsHistory";

function ResultViewMetaModelHistory({result, meta}) {
    const data = result[0].data[0].result;
    const images = result[0].data[0].result.images;

    return (
        <div className="wrap-meta-model-result-history">
            <div className="inset-model-history">
                {
                    meta ?
                        <div className="rows-history">
                            {
                                meta == 1 &&
                                <div className="spider-spawn-history">
                                    <img className="spider" src={'data:image/png;base64,' + images} alt=""/>
                                    <img className="sb" src={sb} alt=""/>
                                </div>
                            }

                            {
                                meta == 2 &&
                                <div className="wrap-switcher-history">
                                    <div className="inset-switcher-back-history">
                                        <PieComponentsHistory print={true} id={1} data={data}/>
                                        <PieComponentsHistory print={true} id={2} data={data}/>
                                        <PieComponentsHistory print={true} id={3} data={data}/>
                                        <PieComponentsHistory print={true} id={4} data={data}/>
                                        <PieComponentsHistory print={true} id={5} data={data}/>
                                    </div>
                                </div>
                            }

                        </div> :
                        <div className="rows-history">
                            <div className="spider-spawn-history">
                                <img className="spider" src={'data:image/png;base64,' + images} alt=""/>
                                <img className="sb" src={sb} alt=""/>
                            </div>
                            <div className="wrap-switcher-history">
                                <div className="inset-switcher-back-history">
                                    <PieComponentsHistory print={true} id={1} data={data}/>
                                    <PieComponentsHistory print={true} id={2} data={data}/>
                                    <PieComponentsHistory print={true} id={3} data={data}/>
                                    <PieComponentsHistory print={true} id={4} data={data}/>
                                    <PieComponentsHistory print={true} id={5} data={data}/>
                                </div>
                            </div>
                        </div>
                }

            </div>
        </div>
    );
}

export default ResultViewMetaModelHistory;