import React, {useState} from "react";
import show from "../../images/demo/show.svg";
import delet from "../../images/demo/delete.svg";
import ResultScoreHistory from "../DemoPages/Models/Inputs/ResultScoreHistory";
import ResultHistory from "../DemoPages/Models/Inputs/ResultHistory";
import ResultSimpleHistory from "../DemoPages/Models/Inputs/ResultSimpleHistory";
import {useDispatch} from "react-redux";
import {setCheck, setDelete} from "../../store/reducer";
import ResultViewMetaModelHistory from "../DemoPages/Models/MetaModelResult/ResultViewMetaModelHistory";
import ResultAcidBaseHistory from "../DemoPages/Models/Inputs/ResultAcidBaseHistory";

function HistoryItem({id, title, date, time, data, isPrint}) {
    const dispatch = useDispatch();
    const score = [
        {
            absolute: [
                {
                    title: "Low",
                    text: "To maintain current low risk, it is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <140 mmHg, LDL-C <2.6 mmol/L."
                },
                {
                    title: "High",
                    text: "It is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <130 mmHg, LDL-C <1.8 mmol/L."
                },
                {
                    title: "Very high",
                    text: "It is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <130 mmHg, LDL-C <1.4 mmol/L."
                },
            ],
            relative: [
                {
                    title: "Low",
                    text: "To maintain current low risk, it is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <140 mmHg, LDL-C <2.6 mmol/L"
                },
                {
                    title: "High",
                    text: "It is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <130 mmHg, LDL-C <1.8 mmol/L."
                }
            ]
        }
    ];

    const ascvd = [
        {
            ascvd: [
                {title: "Low", text: "No statin recommended"},
                {title: "Moderate", text: "Moderate statin dose recommended"},
                {title: "High", text: "High-intensity statin recommended"},
            ]
        }
    ];

    const diabet = {
        diabet: [
            {
                title: "Low",
                text: "It is important to maintain current lifestyle and medication choices. Regular risk estimation is advised to prevent the onset of diabetes"
            },
            {title: "High", text: "It is strongly recommended to undergo comprehensive laboratory diabetes screening"},
        ]
    };

    const getResults = () => {
        switch (title) {
            case "Score":
                return <ResultScoreHistory data={score} result={data} min={0} max={47} middle={9} moderate={5}/>
                break;
            case "ASCVD Risk Model":
                return <ResultHistory data={ascvd} result={data} min={0} max={20} middle={7.5} moderate={5}/>
                break;
            case "Type 2 Diabetes Model":
                return <ResultSimpleHistory data={diabet} result={data} min={0} max={50} middle={7.5}/>
                break;
            case "MetaModel":
                return <ResultViewMetaModelHistory result={data}/>
                break;
            case "Acid-base disorders calculator":
                console.log(data);
                return <ResultAcidBaseHistory result={data}/>
                break;
        }
    }

    const [showR, setshowR] = useState(false);

    const setChecks = (e) => {
        const value = e.target.checked;
        dispatch(setCheck({
            id: id,
            value: value
        }))
    };

    const setDeletes = () => {
        dispatch(setDelete({
            id: id
        }))
    };

    return (
        <React.Fragment>
            <div className="history-item d-flex align-items-center">
                <p className="mb-0 history-item-title">{title}</p>
                <p className="mb-0 history-item-date">{date}</p>
                <p className="mb-0 history-item-time">{time}</p>
                <div className="history-item-add">
                    <input onChange={(e) => setChecks(e)}
                           id={"history-item-check-" + id} type="checkbox" className="history-item-check"
                           defaultChecked={isPrint}/>
                    <label className="label-check" htmlFor={"history-item-check-" + id}>Add to the report</label>
                </div>
                <div onClick={() => setshowR(!showR)}
                     className={showR ? "history-item-show active" : "history-item-show"}>
                    <p className="mb-0 mt-2">Show results</p>
                    <img className="m-auto" src={show} alt=""/>
                </div>
                <div className="history-item-delete" onClick={() => setDeletes()}>
                    <p className="mb-0 mt-2">Delete report</p>
                    <img className="m-auto" src={delet} alt=""/>
                </div>
            </div>
            {showR && getResults()}
        </React.Fragment>
    );
}

export default HistoryItem;