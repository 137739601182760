import React from "react";
import {useSelector} from "react-redux";

function Project() {

    const style1 = {order: 1, backgroundImage: 'url(../images/news/1.jpg)'};
    const style2 = {order: 2, backgroundImage: 'url(../images/news/2.jpg)'};
    const style3 = {order: 4, backgroundImage: 'url(../images/news/3.jpg)'};
    const style4 = {order: 1, backgroundImage: 'url(../images/news/4.jpg)'};

    const data = useSelector(state => state.load.data);

    return (
        <section id="project" className="wow animate__fadeIn" data-wow-duration="2s">
            <div className="container">
                <p className="begin_product pt-5 pb-5">{data && data.Project[0].caption}</p>
                <div className="wrap_news_flex">
                    <div className="wrap_news">
                        <a href="../project/news1">
                            <div className="left_news round_shadow"
                                 style={style1}>
                                <div className="all_news">
                                    <p className="begin_news">{data && data.Project[0].news1[0]}</p>
                                    <p className="end_news">{data && data.Project[0].news1[1]}<br/><br/></p>
                                    <div className="news_btn">
                                        <a className="btn_news_href" href="../project/news1">{data && data.Project[0].newsbtn}</a>
                                    </div>
                                </div>
                                <div className="bottom_line_news">
                                        <p className="about_news">{data && data.Project[0].news1[0]}
                                        </p>
                                </div>
                            </div>
                        </a>
                        <a href="../project/news2">
                            <div className="right_news round_shadow"
                                 style={style2}>
                                <div className="all_news">
                                    <p className="begin_news">{data && data.Project[0].news2[0]}</p>
                                    <p className="end_news">{data && data.Project[0].news2[1]}<br/><br/></p>
                                <div className="news_btn">
                                    <a className="btn_news_href" href="../project/news2">{data && data.Project[0].newsbtn}</a>
                                </div>
                                </div>
                                <div className="bottom_line_news">
                                        <p className="about_news">{data && data.Project[0].news2[0]}
                                        </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <a href="../project/news3">
                        <div className="right_news round_shadow"
                             style={style3}>
                            <div className="all_news">
                                <p className="begin_news">{data && data.Project[0].news3[0]}</p>
                                <p className="end_news">{data && data.Project[0].news3[1]}<br/><br/></p>
                            <div className="news_btn">
                                <a className="btn_news_href" href="../project/news3">{data && data.Project[0].newsbtn}</a>
                            </div>
                            </div>
                            <div className="bottom_line_news">
                                <p className="about_news">{data && data.Project[0].news3[0]}
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="wrap_news">
                    <a href="../project/news4">
                        <div className="left_news round_shadow"
                             style={style4}>
                            <div className="all_news">
                                <p className="begin_news">{data && data.Project[0].news4[0]}</p>
                                <p className="end_news">
                                    {data && data.Project[0].news4[1]}<sup>1</sup><br/><br/>
                                    {data && data.Project[0].news4[2]}<br/><br/>
                                    {data && data.Project[0].news4[3]}<br/><br/>
                                    {data && data.Project[0].news4[4]}<br/><br/>
                                    {data && data.Project[0].news4[5]}<br/><br/>
                                    {data && data.Project[0].news4[6]}
                                </p>
                                <div className="news_btn">
                                        <a className="btn_news_href" href="../project/news4">{data && data.Project[0].newsbtn}</a>
                                </div>
                            </div>
                            <div className="bottom_line_news">
                                <p className="about_news">{data && data.Project[0].news4[0]}
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );

}

    export default Project;
