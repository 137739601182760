import React from "react";
import ResultScoreHistory from "../DemoPages/Models/Inputs/ResultScoreHistory";
import ResultHistory from "../DemoPages/Models/Inputs/ResultHistory";
import ResultSimpleHistory from "../DemoPages/Models/Inputs/ResultSimpleHistory";
import ResultViewMetaModelHistory from "../DemoPages/Models/MetaModelResult/ResultViewMetaModelHistory";
import logo from "../../images/logoprint.png";

function HistoryItemPrint({title, date, time, data, meta}) {

    const score = [
        {
            absolute: [
                {
                    title: "Low",
                    text: "To maintain current low risk, it is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <140 mmHg, LDL-C <2.6 mmol/L."
                },
                {
                    title: "High",
                    text: "It is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <130 mmHg, LDL-C <1.8 mmol/L."
                },
                {
                    title: "Very high",
                    text: "It is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <130 mmHg, LDL-C <1.4 mmol/L."
                },
            ],
            relative: [
                {
                    title: "Low",
                    text: "To maintain current low risk, it is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <140 mmHg, LDL-C <2.6 mmol/L"
                },
                {
                    title: "High",
                    text: "It is recommended to adhere to healthy lifestyle, including moderate-intensity physical activity, healthy diet. Target biomarker levels: SBP <130 mmHg, LDL-C <1.8 mmol/L."
                }
            ]
        }
    ];

    const ascvd = [
        {
            ascvd: [
                {title: "Low", text: "No statin recommended"},
                {title: "Moderate", text: "Moderate statin dose recommended"},
                {title: "High", text: "High-intensity statin recommended"},
            ]
        }
    ];

    const diabet = {
        diabet: [
            {
                title: "Low",
                text: "It is important to maintain current lifestyle and medication choices. Regular risk estimation is advised to prevent the onset of diabetes"
            },
            {title: "High", text: "It is strongly recommended to undergo comprehensive laboratory diabetes screening"},
        ]
    };

    const getResults = () => {
        switch (title) {
            case "Score":
                return <ResultScoreHistory print={true} data={score} result={data} min={0} max={47} middle={9} moderate={5}/>
                break;
            case "ASCVD Risk Model":
                return <ResultHistory print={true} data={ascvd} result={data} min={0} max={20} middle={7.5} moderate={5}/>
                break;
            case "Type 2 Diabetes Model":
                return <ResultSimpleHistory print={true} data={diabet} result={data} min={0} max={50} middle={4}/>
                break;
            case "MetaModel":
                return <ResultViewMetaModelHistory result={data} meta={meta}/>
                break;
        }
    }

    return (
        <div className="item-print">
            <img src={logo} className="pb-4 pt-4 w-100" alt="BioME"/>
            <div className="history-item-print d-flex align-items-center">
                <p className="mb-0 history-item-title print-white">{title}</p>
                <p className="mb-0 history-item-date print-white">{date}</p>
                <p className="mb-0 history-item-time print-white">{time}</p>
            </div>
            {getResults()}
        </div>
    );
}

export default HistoryItemPrint;