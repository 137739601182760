import React, {useEffect, useState} from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import Menu from "../Menu/Menu";
import {useSelector, useDispatch} from "react-redux";
import {setFromHistory} from "../../store/reducer";
import AsideMenu from "../CurrBiometer/Demo/DemoPage/AsideMenu";
import title from '../../images/demo/pages/title.png';
import Footer from "../Footer/Footer";
import "./History.css";
import his from '../../images/demo/history.svg';
import HistoryItem from "./HistoryItem";
import download from '../../images/demo/icon/download.svg';
import Printer, {print} from 'react-pdf-print'
import HistoryItemPrint from "./HistoryItemPrint";
import HistoryItemPrintMetaFirst from "./HistoryItemPrintMetaFirst";
import Authentication from "../Authentication/Authentication";

function History(props) {
    const {history} = props.history;
    const dispatch = useDispatch();
    const data = useSelector(state => state.load.data);
    const uhistory = useSelector(state => state.load.uhistory);

    const usage_history = JSON.parse(window.localStorage.getItem("usage_history"));

    const [ids, setIds] = useState([]);

    useEffect(() => {
        dispatch(setFromHistory(usage_history));
    }, []);


    useEffect(() => {
        const r = [];
        for (let i = 0; i < uhistory.length; i++) {
            if (uhistory[i].isPrint) {
                if (uhistory[i].title === "MetaModel") {
                    r.push((uhistory[i].id).toString());
                    r.push((uhistory[i].id + 1).toString());
                } else {
                    r.push((uhistory[i].id).toString());
                }
            }
        }

        setIds(r);
    }, [uhistory]);

    const toPrint = () => {
        const toPrint = [];
        for (let i = 0; i < uhistory.length; i++) {
            if (uhistory[i].isPrint) {
                toPrint.push(i);
            }
        }

        if (toPrint.length > 0) {
            return "(" + toPrint.length + ")";
        }
    };

    const keys = window.localStorage.getItem('key');
    return (
        keys ?
            <React.Fragment>
                <title>{data && data.PageDemoHistory[0].pageTitle}</title>
                <MobileMenu history={history}/>
                <Menu history={history}/>
                <div className="container pt-5">
                    <div className="d-flex pt-5">
                        <AsideMenu backTo={"/"}/>

                        <section className="pt-5 mt-2 section-left">
                            <div className="history-title">
                                <img className="mr-2" src={his} alt=""/>
                                <p className="mb-0">Usage history</p>
                            </div>
                            <div className="history-wrap">
                                {
                                    uhistory.length > 0 ?
                                        uhistory.map((h) =>
                                            <HistoryItem id={h.id} title={h.title} date={h.date} time={h.time}
                                                         data={h.result} isPrint={h.isPrint}/>
                                        ) :
                                        <div className="text-center" style={{color: "#92A6E9"}}>
                                            <h1>History is empty</h1>
                                            <hr/>
                                        </div>

                                }
                                <button className="print-btn" onClick={() => print(ids)}>
                                    <img src={download} alt=""/>Download report {toPrint()}
                                </button>
                            </div>

                        </section>
                    </div>
                </div>
                <div className="print-wrapper">
                    <Printer>
                        {
                            uhistory.map((i, index) =>
                                i.title == "MetaModel" ?
                                    i.isPrint &&
                                    <HistoryItemPrintMetaFirst key={i.id} id={ids.find(item => item == i.id)}
                                                               title={i.title} date={i.date} time={i.time}
                                                               data={i.result}/>
                                    :
                                    i.isPrint &&
                                    <div key={index} className="a4" id={ids.find(item => item == i.id)}>
                                        <HistoryItemPrint id={i.id} title={i.title} date={i.date} time={i.time}
                                                          data={i.result}/>
                                    </div>
                            )
                        }
                    </Printer>
                </div>
                <Footer/>
            </React.Fragment> :
            <Authentication history={history}/>
    );
}

export default History;