
import React from "react";
import MobileMenu from "../MobileMenu/MobileMenu";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import TopArrow from "../TopArrow/TopArrow";
import News1 from "../News/News1";
import News2 from "../News/News2";
import News3 from "../News/News3";
import News4 from "../News/News4";
import { useSelector } from "react-redux";

function PageTermsOfUse(props) {

    const { history } = props.history;
    const data = useSelector(state => state.load.data);

    return (
        <div>
            <title>{data && data.PageTermsOfUse[0].pageTitle}</title>
            <MobileMenu history={history} />
            <Menu history={history} />
            <div id="products" className="container pt-5 pb-5">
                <p className="begin_product pt-5">{data && data.PageTermsOfUse[0].caption}</p>
            </div>
            <div className="container">
                <div className="current_new">
                    <div className="end_current_news">
                        <p>
                            This website is published by BIOME SAS with share capital of 5 000 euros and the head office located at 15 rue des Halles 75001 PARIS.
                            Registration number: 893 940 791 00013 R.C.S Paris.
                            Responsible for the publication: Mr Velikorodnyy
                            For more information you can contact our services: <a href="mailto:info@biome-science.com">info@biome-science.com</a>
                        </p>
                        <p>
                            All the Elements and Contents that appear on the <a href="https://biome-science.com/">biome-science.com</a> website are protected by French legislation on copyright and trademark law. In particular, the trademarks, logos, drawings, graphics, icons, texts, applications, scripts, functionality, as well as their combination at the main domain, the associated domains and sub-domains, are the exclusive property of BIOME. Access to the website does not entail any transfer of the aforementioned rights. The rights of use of the website are only granted in digital form for the purpose of viewing the pages, on a personal, non-transferable and non-exclusive basis.
                            The user is prohibited to modify, copy, reproduce, sell, rent, supplement or use in any other form, in whole or in part, any element of the website without the prior written permission of BIOME. These Terms of Use shall be governed by BIOME, and all disputes relating to or in connection with these Terms of Use or their subject matter shall be resolved in accordance with the laws of France, to the exclusion of its conflict of laws rules.
                        </p>
                    </div>
                </div>
            </div>


            <div className="pb-5" />
            <Footer />
            <TopArrow />
        </div>
    );
}

export default PageTermsOfUse;
