
    import React from "react";
    import {useSelector} from "react-redux";

function Contact() {

    const data = useSelector(state => state.load.data);

    return (
        <div className="wrap_all_contact">
            <div className="container d-flex  wow animate__fadeIn" data-wow-duration="2s">
                <div className=" wrap-contact">
                    <div id="product">
                        <p className="begin_product">{data && data.Contact[0].caption}</p>
                    </div>
                    <form id="contactform" action="../function/mail.php" method="post">

                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <label htmlFor="firstName">{data && data.Contact[0].name}</label>
                            <input type="text" className="name form-control" name="name"/>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label htmlFor="lastName">{data && data.Contact[0].phone}</label>
                            <input type="tel" id="phone" className="phone form-control" name="phone"
                                       placeholder="_ (___) ___-__-__"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <label htmlFor="firstName">{data && data.Contact[0].Company}</label>
                            <input type="text" className="company form-control" name="company"/>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label htmlFor="lastName">E-mail*</label>
                                <input type="email" id="mail" className="mail form-control" name="mail"
                                       placeholder="__@__.__"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="firstName">{data && data.Contact[0].Message}</label>
                            <textarea rows="6" type="text" name="message" id="message"
                                          className="message form-control"/>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col text-white">
                                <i>
                                    +33(0)1 89 16 85 83<br/>
                                    info@biome-science.com<br/>
                                    2 place de Touraine 78000 Versailles FRANCE
                                </i>
                            </div>
                        </div>
                        <div className="row cnt">
                            <div className="col text-center">
                                    <input id="sendmail" type="submit" name="send" value={data && data.Contact[0].send}
                                           className="btn-violet text-center mt-2 mb-4"/>
                            </div>
                        </div>
                        <input type="hidden" name="recaptcha_response" id="recaptchaResponse"/>
                    </form>
                    <div className="wrap_success">
                        <div className="success">
                            <p className="success_text">{data && data.Contact[0].succes}Your application is accepted!</p>
                            <div className="success_img">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 20 20">
                                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
