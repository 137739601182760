import React from "react";
import ResultView from "../ResultView";

function ResultSimpleHistory({result, min, max, middle, data, print}) {

    return (
        result.length > 0 &&
        <div className="result-model-history pt-2">
            <div className="form-result-history">
                {
                    result.map((r, index) =>
                        r.data ?
                            <div key={index} className="mb-4 mt-4">
                                {
                                    r.data.map((rd, index) =>
                                        <div key={index}>
                                            <ResultView print={print} dataText={data.diabet} max={max} min={min}
                                                        middle={middle} data={rd.result}/>
                                        </div>
                                    )
                                }
                            </div> :
                            <div className="mb-4 mt-4">
                                <h1 className="title-result text-danger">Data error</h1>
                            </div>
                    )
                }
            </div>
        </div>
    );
}

export default ResultSimpleHistory;