import {setting_auth} from "../setting_auth";
const apiUrl = setting_auth.apiUrl;

export function loadItem() {
    return new Promise((resolve) => {

            const requestOptions = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json"
                }
            };

            fetch(apiUrl+"get_session", requestOptions)
                .then(response => response.json())
                .then(response => {
                    resolve({data: response});
                })
                .catch(error => console.log('error', error));
        }
    );
}