import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {loadItem} from "./async_reducer";

export const AsyncLoadItem = createAsyncThunk(
    'load/AsyncLoadItem',
    async () => {
        const response = await loadItem();
        // console.log('AsyncLoadItem', response.data);

        return response.data;
    }
);

const load = createSlice({
    name: "load",
    initialState: {
        isLoad: false,
        lang: 'en',
        data: false,
        key: '',
        uhistory: []
    },
    reducers: {
        setKey(state, action){
            state.key = action.payload;
        },
        setDelete(state, action) {
            const id = action.payload.id;
            const find = state.uhistory.filter(item => item.id !== id);
            state.uhistory = find;
            window.localStorage.setItem("usage_history", JSON.stringify(find));
        },
        setCheck(state, action) {
            const id = action.payload.id;
            const value = action.payload.value;

            const find = state.uhistory.find(item => item.id === id);
            find.isPrint = value;
        },
        setFromHistory(state, action) {
            const data = action.payload;
            const history = [];

            for (let i = 0; i < data.length; i++) {
                data[i].isPrint = false;

                history.push(
                    data[i]
                );
            }

            state.uhistory = history;
        },
        isLoadSwitch(state) {
            state.isLoad = true;
        },
        loadLanguageText(state, action) {
            state.data = action.payload;
        },
        isChangeLanguage(state, action) {
            state.lang = action.payload;
            window.sessionStorage.setItem("lang", action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(AsyncLoadItem.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(AsyncLoadItem.fulfilled, (state, action) => {
                console.log(action.payload)
                state.status = 'idle';
                state.key = action.payload.key;
            })
    }
});

export default load.reducer;
export const {setKey, isLoadSwitch, isChangeLanguage, loadLanguageText, setFromHistory, setCheck, setDelete} = load.actions;