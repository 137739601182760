import React from "react";
import logo from "../../images/logoprint.png";
import sb from "../../images/demo/bottomspawn.png";
import PieComponentsHistory from "../DemoPages/Models/MetaModelResult/PieComponentsHistory";

function HistoryItemPrintMetaFirst({id, title, date, time, data}) {

    const images = data[0].data[0].result.images;
    const datas = data[0].data[0].result;

    return (
        <React.Fragment>
            <div className="a4" id={id}>
                <div className="item-print">
                    <img src={logo} className="pb-4 pt-4 w-100" alt="BioME"/>
                    <div className="history-item-print d-flex align-items-center">
                        <p className="mb-0 history-item-title print-white">{title}</p>
                        <p className="mb-0 history-item-date print-white">{date}</p>
                        <p className="mb-0 history-item-time print-white">{time}</p>
                    </div>
                </div>
                <div className="rows-history ">
                    <div className="spider-spawn-history">
                        <img className="spider" src={'data:image/png;base64,' + images} alt=""/>
                        <img className="sb" src={sb} alt=""/>
                    </div>
                </div>
            </div>
            <div className="a4" id={Number(id) + 1}>
                <div className="item-print">
                    <img src={logo} className="pb-4 w-100" alt="BioME"/>
                    <div className="history-item-print d-flex align-items-center">
                        <p className="mb-0 history-item-title print-white">{title}</p>
                        <p className="mb-0 history-item-date print-white">{date}</p>
                        <p className="mb-0 history-item-time print-white">{time}</p>
                    </div>
                    <div className="rows-history pt-4">
                        <div className="wrap-switcher-history">
                            <div className="inset-switcher-back-history">
                                <PieComponentsHistory print={true} id={1} data={datas}/>
                                <PieComponentsHistory print={true} id={2} data={datas}/>
                                <PieComponentsHistory print={true} id={3} data={datas}/>
                                <PieComponentsHistory print={true} id={4} data={datas}/>
                                <PieComponentsHistory print={true} id={5} data={datas}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HistoryItemPrintMetaFirst;