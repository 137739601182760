import React from "react";
import logo from "../../../images/demo/digestive-shadow.svg";

function Digestive() {
    return (
        <section className="section-left">
            <div className="d-flex align-items-center pt-5 mt-3 w-100">
                <img className="img-by-system" src={logo} alt=""/>
                <div className="d-grid">
                    <h1 className="title-by-system mb-0">Digestive</h1>
                    <h1 className="title-by-system">system</h1>
                </div>
            </div>
            <p className="mb-0 pt-3 pb-2 text-white">
                Health risks associated with heart and vessels disorders are estimated in BioMeter demo with SCORE model
                and ASCVD Risk model. The full version gives the user access to the following scales and models (the
                list is not comprehensive): CHA2DS2VASc (risk of stroke in patients with atrial fibrillation), HAS-BLED
                (bleeding risk in patients with atrial fibrillation), Framingham 2008 (10-year risk of non-fatal CVD),
                MESA 2015 (10-year coronary heart disease risk), Seattle Heart Failure Model (survival in patients with
                heart failure).
            </p>

        </section>
    );
}

export default Digestive;