import React, {useEffect, useState} from "react";
import InputDate from "../Inputs/InputDate";
import InputNumber from "../Inputs/InputNumber";
import InputSelect from "../Inputs/InputSelect";
import defaultParam from './defaultParam';
import input from './input';
import ResultSimple from "../Inputs/ResultSimple";
import axios from "axios";
import inputs from './qwe';
import ResultViewMetaModel from "../MetaModelResult/ResultViewMetaModel";

function MetaModel() {
    const [block, setBlock] = useState(1);
    const [birthday_time, setBirthday_time] = useState(false);
    const [gender, setGender] = useState(false);
    const [cholesterol, setCholesterol] = useState(false);
    const [sbp, setSbp] = useState(false);
    const [result, setResult] = useState([]);
    const [findPram, setFindParam] = useState(true);
    const [isDefault, setDefault] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [defaultTexts, setDefaultTexts] = useState(false);

    useEffect(() => {
        findParam();
    }, [birthday_time, gender, cholesterol, sbp]);

    const findParam = () => {
        if (birthday_time && gender && cholesterol && sbp ) {
            setFindParam(true);
        } else {
            setFindParam(false);
        }
    };

    const setValueByType = (type, value) => {
        switch (type) {
            case "birthday_time":
                setBirthday_time(value);
                break;
            case "gender":
                setGender(value);
                break;
            case "cholesterol":
                setCholesterol(value);
                break;
            case "sbp":
                setSbp(value);
                break;
        }
    };

    const defaultText = [
        {
            id: 1,
            text: "Male, 56. Patient’s medical history includes hypertension and type 2 diabetes, which are reflected by pronounced critical (red) segments of cardiovascular, endocrine and digestive systems on the diagram. Based on detected significant ratio of abnormal values in urinary system, the patient was referred to a specialist, who diagnosed several kidney-related conditions."
        },
        {
            id: 2,
            text: "Male, 67. Kidney disease associated with chemotherapy-treated myeloma. MetaModel confirms high level of urinary system damage, as well as indicates multiple systems involvement in disease progression, highlighting directions for future patient management."
        },
    ];

    const setDefaultBySelect = (id) => {
        if (id !== "0") {
            const finds = defaultText.find(item => item.id == id);
            setDefaultTexts(finds.text);
        } else {
            setDefaultTexts(false);
        }

        if (id !== "0") {
            const find = defaultParam.find(item => item.id == id);
            setDefault(id);
            setResult(find.result);

            const dates = new Date();
            const bt = new Date(birthday_time);
            const year = dates.getFullYear();
            const month = dates.getMonth() + 1;
            if (month <= 9) {
                var month_ = "0" + (Number(month));
            } else {
                var month_ = Number(month);
            }
            const dat = dates.getDate();
            if (dat <= 9) {
                var dat_ = "0" + (Number(dat));
            } else {
                var dat_ = Number(dat);
            }
            const hour = dates.getHours();
            if (hour <= 9) {
                var hour_ = "0" + (Number(hour));
            } else {
                var hour_ = Number(hour);
            }
            const min = dates.getMinutes();
            if (min <= 9) {
                var min_ = "0" + (Number(min));
            } else {
                var min_ = Number(min);
            }
            const sec = dates.getSeconds();
            if (sec <= 9) {
                var sec_ = "0" + (Number(sec));
            } else {
                var sec_ = Number(sec);
            }

            var UH = [];
            const UH_r = JSON.parse(window.localStorage.getItem("usage_history"));

            if (UH_r !== null) {
                UH = UH_r;
            }

            const result_ = {
                id: Math.floor(Math.random() * (999999 - 111111) + 111111),
                title: "MetaModel",
                date: dat_ + '-' + month_ + '-' + year,
                time: hour_ + ':' + min_,
                result: find.result
            };
            UH.push(result_);
            window.localStorage.setItem("usage_history", JSON.stringify(UH));

        } else
            setDefault(false);
        document.getElementById("input-Dateofbirth0").value = " ";
        document.getElementById("input-Gender1").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Smoking2").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Hemoglobin3").value = " ";
        document.getElementById("input-HematocritHCT4").value = " ";
        document.getElementById("input-RedbloodcellRBCcount5").value = " ";
        document.getElementById("input-MeancorpuscularvolumeMCV6").value = " ";
        document.getElementById("input-MeancorpuscularhemoglobinMCH7").value = " ";
        document.getElementById("input-MeancorpuscularhemoglobinconcentrationMCHC8").value = " ";
        document.getElementById("input-RedbloodcelldistributionwidthstandarddeviationRDWSD9").value = " ";
        document.getElementById("input-RedbloodcelldistributionwidthcoefficientofvariationRDWCV10").value = " ";
        document.getElementById("input-PlateletcritPCT11").value = " ";
        document.getElementById("input-PlateletcountPLT12").value = " ";
        document.getElementById("input-MeanplateletvolumeMPV13").value = " ";
        document.getElementById("input-PlateletdistributionwidthPDW14").value = " ";
        document.getElementById("input-PlateletlargecellratioPLCR15").value = " ";
        document.getElementById("input-WhitebloodcellcountWBC16").value = " ";
        document.getElementById("input-NeutrophilcountNEUT17").value = " ";
        document.getElementById("input-NeutrophilcountNEUT18").value = " ";
        document.getElementById("input-EosinophilcountEO19").value = " ";
        document.getElementById("input-EosinophilcountEO20").value = " ";
        document.getElementById("input-BasophilcountBASO21").value = " ";
        document.getElementById("input-BasophilcountBASO22").value = " ";
        document.getElementById("input-LymphocytecountLYMPH23").value = " ";
        document.getElementById("input-LymphocytecountLYMPH24").value = " ";
        document.getElementById("input-MonocytecountMONO25").value = " ";
        document.getElementById("input-MonocytecountMONO26").value = " ";
        document.getElementById("input-Glucose27").value = " ";
        document.getElementById("input-Bilirubintotal28").value = " ";
        document.getElementById("input-Bilirubindirect29").value = " ";
        document.getElementById("input-Bilirubinindirect30").value = " ";
        document.getElementById("input-Creatinine31").value = " ";
        document.getElementById("input-Urea32").value = " ";
        document.getElementById("input-Uricacid33").value = " ";
        document.getElementById("input-Proteintotal34").value = " ";
        document.getElementById("input-Albumin35").value = " ";
        document.getElementById("input-TriglyceridesTG36").value = " ";
        document.getElementById("input-Cholesteroltotal37").value = " ";
        document.getElementById("input-HighdensitylipoproteincholesterolHDLC38").value = " ";
        document.getElementById("input-LowdensitylipoproteincholesterolLDLC39").value = " ";
        document.getElementById("input-VerylowdensitylipoproteincholesterolVLDLC40").value = " ";
        document.getElementById("input-AspartateaminotransferaseAST41").value = " ";
        document.getElementById("input-AlanineaminotransferaseALT42").value = " ";
        document.getElementById("input-Amylasealpha43").value = " ";
        document.getElementById("input-Amylasepancreatic44").value = " ";
        document.getElementById("input-GammaglutamyltransferaseGGT45").value = " ";
        document.getElementById("input-Alkalinephosphatase46").value = " ";
        document.getElementById("input-Iron47").value = " ";
        document.getElementById("input-Magnesium48").value = " ";
        document.getElementById("input-Calcium49").value = " ";
        document.getElementById("input-Phosphorus50").value = " ";
        document.getElementById("input-CreactiveproteinCRP51").value = " ";
        document.getElementById("input-Prothrombin52").value = " ";
        document.getElementById("input-InternationalNormalizedRatioINR53").value = " ";
        document.getElementById("input-Thrombintime54").value = " ";
        document.getElementById("input-Cortisol55").value = " ";
        document.getElementById("input-ThyroidstimulatinghormoneTSH56").value = " ";
        document.getElementById("input-TriiodothyroninefreeT3free57").value = " ";
        document.getElementById("input-ThyroxinefreeT4free58").value = " ";
        document.getElementById("input-Testosteronetotal59").value = " ";
        document.getElementById("input-Testosteronefree60").value = " ";
        document.getElementById("input-SexhormonebindingglobulinSHBG61").value = " ";
        document.getElementById("input-LuteinizinghormoneLH62").value = " ";
        document.getElementById("input-FolliclestimulatinghormoneFSH63").value = " ";
        document.getElementById("input-Insulin64").value = " ";
        document.getElementById("input-GlycatedhemoglobinHbA1C65").value = " ";
        document.getElementById("input-Carbohydrateantigen199CA19966").value = " ";
        document.getElementById("input-CarcinoembryonicantigenCEA67").value = " ";
        document.getElementById("input-Urinecolor68").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Urinespecificgravity69").value = " ";
        document.getElementById("input-UrinepH70").value = " ";
        document.getElementById("input-Proteininurine71").value = " ";
        document.getElementById("input-Glucoseinurine72").value = " ";
        document.getElementById("input-Bilirubininurine73").value = " ";
        document.getElementById("input-Urobilinogeninurine74").value = " ";
        document.getElementById("input-Nitritesinurine75").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-RBCinurine76").value = " ";
        document.getElementById("input-RBCcastsinurine77").value = " ";
        document.getElementById("input-WBCinurine78").value = " ";
        document.getElementById("input-WBCcastsinurine79").value = " ";
        document.getElementById("input-Ketonesinurine80").value = " ";
        document.getElementById("input-Squamousepithelialcellsinurine81").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Transitionalepithelialcells82").value = " ";
        document.getElementById("input-Renalepithelialcells83").value = " ";
        document.getElementById("input-Hyalinecasts84").value = " ";
        document.getElementById("input-Granularcasts85").value = " ";
        document.getElementById("input-Waxycasts86").value = " ";
        document.getElementById("input-RBCbyNechiporenkomethod87").value = " ";
        document.getElementById("input-WBCbyNechiporenkomethod88").value = " ";
        document.getElementById("input-CastsbyNechiporenkomethod89").value = " ";
        document.getElementById("input-VitaminB1290").value = " ";
        document.getElementById("input-HydroxyvitaminD91").value = " ";
        document.getElementById("input-Folicacid92").value = " ";
        document.getElementById("input-Potassium93").value = " ";
        document.getElementById("input-Sodium94").value = " ";
        document.getElementById("input-Chloride95").value = " ";
        document.getElementById("input-Ferritin96").value = " ";
        document.getElementById("input-TriiodothyroninetotalT3total97").value = " ";
        document.getElementById("input-ThyroxinetotalT4total98").value = " ";
        document.getElementById("input-ErythrocytesedimentationrateESRbyWestergrenmethod99").value = " ";
        document.getElementById("input-Thyroidlocation100").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Thyroidlobesvolume101").value = " ";
        document.getElementById("input-Thyroidcomposition102").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Thyroidechogenicity103").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Thyroidmarginsmoothness104").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-RRinterval105").value = " ";
        document.getElementById("input-Heartrate106").value = " ";
        document.getElementById("input-ECGrhythm107").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Alphaangleelectricalheartaxis108").value = " ";
        document.getElementById("input-Pwave109").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-PQinterval110").value = " ";
        document.getElementById("input-QRScomplex111").value = " ";
        document.getElementById("input-Kidneylengthleft112").value = " ";
        document.getElementById("input-Kidneywidthleft113").value = " ";
        document.getElementById("input-Kidneyparenchymawidthinmediansegmentleft114").value = " ";
        document.getElementById("input-Kidneylengthright115").value = " ";
        document.getElementById("input-Kidneywidthright116").value = " ";
        document.getElementById("input-Kidneyparenchymawidthinmediansegmentright117").value = " ";
        document.getElementById("input-Livercomposition118").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Liverechogenicity119").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Liversize120").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Livermarginsmoothness121").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Portalveindiameter122").value = " ";
        document.getElementById("input-Intrahepaticbileductswidth123").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Livervascularpattern124").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Gallbladdershape125").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Gallbladdermarginsmoothness126").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Gallbladderwallthickness127").value = " ";
        document.getElementById("input-Commonbileductdiameter128").value = " ";
        document.getElementById("input-Pancreascomposition129").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Pancreasechogenicity130").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Pancreasmarginsmoothness131").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Splenicveindiameter132").value = " ";
        document.getElementById("input-Pancreaticductdiameter133").value = " ";
        document.getElementById("input-Spleencomposition134").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Spleenechogenicity135").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Spleenmarginsmoothness136").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Totalbacteriacount137").value = " ";
        document.getElementById("input-Lactobacillusspp138").value = " ";
        document.getElementById("input-Gallbladdermargindefinition139").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Pancreasmargindefinition140").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Systolicbloodpressure141").value = " ";
        document.getElementById("input-Diastolicbloodpressure142").value = " ";
        document.getElementById("input-Lungvolumeright143").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungpatternright144").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungrootright145").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungfieldlucencyleft146").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungsinusesconditionright147").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungpleuraconditionright148").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungvolumeleft149").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungpatternleft150").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungrootleft151").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungfieldlucencyright152").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungsinusesconditionleft153").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungpleuraconditionleft154").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Mediastinumlocation155").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Mediastinumsize156").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Diaphragmlocation157").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Heartsize158").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Aorticarch159").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Kidneymarginsmoothnessright160").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Kidneylocationright161").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Kidneyshaperight162").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Kidneypelvicalycealsystemright163").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Kidneymarginsmoothnessleft164").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Kidneylocationleft165").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Kidneyshapeleft166").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Kidneypelvicalycealsystemleft167").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungrootcompositionright168").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Lungrootcompositionleft169").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Liverparenchymadensity170").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Liverparenchymacomposition171").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Pancreasparenchymadensity172").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Pancreasparenchymacomposition173").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Spleenparenchymadensity174").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Spleenparenchymacomposition175").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Aortaabdominal176").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Abdominalfluid177").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Abdominallymphnodessize178").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Retroperitoneallymphnodessize179").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Trichomonasvaginalisqualitative180").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Chlamydiatrachomatisqualitative181").getElementsByTagName("option")[0].selected = true;
        document.getElementById("input-Ureaplasmaurealyticum182").value = " ";
        document.getElementById("input-Ureaplasmaparvum183").value = " ";
    };

    const closeResult = () => {
        setShowResult(false);
    };

    const getByType = (type, title, uid, utype, setValueByType, unit, min, max, step, isDefault = false) => {
        switch (type) {
            case "date":
                return <InputDate block={true}
                                  title={title}
                                  uid={uid}
                                  utype={utype}
                                  setValueByType={setValueByType}
                                  isDefault={isDefault}
                />;
            case "select":
                return <InputSelect block={true}
                                    title={title}
                                    uid={uid}
                                    unit={unit}
                                    setValueByType={setValueByType}
                                    utype={utype}
                                    isDefault={isDefault}
                />;
            case "number":
                return <InputNumber block={true}
                                    title={title}
                                    uid={uid}
                                    unit={unit}
                                    utype={utype}
                                    step={step}
                                    setValueByType={setValueByType}
                                    min={min}
                                    max={max}
                                    isDefault={isDefault}
                />;
        }
    };

    const data = {
        diabet: [
            {
                title: "Low",
                text: "It is important to maintain current lifestyle and medication choices. Regular risk estimation is advised to prevent the onset of diabetes"
            },
            {title: "High", text: "It is strongly recommended to undergo comprehensive laboratory diabetes screening"},
        ]
    };

    return (
        <section className="section-left">
            {
                showResult &&
                <ResultViewMetaModel closeResult={closeResult} result={result}/>
            }
            <div className="d-flex align-items-center pt-5 pb-3 mt-3 w-100">
                <h1 className="mr-auto text-white">MetaModel</h1>
            </div>

            {/*<div className="switch_block">*/}
            {/*    <div className="d-flex align-items-center ml-auto" style={{width: "fit-content"}}>*/}
            {/*        <button onClick={() => setBlock(1)}*/}
            {/*                className={block === 1 ? "switch-btn active" : "switch-btn"}>description*/}
            {/*        </button>*/}
            {/*        <button onClick={() => setBlock(2)}*/}
            {/*                className={block === 2 ? "switch-btn active" : "switch-btn"}>context*/}
            {/*        </button>*/}
            {/*        <button onClick={() => setBlock(3)}*/}
            {/*                className={block === 3 ? "switch-btn active" : "switch-btn"}>reference*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        block === 1 &&*/}
            {/*        <div className="item-switch">*/}
            {/*            <p className="mb-0">*/}
            {/*                The machine learning algorithm uses patient-generated data of electronic health records to*/}
            {/*                estimate probability of having type 2 diabetes. Despite well-established diagnostic*/}
            {/*                criteria, diabetes often remains undiagnosed, detected only when clinical manifestations of*/}
            {/*                the disease become apparent. This makes diabetic complications (retinopathy, nephropathy,*/}
            {/*                neuropathy, cardiovascular complications) significantly harder to prevent. Type 2 Diabetes*/}
            {/*                Model by BioME is capable of detecting the disease at early stages, thus allowing both the*/}
            {/*                physician and the patient to make timely interventions.*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        block === 2 &&*/}
            {/*        <div className="item-switch">*/}
            {/*            <p className="mb-0">*/}
            {/*                Type 2 Diabetes Model is intended for use in populations of ambulatory patients (symptomatic*/}
            {/*                or non-symptomatic) without confirmed diabetes, and classifies patients as being at either*/}
            {/*                high risk or low risk. The model output represents a confidence index (displayed as %) with*/}
            {/*                a cut-off value of 4. Results equal or above this value correspond to high risk of having*/}
            {/*                diabetes. In this case, endocrinologist or primary care provider is advised to recommend the*/}
            {/*                patient to undergo full screening procedure.*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*    {*/}
            {/*        block === 3 &&*/}
            {/*        <div className="item-switch">*/}
            {/*            <p className="mb-0">*/}
            {/*                Glanz V., Dudenkov V., Velikorodny A. (2022). Development and Validation of Type 2 Diabetes*/}
            {/*                Machine Learning Classification Model for Clinical Decision Support Framework. Nature*/}
            {/*                Scientific Reports, manuscript submitted for publication.*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</div>*/}
            <div className="parameter">

                <div className="select-wrapper">
                    <select id="default" className="form-control default-case"
                            onChange={(e) => setDefaultBySelect(e.target.value)}>
                        <option value="0">Select case</option>
                        <option value="1">Patient 1</option>
                        <option value="2">Patient 2</option>
                    </select>
                    <div className="select"/>
                </div>
                {
                    isDefault &&
                    <div className="about-default">
                        {defaultTexts}
                    </div>
                }
                <div className="text-center pt-3">
                    {
                        isDefault ?
                            <button className="submit-model mt-4" onClick={() => setShowResult(true)}>Submit</button> :
                            findPram ?
                                <button className="submit-model mt-4">Submit</button> :
                                <button className="submit-model mt-4 disabled" disabled>Submit</button>
                    }
                    <div className="d-flex align-items-center text-left flex-wrap">
                        {
                            input.map((i) =>
                                isDefault ?
                                    getByType(i.type, i.title, i.uid, i.utype, setValueByType, i.unit, i.min, i.max, i.step, defaultParam[isDefault - 1]) :
                                    getByType(i.type, i.title, i.uid, i.utype, setValueByType, i.unit, i.min, i.max, i.step)
                            )
                        }
                    </div>
                    {
                        isDefault ?
                            <button className="submit-model mt-4" onClick={() => setShowResult(true)}>Submit</button> :
                            findPram ?
                                <button className="submit-model mt-4">Submit</button> :
                                <button className="submit-model mt-4 disabled" disabled>Submit</button>
                    }
                </div>
            </div>
        </section>
    );
}

export default MetaModel;