import React from "react";
import ResultView from "../ResultView";
import ResultViewThreeScore from "../ResultViewThreeScore";

function ResultScoreHistory({result, min, max, middle, moderate, data, print}) {

    return (
        result.length > 0 &&
        <div className="result-model-history pt-2">
                <div className="form-result-history">
                    {
                        result.map((r, index) =>
                            r.model.technical_model_name === "ScoreAbsoluteRiskValue" ?
                                r.data &&
                                    <div key={index} className="mb-4 mt-4">
                                        {
                                            r.data.map((rd, index) =>
                                                rd.id_result === 100000 ?
                                                    <div key={index}>
                                                        <h6 className={print ? "title-result-little print":"title-result-little"}>RISK FOR SMOKERS:</h6>
                                                        <ResultViewThreeScore print={print} dataText={data[0].absolute} max={max} min={min} middle={middle} moderate={moderate}
                                                                    data={rd.result}/>
                                                    </div> :
                                                    <div key={index}>
                                                        <h6 className={print ? "title-result-little print":"title-result-little"}>RISK FOR NON-SMOKERS:</h6>
                                                        <ResultViewThreeScore print={print} dataText={data[0].absolute} max={max} min={min} middle={middle} moderate={moderate}
                                                                    data={rd.result}/>
                                                    </div>
                                            )
                                        }
                                    </div>
                                :
                                r.data &&
                                    <div className="mb-4 mt-4">
                                        {
                                            r.data.map((rd, index) =>
                                                rd.id_result === 100002 ?
                                                    <div key={index}>
                                                        <h6 className={print ? "title-result-little print":"title-result-little"}>RISK FOR SMOKERS:</h6>
                                                        <ResultView print={print} dataText={data[0].relative} max={max} min={min} middle={12}
                                                                    data={rd.result}/>
                                                    </div> :
                                                    <div key={index}>
                                                        <h6 className={print ? "title-result-little print":"title-result-little"}>RISK FOR NON-SMOKERS:</h6>
                                                        <ResultView print={print} dataText={data[0].relative} max={max} min={min} middle={12}
                                                                    data={rd.result}/>
                                                    </div>
                                            )
                                        }
                                    </div>
                        )
                    }
                    {
                        result[0].data === null && result[1].data === null &&
                        <div className="mb-4 mt-4">
                            <h1 className="title-result text-danger">Data error</h1>
                        </div>
                    }
                </div>
        </div>
    );
}

export default ResultScoreHistory;