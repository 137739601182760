import React from "react";
import LangButtonItem from "./LangButtonItem";

function LangButton() {

    const btn = [
        {id: 1, title: "En", data: "en"},
        {id: 1, title: "Fr", data: "fr"},
    ];

    return(
        <div className="top_menu_btn">
            {
                btn.map((m, index) =>
                    <LangButtonItem key={index} title={m.title} data={m.data}/>
                )
            }
        </div>
    )
}

export default LangButton;