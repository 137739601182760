import React, {useState} from "react";
import './MetaModel.css';
import sb from '../../../../images/demo/bottomspawn.svg'
import cardiovascular from '../../../../images/demo/cardio.svg';
import digestive from '../../../../images/demo/digest.svg';
import endocrine from '../../../../images/demo/endocr.svg';
import excretory from '../../../../images/demo/exest.svg';
import respiratory from '../../../../images/demo/raspir.svg';
import cardiovasculara from '../../../../images/demo/cardioa.svg';
import digestivea from '../../../../images/demo/digesta.svg';
import endocrinea from '../../../../images/demo/endocra.svg';
import excretorya from '../../../../images/demo/exesta.svg';
import respiratorya from '../../../../images/demo/raspira.svg';
import close from "../../../../images/demo/pages/closeresult.svg";
import VictoryPieWrap from "./VictoryPieWrap";
import PieComponents from "./PieComponents";

function ResultViewMetaModel({result, closeResult}) {
    const data = result[0].data[0].result;
    const images = result[0].data[0].result.images;

    const [switchs, setSwitchs] = useState(1);

    const getSwitch = () => {
        const data = [
            {id: 1, title: "Cardiovascular", img: cardiovascular, img_active: cardiovasculara},
            {id: 2, title: "Digestive", img: digestive, img_active: digestivea},
            {id: 3, title: "Endocrine", img: endocrine, img_active: endocrinea},
            {id: 4, title: "Urinary", img: excretory, img_active: excretorya},
            {id: 5, title: "Respiratory", img: respiratory, img_active: respiratorya}
        ];

        return data.map((d, index) =>
            index + 1 !== data.length ?
                d.id ===
                switchs ?
                    <div onClick={() => setSwitchs(d.id)} className="item-switch-model mr-3 active">
                        <img className="image-model-inset" src={d.img_active} alt=""/>
                        {d.title}
                    </div> :
                    <div onClick={() => setSwitchs(d.id)} className="item-switch-model mr-3">
                        <img className="image-model-inset" src={d.img} alt=""/>
                        {d.title}
                    </div> :
                d.id ===
                switchs ?
                    <div onClick={() => setSwitchs(d.id)} className="item-switch-model active">
                        <img className="image-model-inset" src={d.img_active} alt=""/>
                        {d.title}
                    </div> :
                    <div onClick={() => setSwitchs(d.id)} className="item-switch-model">
                        <img className="image-model-inset" src={d.img} alt=""/>
                        {d.title}
                    </div>
        )
    };

    return (
        <div className="wrap-meta-model-result">
            <div className="inset-model">

                <h1 className="meta-title text-center">Metamodel results</h1>
                <div className="rows">
                    <img onClick={() => closeResult()}
                         className="close-result-meta" src={close} alt=""/>
                    <div className="spider-spawn">
                        <img className="spider" src={'data:image/png;base64,' + images} alt=""/>
                        <img className="sb" src={sb} alt=""/>
                    </div>
                    <div className="wrap-switcher">
                        <div className="switcher">
                            {getSwitch()}
                        </div>
                        <div className="inset-switcher-back">
                            <PieComponents id={switchs} data={data}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResultViewMetaModel;