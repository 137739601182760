import React from "react";
import web from "../../../images/demo/pages/web.svg";
import contact from "../../../images/demo/pages/contact.svg";

function Partners() {
    return (
        <section className="section-left">
            <div className="d-flex align-items-center pt-5 mt-3 w-100">
                <h1 className="mr-auto text-white">BioME Collaborations</h1>
                {/*<div className="button-group">*/}
                {/*    <a href="#" className="button-company d-flex align-items-center position-relative mb-2"*/}
                {/*       target="_blank">*/}
                {/*        <img className="position-absolute mlbi" src={web} alt=""/>*/}
                {/*        <p className="mb-0 mlb m-auto">Website</p>*/}
                {/*    </a>*/}
                {/*    <a href="#" className="button-company d-flex align-items-center position-relative mb-2"*/}
                {/*       target="_blank">*/}
                {/*        <img className="position-absolute mlbi" src={contact} alt=""/>*/}
                {/*        <p className="mb-0 mlb m-auto">Contact</p>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
            <p className="mb-0 pt-3 pb-2 text-white">
                The work being done at BioME is getting recognition from biotech industry prominent figures in form of
                partnerships and valuable feedback, as, we believe, communication and external expertise are vital for
                achieving perfection. Here, we present the products of our collaboration with medical software
                development companies, pharmaceutical companies, healthcare regulators and clinics.
            </p>
        </section>
    );
}

export default Partners;