import React from "react";

function InputSelect({title, uid, setValueByType, utype, isDefault, block}) {

    const data = {
        gender: [
            {id: 1, value: 1, title: "Female"},
            {id: 2, value: 0, title: "Male"},
        ],
        race: [
            {id: 1, value: 1, title: "Black"},
            {id: 2, value: 0, title: "White"},
        ],
        smoking_status: [
            {id: 1, value: 1, title: "True"},
            {id: 2, value: 0, title: "False"},
        ],
        drugs_status: [
            {id: 1, value: 1, title: "True"},
            {id: 2, value: 0, title: "False"},
        ],
        diabetes_status: [
            {id: 1, value: 1, title: "True"},
            {id: 2, value: 0, title: "False"},
        ],
        Gender1: [{id: 2, value: "0", title: "Male"}],
        Smoking2: [{id: 3, value: "false", title: "False"}],
        Hemoglobin3: [{id: 4, value: "147", title: "147"}],
        HematocritHCT4: [{id: 5, value: "44.6", title: "44.6"}],
        RedbloodcellRBCcount5: [{id: 6, value: "4.83", title: "4.83"}],
        MeancorpuscularvolumeMCV6: [{id: 7, value: "92.3", title: "92.3"}],
        MeancorpuscularhemoglobinMCH7: [{id: 8, value: "30.4", title: "30.4"}],
        MeancorpuscularhemoglobinconcentrationMCHC8: [{id: 9, value: "33", title: "33"}],
        RedbloodcelldistributionwidthstandarddeviationRDWSD9: [{id: 10, value: "44.2", title: "44.2"}],
        RedbloodcelldistributionwidthcoefficientofvariationRDWCV10: [{id: 11, value: "13.3", title: "13.3"}],
        PlateletcritPCT11: [{id: 12, value: "0.15", title: "0.15"}],
        PlateletcountPLT12: [{id: 13, value: "173", title: "173"}],
        MeanplateletvolumeMPV13: [{id: 14, value: "8.9", title: "8.9"}],
        PlateletdistributionwidthPDW14: [{id: 15, value: "9.2", title: "9.2"}],
        PlateletlargecellratioPLCR15: [{id: 16, value: "15.7", title: "15.7"}],
        WhitebloodcellcountWBC16: [{id: 17, value: "6.54", title: "6.54"}],
        NeutrophilcountNEUT17: [{id: 18, value: "4.34", title: "4.34"}],
        NeutrophilcountNEUT18: [{id: 19, value: "66.4", title: "66.4"}],
        EosinophilcountEO19: [{id: 20, value: "0.08", title: "0.08"}],
        EosinophilcountEO20: [{id: 21, value: "1.2", title: "1.2"}],
        BasophilcountBASO21: [{id: 22, value: "0.02", title: "0.02"}],
        BasophilcountBASO22: [{id: 23, value: "0.3", title: "0.3"}],
        LymphocytecountLYMPH23: [{id: 24, value: "1.44", title: "1.44"}],
        LymphocytecountLYMPH24: [{id: 25, value: "22", title: "22"}],
        MonocytecountMONO25: [{id: 26, value: "0.66", title: "0.66"}],
        MonocytecountMONO26: [{id: 27, value: "10.1", title: "10.1"}],
        Glucose27: [{id: 28, value: "4.86", title: "4.86"}],
        Bilirubintotal28: [{id: 29, value: "18.79", title: "18.79"}],
        Bilirubindirect29: [{id: 30, value: "8.57", title: "8.57"}],
        Bilirubinindirect30: [{id: 31, value: "10.22", title: "10.22"}],
        Creatinine31: [{id: 32, value: "93", title: "93"}],
        Urea32: [{id: 33, value: "8.35", title: "8.35"}],
        Uricacid33: [{id: 34, value: "525", title: "525"}],
        Proteintotal34: [{id: 35, value: "74.9", title: "74.9"}],
        Albumin35: [{id: 36, value: "44.86", title: "44.86"}],
        TriglyceridesTG36: [{id: 37, value: "0.99", title: "0.99"}],
        Cholesteroltotal37: [{id: 38, value: "4.69", title: "4.69"}],
        HighdensitylipoproteincholesterolHDLC38: [{id: 39, value: "1.73", title: "1.73"}],
        LowdensitylipoproteincholesterolLDLC39: [{id: 40, value: "2.51", title: "2.51"}],
        VerylowdensitylipoproteincholesterolVLDLC40: [{id: 41, value: "0.45", title: "0.45"}],
        AspartateaminotransferaseAST41: [{id: 42, value: "30.9", title: "30.9"}],
        AlanineaminotransferaseALT42: [{id: 43, value: "40.7", title: "40.7"}],
        Amylasealpha43: [{id: 44, value: "73.1", title: "73.1"}],
        Amylasepancreatic44: [{id: 45, value: "42", title: "42"}],
        GammaglutamyltransferaseGGT45: [{id: 46, value: "102.6", title: "102.6"}],
        Alkalinephosphatase46: [{id: 47, value: "65.9", title: "65.9"}],
        Iron47: [{id: 48, value: "29.23", title: "29.23"}],
        Magnesium48: [{id: 49, value: "0.94", title: "0.94"}],
        Calcium49: [{id: 50, value: "2.48", title: "2.48"}],
        Phosphorus50: [{id: 51, value: "1.18", title: "1.18"}],
        CreactiveproteinCRP51: [{id: 52, value: "1.52", title: "1.52"}],
        Prothrombin52: [{id: 53, value: "100", title: "100"}],
        InternationalNormalizedRatioINR53: [{id: 54, value: "1", title: "1"}],
        Thrombintime54: [{id: 55, value: "15.8", title: "15.8"}],
        Cortisol55: [{id: 56, value: "309.4", title: "309.4"}],
        ThyroidstimulatinghormoneTSH56: [{id: 57, value: "1.99", title: "1.99"}],
        TriiodothyroninefreeT3free57: [{id: 58, value: "4.75", title: "4.75"}],
        ThyroxinefreeT4free58: [{id: 59, value: "16.56", title: "16.56"}],
        Testosteronetotal59: [{id: 60, value: "13.19", title: "13.19"}],
        Testosteronefree60: [{id: 61, value: "0.299", title: "0.299"}],
        SexhormonebindingglobulinSHBG61: [{id: 62, value: "25.67", title: "25.67"}],
        LuteinizinghormoneLH62: [{id: 63, value: "6.66", title: "6.66"}],
        FolliclestimulatinghormoneFSH63: [{id: 64, value: "12.38", title: "12.38"}],
        Insulin64: [{id: 65, value: "138.2", title: "138.2"}],
        GlycatedhemoglobinHbA1C65: [{id: 66, value: "5.87", title: "5.87"}],
        Carbohydrateantigen199CA19966: [{id: 67, value: "22.98", title: "22.98"}],
        CarcinoembryonicantigenCEA67: [{id: 68, value: "3.46", title: "3.46"}],
        Urinecolor68: [{id: 69, value: "normal", title: "Normal"}],
        Urinespecificgravity69: [{id: 70, value: "1.02", title: "1.02"}],
        UrinepH70: [{id: 71, value: "5", title: "5"}],
        Proteininurine71: [{id: 72, value: "0", title: "0"}],
        Glucoseinurine72: [{id: 73, value: "0", title: "0"}],
        Bilirubininurine73: [{id: 74, value: "0", title: "0"}],
        Urobilinogeninurine74: [{id: 75, value: "0", title: "0"}],
        Nitritesinurine75: [{id: 76, value: "normal", title: "Normal"}],
        RBCinurine76: [{id: 77, value: "0", title: "0"}],
        RBCcastsinurine77: [{id: 78, value: "0.5", title: "0.5"}],
        WBCinurine78: [{id: 79, value: "0", title: "0"}],
        WBCcastsinurine79: [{id: 80, value: "2.5", title: "2.5"}],
        Ketonesinurine80: [{id: 81, value: "0", title: "0"}],
        Squamousepithelialcellsinurine81: [{id: 82, value: "normal", title: "Normal"}],
        Transitionalepithelialcells82: [{id: 83, value: "0", title: "0"}],
        Renalepithelialcells83: [{id: 84, value: "0", title: "0"}],
        Hyalinecasts84: [{id: 85, value: "0", title: "0"}],
        Granularcasts85: [{id: 86, value: "0", title: "0"}],
        Waxycasts86: [{id: 87, value: "0", title: "0"}],
        RBCbyNechiporenkomethod87: [{id: 88, value: "0.4", title: "0.4"}],
        WBCbyNechiporenkomethod88: [{id: 89, value: "1.6", title: "1.6"}],
        CastsbyNechiporenkomethod89: [{id: 90, value: "0", title: "0"}],
        VitaminB1290: [{id: 91, value: "513.1", title: "513.1"}],
        HydroxyvitaminD91: [{id: 92, value: "34.65", title: "34.65"}],
        Folicacid92: [{id: 93, value: "7.44", title: "7.44"}],
        Potassium93: [{id: 94, value: "5.27", title: "5.27"}],
        Sodium94: [{id: 95, value: "134.6", title: "134.6"}],
        Chloride95: [{id: 96, value: "99.9", title: "99.9"}],
        Ferritin96: [{id: 97, value: "216.3", title: "216.3"}],
        TriiodothyroninetotalT3total97: [{id: 98, value: "1.37", title: "1.37"}],
        ThyroxinetotalT4total98: [{id: 99, value: "122.3", title: "122.3"}],
        ErythrocytesedimentationrateESRbyWestergrenmethod99: [{id: 100, value: "9", title: "9"}],
        Thyroidlocation100: [{id: 101, value: "normal", title: "Normal"}],
        Thyroidlobesvolume101: [{id: 102, value: "10.896661048564885", title: "10.896661048564885"}],
        Thyroidcomposition102: [{id: 103, value: "normal", title: "Normal"}],
        Thyroidechogenicity103: [{id: 104, value: "normal", title: "Normal"}],
        Thyroidmarginsmoothness104: [{id: 105, value: "normal", title: "Normal"}],
        RRinterval105: [{id: 106, value: "650", title: "650"}],
        Heartrate106: [{id: 107, value: "92", title: "92"}],
        ECGrhythm107: [{id: 108, value: "altered", title: "Altered"}],
        Alphaangleelectricalheartaxis108: [{id: 109, value: "-18", title: "-18"}],
        Pwave109: [{id: 110, value: "normal", title: "Normal"}],
        PQinterval110: [{id: 111, value: "172", title: "172"}],
        QRScomplex111: [{id: 112, value: "90", title: "90"}],
        Kidneylengthleft112: [{id: 113, value: "121.21986034482", title: "121.21986034482"}],
        Kidneywidthleft113: [{id: 114, value: "50.31161461865", title: "50.31161461865"}],
        Kidneyparenchymawidthinmediansegmentleft114: [{id: 115, value: "19.78073086741", title: "19.78073086741"}],
        Kidneylengthright115: [{id: 116, value: "100.47526344175999", title: "100.47526344175999"}],
        Kidneywidthright116: [{id: 117, value: "53.4106831701", title: "53.4106831701"}],
        Kidneyparenchymawidthinmediansegmentright117: [{id: 118, value: "16.737648843869998", title: "16.737648843869998"}],
        Livercomposition118: [{id: 119, value: "normal", title: "Normal"}],
        Liverechogenicity119: [{id: 120, value: "altered", title: "Altered"}],
        Liversize120: [{id: 121, value: "altered", title: "Altered"}],
        Livermarginsmoothness121: [{id: 122, value: "normal", title: "Normal"}],
        Portalveindiameter122: [{id: 123, value: "11", title: "11"}],
        Intrahepaticbileductswidth123: [{id: 124, value: "normal", title: "Normal"}],
        Livervascularpattern124: [{id: 125, value: "normal", title: "Normal"}],
        Gallbladdershape125: [{id: 126, value: "normal", title: "Normal"}],
        Gallbladdermarginsmoothness126: [{id: 127, value: "normal", title: "Normal"}],
        Gallbladderwallthickness127: [{id: 128, value: "1.5", title: "1.5"}],
        Commonbileductdiameter128: [{id: 129, value: "3", title: "3"}],
        Pancreascomposition129: [{id: 130, value: "normal", title: "Normal"}],
        Pancreasechogenicity130: [{id: 131, value: "altered", title: "Altered"}],
        Pancreasmarginsmoothness131: [{id: 132, value: "normal", title: "Normal"}],
        Splenicveindiameter132: [{id: 133, value: "4", title: "4"}],
        Pancreaticductdiameter133: [{id: 134, value: "1", title: "1"}],
        Spleencomposition134: [{id: 135, value: "normal", title: "Normal"}],
        Spleenechogenicity135: [{id: 136, value: "normal", title: "Normal"}],
        Spleenmarginsmoothness136: [{id: 137, value: "normal", title: "Normal"}],
        Totalbacteriacount137: [{id: 138, value: "5", title: "5"}],
        Lactobacillusspp138: [{id: 139, value: "25.118864315095824", title: "25.118864315095824"}],
        Gallbladdermargindefinition139: [{id: 140, value: "normal", title: "Normal"}],
        Pancreasmargindefinition140: [{id: 141, value: "normal", title: "Normal"}],
        Systolicbloodpressure141: [{id: 142, value: "145", title: "145"}],
        Diastolicbloodpressure142: [{id: 143, value: "90", title: "90"}],
        Lungvolumeright143: [{id: 144, value: "normal", title: "Normal"}],
        Lungpatternright144: [{id: 145, value: "altered", title: "Altered"}],
        Lungrootright145: [{id: 146, value: "normal", title: "Normal"}],
        Lungfieldlucencyleft146: [{id: 147, value: "normal", title: "Normal"}],
        Lungsinusesconditionright147: [{id: 148, value: "normal", title: "Normal"}],
        Lungpleuraconditionright148: [{id: 149, value: "normal", title: "Normal"}],
        Lungvolumeleft149: [{id: 150, value: "normal", title: "Normal"}],
        Lungpatternleft150: [{id: 151, value: "altered", title: "Altered"}],
        Lungrootleft151: [{id: 152, value: "normal", title: "Normal"}],
        Lungfieldlucencyright152: [{id: 153, value: "normal", title: "Normal"}],
        Lungsinusesconditionleft153: [{id: 154, value: "normal", title: "Normal"}],
        Lungpleuraconditionleft154: [{id: 155, value: "normal", title: "Normal"}],
        Mediastinumlocation155: [{id: 156, value: "normal", title: "Normal"}],
        Mediastinumsize156: [{id: 157, value: "normal", title: "Normal"}],
        Diaphragmlocation157: [{id: 158, value: "normal", title: "Normal"}],
        Heartsize158: [{id: 159, value: "normal", title: "Normal"}],
        Aorticarch159: [{id: 160, value: "normal", title: "Normal"}],
        Kidneymarginsmoothnessright160: [{id: 161, value: "normal", title: "Normal"}],
        Kidneylocationright161: [{id: 162, value: "normal", title: "Normal"}],
        Kidneyshaperight162: [{id: 163, value: "normal", title: "Normal"}],
        Kidneypelvicalycealsystemright163: [{id: 164, value: "normal", title: "Normal"}],
        Kidneymarginsmoothnessleft164: [{id: 165, value: "normal", title: "Normal"}],
        Kidneylocationleft165: [{id: 166, value: "normal", title: "Normal"}],
        Kidneyshapeleft166: [{id: 167, value: "normal", title: "Normal"}],
        Kidneypelvicalycealsystemleft167: [{id: 168, value: "normal", title: "Normal"}],
        Lungrootcompositionright168: [{id: 169, value: "altered", title: "Altered"}],
        Lungrootcompositionleft169: [{id: 170, value: "altered", title: "Altered"}],
        Liverparenchymadensity170: [{id: 171, value: "altered", title: "Altered"}],
        Liverparenchymacomposition171: [{id: 172, value: "normal", title: "Normal"}],
        Pancreasparenchymadensity172: [{id: 173, value: "altered", title: "Altered"}],
        Pancreasparenchymacomposition173: [{id: 174, value: "normal", title: "Normal"}],
        Spleenparenchymadensity174: [{id: 175, value: "altered", title: "Altered"}],
        Spleenparenchymacomposition175: [{id: 176, value: "normal", title: "Normal"}],
        Aortaabdominal176: [{id: 177, value: "normal", title: "Normal"}],
        Abdominalfluid177: [{id: 178, value: "normal", title: "Normal"}],
        Abdominallymphnodessize178: [{id: 179, value: "altered", title: "Altered"}],
        Retroperitoneallymphnodessize179: [{id: 180, value: "normal", title: "Normal"}],
        Trichomonasvaginalisqualitative180: [{id: 181, value: "normal", title: "Normal"}],
        Chlamydiatrachomatisqualitative181: [{id: 182, value: "normal", title: "Normal"}],
        Ureaplasmaurealyticum182: [{id: 183, value: "0", title: "0"}],
        Ureaplasmaparvum183: [{id: 184, value: "3.1", title: "3.1"}],
    };

    const getOption = () => {
        switch (utype) {
            case "gender":
                return data.gender;
            case "race":
                return data.race;
            case "smoking_status":
                return data.smoking_status;
            case "drugs_status":
                return data.drugs_status;
            case "diabetes_status":
                return data.diabetes_status;
            case "Dateofbirth0":return data.Dateofbirth0;
            case "Gender1":return data.Gender1;
            case "Smoking2":return data.Smoking2;
            case "Hemoglobin3":return data.Hemoglobin3;
            case "HematocritHCT4":return data.HematocritHCT4;
            case "RedbloodcellRBCcount5":return data.RedbloodcellRBCcount5;
            case "MeancorpuscularvolumeMCV6":return data.MeancorpuscularvolumeMCV6;
            case "MeancorpuscularhemoglobinMCH7":return data.MeancorpuscularhemoglobinMCH7;
            case "MeancorpuscularhemoglobinconcentrationMCHC8":return data.MeancorpuscularhemoglobinconcentrationMCHC8;
            case "RedbloodcelldistributionwidthstandarddeviationRDWSD9":return data.RedbloodcelldistributionwidthstandarddeviationRDWSD9;
            case "RedbloodcelldistributionwidthcoefficientofvariationRDWCV10":return data.RedbloodcelldistributionwidthcoefficientofvariationRDWCV10;
            case "PlateletcritPCT11":return data.PlateletcritPCT11;
            case "PlateletcountPLT12":return data.PlateletcountPLT12;
            case "MeanplateletvolumeMPV13":return data.MeanplateletvolumeMPV13;
            case "PlateletdistributionwidthPDW14":return data.PlateletdistributionwidthPDW14;
            case "PlateletlargecellratioPLCR15":return data.PlateletlargecellratioPLCR15;
            case "WhitebloodcellcountWBC16":return data.WhitebloodcellcountWBC16;
            case "NeutrophilcountNEUT17":return data.NeutrophilcountNEUT17;
            case "NeutrophilcountNEUT18":return data.NeutrophilcountNEUT18;
            case "EosinophilcountEO19":return data.EosinophilcountEO19;
            case "EosinophilcountEO20":return data.EosinophilcountEO20;
            case "BasophilcountBASO21":return data.BasophilcountBASO21;
            case "BasophilcountBASO22":return data.BasophilcountBASO22;
            case "LymphocytecountLYMPH23":return data.LymphocytecountLYMPH23;
            case "LymphocytecountLYMPH24":return data.LymphocytecountLYMPH24;
            case "MonocytecountMONO25":return data.MonocytecountMONO25;
            case "MonocytecountMONO26":return data.MonocytecountMONO26;
            case "Glucose27":return data.Glucose27;
            case "Bilirubintotal28":return data.Bilirubintotal28;
            case "Bilirubindirect29":return data.Bilirubindirect29;
            case "Bilirubinindirect30":return data.Bilirubinindirect30;
            case "Creatinine31":return data.Creatinine31;
            case "Urea32":return data.Urea32;
            case "Uricacid33":return data.Uricacid33;
            case "Proteintotal34":return data.Proteintotal34;
            case "Albumin35":return data.Albumin35;
            case "TriglyceridesTG36":return data.TriglyceridesTG36;
            case "Cholesteroltotal37":return data.Cholesteroltotal37;
            case "HighdensitylipoproteincholesterolHDLC38":return data.HighdensitylipoproteincholesterolHDLC38;
            case "LowdensitylipoproteincholesterolLDLC39":return data.LowdensitylipoproteincholesterolLDLC39;
            case "VerylowdensitylipoproteincholesterolVLDLC40":return data.VerylowdensitylipoproteincholesterolVLDLC40;
            case "AspartateaminotransferaseAST41":return data.AspartateaminotransferaseAST41;
            case "AlanineaminotransferaseALT42":return data.AlanineaminotransferaseALT42;
            case "Amylasealpha43":return data.Amylasealpha43;
            case "Amylasepancreatic44":return data.Amylasepancreatic44;
            case "GammaglutamyltransferaseGGT45":return data.GammaglutamyltransferaseGGT45;
            case "Alkalinephosphatase46":return data.Alkalinephosphatase46;
            case "Iron47":return data.Iron47;
            case "Magnesium48":return data.Magnesium48;
            case "Calcium49":return data.Calcium49;
            case "Phosphorus50":return data.Phosphorus50;
            case "CreactiveproteinCRP51":return data.CreactiveproteinCRP51;
            case "Prothrombin52":return data.Prothrombin52;
            case "InternationalNormalizedRatioINR53":return data.InternationalNormalizedRatioINR53;
            case "Thrombintime54":return data.Thrombintime54;
            case "Cortisol55":return data.Cortisol55;
            case "ThyroidstimulatinghormoneTSH56":return data.ThyroidstimulatinghormoneTSH56;
            case "TriiodothyroninefreeT3free57":return data.TriiodothyroninefreeT3free57;
            case "ThyroxinefreeT4free58":return data.ThyroxinefreeT4free58;
            case "Testosteronetotal59":return data.Testosteronetotal59;
            case "Testosteronefree60":return data.Testosteronefree60;
            case "SexhormonebindingglobulinSHBG61":return data.SexhormonebindingglobulinSHBG61;
            case "LuteinizinghormoneLH62":return data.LuteinizinghormoneLH62;
            case "FolliclestimulatinghormoneFSH63":return data.FolliclestimulatinghormoneFSH63;
            case "Insulin64":return data.Insulin64;
            case "GlycatedhemoglobinHbA1C65":return data.GlycatedhemoglobinHbA1C65;
            case "Carbohydrateantigen199CA19966":return data.Carbohydrateantigen199CA19966;
            case "CarcinoembryonicantigenCEA67":return data.CarcinoembryonicantigenCEA67;
            case "Urinecolor68":return data.Urinecolor68;
            case "Urinespecificgravity69":return data.Urinespecificgravity69;
            case "UrinepH70":return data.UrinepH70;
            case "Proteininurine71":return data.Proteininurine71;
            case "Glucoseinurine72":return data.Glucoseinurine72;
            case "Bilirubininurine73":return data.Bilirubininurine73;
            case "Urobilinogeninurine74":return data.Urobilinogeninurine74;
            case "Nitritesinurine75":return data.Nitritesinurine75;
            case "RBCinurine76":return data.RBCinurine76;
            case "RBCcastsinurine77":return data.RBCcastsinurine77;
            case "WBCinurine78":return data.WBCinurine78;
            case "WBCcastsinurine79":return data.WBCcastsinurine79;
            case "Ketonesinurine80":return data.Ketonesinurine80;
            case "Squamousepithelialcellsinurine81":return data.Squamousepithelialcellsinurine81;
            case "Transitionalepithelialcells82":return data.Transitionalepithelialcells82;
            case "Renalepithelialcells83":return data.Renalepithelialcells83;
            case "Hyalinecasts84":return data.Hyalinecasts84;
            case "Granularcasts85":return data.Granularcasts85;
            case "Waxycasts86":return data.Waxycasts86;
            case "RBCbyNechiporenkomethod87":return data.RBCbyNechiporenkomethod87;
            case "WBCbyNechiporenkomethod88":return data.WBCbyNechiporenkomethod88;
            case "CastsbyNechiporenkomethod89":return data.CastsbyNechiporenkomethod89;
            case "VitaminB1290":return data.VitaminB1290;
            case "HydroxyvitaminD91":return data.HydroxyvitaminD91;
            case "Folicacid92":return data.Folicacid92;
            case "Potassium93":return data.Potassium93;
            case "Sodium94":return data.Sodium94;
            case "Chloride95":return data.Chloride95;
            case "Ferritin96":return data.Ferritin96;
            case "TriiodothyroninetotalT3total97":return data.TriiodothyroninetotalT3total97;
            case "ThyroxinetotalT4total98":return data.ThyroxinetotalT4total98;
            case "ErythrocytesedimentationrateESRbyWestergrenmethod99":return data.ErythrocytesedimentationrateESRbyWestergrenmethod99;
            case "Thyroidlocation100":return data.Thyroidlocation100;
            case "Thyroidlobesvolume101":return data.Thyroidlobesvolume101;
            case "Thyroidcomposition102":return data.Thyroidcomposition102;
            case "Thyroidechogenicity103":return data.Thyroidechogenicity103;
            case "Thyroidmarginsmoothness104":return data.Thyroidmarginsmoothness104;
            case "RRinterval105":return data.RRinterval105;
            case "Heartrate106":return data.Heartrate106;
            case "ECGrhythm107":return data.ECGrhythm107;
            case "Alphaangleelectricalheartaxis108":return data.Alphaangleelectricalheartaxis108;
            case "Pwave109":return data.Pwave109;
            case "PQinterval110":return data.PQinterval110;
            case "QRScomplex111":return data.QRScomplex111;
            case "Kidneylengthleft112":return data.Kidneylengthleft112;
            case "Kidneywidthleft113":return data.Kidneywidthleft113;
            case "Kidneyparenchymawidthinmediansegmentleft114":return data.Kidneyparenchymawidthinmediansegmentleft114;
            case "Kidneylengthright115":return data.Kidneylengthright115;
            case "Kidneywidthright116":return data.Kidneywidthright116;
            case "Kidneyparenchymawidthinmediansegmentright117":return data.Kidneyparenchymawidthinmediansegmentright117;
            case "Livercomposition118":return data.Livercomposition118;
            case "Liverechogenicity119":return data.Liverechogenicity119;
            case "Liversize120":return data.Liversize120;
            case "Livermarginsmoothness121":return data.Livermarginsmoothness121;
            case "Portalveindiameter122":return data.Portalveindiameter122;
            case "Intrahepaticbileductswidth123":return data.Intrahepaticbileductswidth123;
            case "Livervascularpattern124":return data.Livervascularpattern124;
            case "Gallbladdershape125":return data.Gallbladdershape125;
            case "Gallbladdermarginsmoothness126":return data.Gallbladdermarginsmoothness126;
            case "Gallbladderwallthickness127":return data.Gallbladderwallthickness127;
            case "Commonbileductdiameter128":return data.Commonbileductdiameter128;
            case "Pancreascomposition129":return data.Pancreascomposition129;
            case "Pancreasechogenicity130":return data.Pancreasechogenicity130;
            case "Pancreasmarginsmoothness131":return data.Pancreasmarginsmoothness131;
            case "Splenicveindiameter132":return data.Splenicveindiameter132;
            case "Pancreaticductdiameter133":return data.Pancreaticductdiameter133;
            case "Spleencomposition134":return data.Spleencomposition134;
            case "Spleenechogenicity135":return data.Spleenechogenicity135;
            case "Spleenmarginsmoothness136":return data.Spleenmarginsmoothness136;
            case "Totalbacteriacount137":return data.Totalbacteriacount137;
            case "Lactobacillusspp138":return data.Lactobacillusspp138;
            case "Gallbladdermargindefinition139":return data.Gallbladdermargindefinition139;
            case "Pancreasmargindefinition140":return data.Pancreasmargindefinition140;
            case "Systolicbloodpressure141":return data.Systolicbloodpressure141;
            case "Diastolicbloodpressure142":return data.Diastolicbloodpressure142;
            case "Lungvolumeright143":return data.Lungvolumeright143;
            case "Lungpatternright144":return data.Lungpatternright144;
            case "Lungrootright145":return data.Lungrootright145;
            case "Lungfieldlucencyleft146":return data.Lungfieldlucencyleft146;
            case "Lungsinusesconditionright147":return data.Lungsinusesconditionright147;
            case "Lungpleuraconditionright148":return data.Lungpleuraconditionright148;
            case "Lungvolumeleft149":return data.Lungvolumeleft149;
            case "Lungpatternleft150":return data.Lungpatternleft150;
            case "Lungrootleft151":return data.Lungrootleft151;
            case "Lungfieldlucencyright152":return data.Lungfieldlucencyright152;
            case "Lungsinusesconditionleft153":return data.Lungsinusesconditionleft153;
            case "Lungpleuraconditionleft154":return data.Lungpleuraconditionleft154;
            case "Mediastinumlocation155":return data.Mediastinumlocation155;
            case "Mediastinumsize156":return data.Mediastinumsize156;
            case "Diaphragmlocation157":return data.Diaphragmlocation157;
            case "Heartsize158":return data.Heartsize158;
            case "Aorticarch159":return data.Aorticarch159;
            case "Kidneymarginsmoothnessright160":return data.Kidneymarginsmoothnessright160;
            case "Kidneylocationright161":return data.Kidneylocationright161;
            case "Kidneyshaperight162":return data.Kidneyshaperight162;
            case "Kidneypelvicalycealsystemright163":return data.Kidneypelvicalycealsystemright163;
            case "Kidneymarginsmoothnessleft164":return data.Kidneymarginsmoothnessleft164;
            case "Kidneylocationleft165":return data.Kidneylocationleft165;
            case "Kidneyshapeleft166":return data.Kidneyshapeleft166;
            case "Kidneypelvicalycealsystemleft167":return data.Kidneypelvicalycealsystemleft167;
            case "Lungrootcompositionright168":return data.Lungrootcompositionright168;
            case "Lungrootcompositionleft169":return data.Lungrootcompositionleft169;
            case "Liverparenchymadensity170":return data.Liverparenchymadensity170;
            case "Liverparenchymacomposition171":return data.Liverparenchymacomposition171;
            case "Pancreasparenchymadensity172":return data.Pancreasparenchymadensity172;
            case "Pancreasparenchymacomposition173":return data.Pancreasparenchymacomposition173;
            case "Spleenparenchymadensity174":return data.Spleenparenchymadensity174;
            case "Spleenparenchymacomposition175":return data.Spleenparenchymacomposition175;
            case "Aortaabdominal176":return data.Aortaabdominal176;
            case "Abdominalfluid177":return data.Abdominalfluid177;
            case "Abdominallymphnodessize178":return data.Abdominallymphnodessize178;
            case "Retroperitoneallymphnodessize179":return data.Retroperitoneallymphnodessize179;
            case "Trichomonasvaginalisqualitative180":return data.Trichomonasvaginalisqualitative180;
            case "Chlamydiatrachomatisqualitative181":return data.Chlamydiatrachomatisqualitative181;
            case "Ureaplasmaurealyticum182":return data.Ureaplasmaurealyticum182;
            case "Ureaplasmaparvum183":return data.Ureaplasmaparvum183;

        }
    };

    return (
        block ?
            <div className="d-grid mr-1 mb-3">
                <p className="mb-0 title-input-model" dangerouslySetInnerHTML={{__html: title}}></p>
                <div className="d-flex align-items-center">
                    <select id={uid} onChange={(e) => setValueByType(utype, e.target.value)}
                            className="input-input-model-gender no-pointer">
                        <option/>
                        {
                            getOption().map((i) =>
                                <option key={i.id} value={i.value}
                                        selected={isDefault[utype] === i.value}>{i.title}</option>
                            )
                        }
                    </select>
                </div>
            </div> :
            isDefault ?
                <div className="d-grid mr-1 mb-3">
                    <p className="mb-0 title-input-model" dangerouslySetInnerHTML={{__html: title}}></p>
                    <div className="d-flex align-items-center">
                        <select id={uid} onChange={(e) => setValueByType(utype, e.target.value)}
                                className="input-input-model-gender pointer-event-none">
                            <option/>
                            {
                                getOption().map((i) =>
                                    <option key={i.id} value={i.value}
                                            selected={isDefault[utype] === i.value}>{i.title}</option>
                                )
                            }
                        </select>
                    </div>
                </div> :
                <div className="d-grid mr-1 mb-3">
                    <p className="mb-0 title-input-model" dangerouslySetInnerHTML={{__html: title}}></p>
                    <div className="d-flex align-items-center">
                        <select id={uid} onChange={(e) => setValueByType(utype, e.target.value)}
                                className="input-input-model-gender">
                            <option/>
                            {
                                getOption().map((i) =>
                                    <option key={i.id} value={i.value}>{i.title}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
    );
}

export default InputSelect;