import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {setKey} from "../../store/reducer";
import {setting_auth} from "../../setting_auth";

function Authentication({history}) {
    const apiUrl = setting_auth.apiUrl;
    const dataStore = useSelector(state => state.load.data);
    const dispatch = useDispatch();

    const [errorMsg, setErrorMsg] = useState('');

    const btn_center = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    };

    const errorStale = {
        fontStyle: "normal",
        fontSize: "18px",
        color: "red",
        fontWeight: "400",

    };

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({
        defaultValues: {
            email: "",
            password: ""
        }
    });

    const onSubmit = async function (data, event) {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                {
                    email: data.email,
                    password: data.password
                })
        };


        fetch(apiUrl + 'auth', requestOptions)
            .then(response => response.json())
            .then(response => {
                    if (response.auth) {
                        localStorage.setItem('key', response.key);
                        window.location.href = '/demo';
                    } else {
                        setErrorMsg(dataStore.Authentication[0].error);
                    }
                }
            );
    }

    const keys = window.localStorage.getItem('key');
    return (
        !keys ?
        <React.Fragment>
            <form
                onSubmit={handleSubmit(onSubmit)}>
                <div className="wrap-auto
                            col-md-8 mb-4"
                >
                    <div id="login"><p className="begin_registr">LOGIN</p></div>

                    <div className="text_registr col-md-8 mb-4">
                        Only registered and logged in users have access to Demo
                    </div>

                    <div className="col-md-8 mb-4">
                        <label>{dataStore && dataStore.Authentication[0].email}</label>
                        <input
                            className={errors.email ? "name form-control error" : "name form-control" }
                            id="email"
                            placeholder="__@__.__"
                            {...register("email", { required: true,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })}
                            type="email"/>
                        <div className={"text_registr_error"}>
                            {errors.email && <p>{dataStore && dataStore.Authentication[0].errorEmail}</p> }
                        </div>
                    </div>

                    <div className="col-md-8 mb-4">
                        <label>{dataStore && dataStore.Authentication[0].password}</label>
                        <input
                            className="name form-control"
                            id="password"
                            {...register("password", {required: true})}
                            type="password"/>
                        <div className={"text_registr_error"}>
                            {errors.password && <p>{dataStore && dataStore.Authentication[0].errorRequired}</p> }
                        </div>
                    </div>
                    <div className="col-md-8 mb-4"
                        style={errorStale}>
                        {errorMsg}
                    </div>
                    <div className="col-md-8 mb-4"
                         style={btn_center}>
                        <input className="btn-violet text-center mt-3 mb-4"
                               type="submit" value={dataStore && dataStore.Authentication[0].logIn}/>
                        <a class="top_menu_href"
                           href="/registration">{dataStore && dataStore.Authentication[0].signIn}</a>
                    </div>
                </div>
            </form>
        </React.Fragment>:
            window.location.replace('/demo')
    )
}

export default Authentication;