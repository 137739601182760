import {combineReducers, configureStore} from "@reduxjs/toolkit";
import LoadReducer from "./reducer";

const rootReducer = combineReducers({
    load: LoadReducer
});


export const store = configureStore({
    reducer: rootReducer
});