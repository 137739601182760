import React, {useEffect, useState} from "react";
import title from "../../../images/demo/pages/title.png";
import MobileMenu from "../../MobileMenu/MobileMenu";
import Menu from "../../Menu/Menu";
import AsideMenuModel from "../../CurrBiometer/Demo/DemoPage/AsideMenuModel";
import {useSelector} from "react-redux";
import Score from "./Score/Score";
import ASCVD from "./ASCVD/ASCVD";
import Type2Diabet from "./Type2Diabet/Type2diabet";
import AcidBaseCalculator from "./AcidBaseCalculator/AcidBaseCalculator";
import Footer from "../../Footer/Footer";
import MetaModel from "./MetaModel/MetaModel";
import Authentication from "../../Authentication/Authentication";

function SimpleModels(props) {
    const {history} = props.history;
    const data = useSelector(state => state.load.data);
    const [page, setPage] = useState(false);

    useEffect(() => {
        setPage(getQuery());
    }, []);

    const getQuery = () => {
        const pathname = window.location.pathname;
        const c = window.location.search;
        const query = c.split("=");

        return {
            query: query[1],
            pathname: pathname
        };
    };

    const keys = window.localStorage.getItem('key');
    return (
        keys ?
            <React.Fragment>
                <title>{data && data.PageDemoSingle[0].pageTitle}</title>
                <MobileMenu history={history}/>
                <Menu history={history}/>
                <div className="container pt-5 pb-5">
                    <div className="d-flex pt-5">
                        {
                            page.query === "score" &&
                            <React.Fragment>
                                <AsideMenuModel query={page.query} pathname={page.pathname}
                                                system={"Cardiovascular system"}/>
                                <Score/>
                            </React.Fragment>
                        }
                        {
                            page.query === "ascvd" &&
                            <React.Fragment>
                                <AsideMenuModel query={page.query} pathname={page.pathname}
                                                system={"Cardiovascular system"}/>
                                <ASCVD/>
                            </React.Fragment>
                        }
                        {
                            page.query === "type2diabet" &&
                            <React.Fragment>
                                <AsideMenuModel query={page.query} pathname={page.pathname}
                                                system={"Endocrine system"}/>
                                <Type2Diabet/>
                            </React.Fragment>
                        }
                        {
                            page.query === "metamodel" &&
                            <React.Fragment>
                                <AsideMenuModel query={page.query} pathname={page.pathname} system={"MetaModel"}/>
                                <MetaModel/>
                            </React.Fragment>
                        }
                        {
                            page.query === "acid-base-calculator" &&
                            <React.Fragment>
                                <AsideMenuModel query={page.query} pathname={page.pathname}
                                                system={"Respiratory system"}/>
                                <AcidBaseCalculator/>
                            </React.Fragment>
                        }
                    </div>
                </div>
                <Footer/>
            </React.Fragment> :
            <Authentication history={history}/>
    );
}

export default SimpleModels;