import React from "react";
import Menu from "../Menu/Menu";
import MobileMenu from "../MobileMenu/MobileMenu";
import Footer from "../Footer/Footer";
import TopArrow from "../TopArrow/TopArrow";
import ProductMenu from "../ProductMenu/ProductMenu";
import ProductMenuMobile from "../ProductMenuMobile/ProductMenuMobile";
import { useSelector } from "react-redux";


function CurrFlowViewer(props) {

    const { history } = props.history;
    const lang = useSelector(state => state.load.lang);
    const data = useSelector(state => state.load.data);

    return (
        <div>
            <title>{data && data.CurrFlowViewer[0].pageTitle}</title>
            <MobileMenu history={history} />
            <Menu history={history} />
            <ProductMenu history={history} />
            <div className="container">
                <div className="wrap-product">
                    <div id="product2" className="product_card productm">
                        <div className="current_card">
                            <div className="wrap_text_card order-0">
                                <div className="text_card">
                                    <div className="begin_products wow animate__slideInLeft" data-wow-duration="2s">
                                        {data && data.CurrFlowViewer[0].caption}
                                    </div>
                                    <div className="back_gradient  wow animate__slideInLeft"
                                        data-wow-duration="2s"></div>
                                </div>
                                <div className="begin_text_product">{data && data.CurrFlowViewer[0].subtitle}</div>
                                <div className="text_product">
                                    {data && data.CurrFlowViewer[0].text[0]}
                                </div>
                                {/*<div id="flowimg"></div>*/}

                            </div>
                            <div id="product_img2" className="wrap_notext_card"></div>
                        </div>
                        <div className="d-none row pb-5 mb-5 mt-4">
                            <div className="col">
                                <p className="project_about">CFD, or Computational Fluid Dynamics - a special area
                                    of mathematics/fluid mechanics, is routinely employed in a diverse range of
                                    safety-critical engineering systems, and being now increasingly often applied to
                                    the cardiovascular system.
                                </p>
                            </div>
                            <div className="col">
                                <p className="project_about">
                                    Combined with empirical data from cardiovascular imaging, MRI/CT, CFD modeling
                                    in FlowViewer software enables detailed characterization of complex
                                    physiological pressure and flow fields.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item_image" id="item2">
                    <img src="../images/product/flo2.jpg" className="image_productes" alt=""/>
                </div>
                <div className="row mb-5">
                    <div className="col">
                        <p className="project_about">
                            {data && data.CurrFlowViewer[0].text[3]}
                        </p>
                    </div>
                    <div className="col">
                        <p className="project_about">
                            {data && data.CurrFlowViewer[0].text[4]}
                        </p>
                    </div>
                </div>
                {/* 
                <div className="item_image" id="item1">
                    <img src="../images/product/flo1.jpg" className="image_productes" alt="" />
                </div>
                 */}
                <div className="row pb-5 mb-5">
                    <div className="col">
                        <p className="project_about">
                            {data && data.CurrFlowViewer[0].text[1]}
                        </p>
                    </div>
                    <div className="col">
                        <p className="project_about">
                            {data && data.CurrFlowViewer[0].text[2]}
                        </p>
                    </div>
                </div>

                <div className="item_image" id="item3">
                    <img src="../images/product/flo3.jpg" className="image_productes" alt="" />
                </div>

                <div className="row">
                    <div className="col">
                        <p className="project_about">
                            {data && <div dangerouslySetInnerHTML={{ __html: data.CurrFlowViewer[0].text[5] }}></div>}
                        </p>
                    </div>
                    <div className="col">
                        <p className="project_about">
                            {data && <div dangerouslySetInnerHTML={{ __html: data.CurrFlowViewer[0].text[6] }}></div>}
                        </p>
                    </div>
                </div>
                <div className="row mb-5 pt-3">
                    <div className="col">
                        <p className="project_about">
                            {data && data.CurrFlowViewer[0].text[7]}
                        </p>
                    </div>
                    <div className="col">
                        <p className="project_about">
                            <ul className="ml-3 ml-md-0">
                                <li className="project_about">{data && data.CurrFlowViewer[0].text[8]}</li>
                                <li className="project_about">{data && data.CurrFlowViewer[0].text[9]}</li>
                                <li className="project_about">{data && data.CurrFlowViewer[0].text[10]}</li>
                                <li className="project_about">{data && data.CurrFlowViewer[0].text[11]}</li>
                            </ul>
                        </p>
                    </div>
                </div>

                <div className="bottom_btn_gen">
                    <a href="/contact" className="btn-violet">
                        {data && data.CurrBiometer[0].btn}
                    </a>
                </div>
                <ProductMenuMobile history={history} />
            </div>
            <Footer />
            <TopArrow />
        </div>
    )
}

export default CurrFlowViewer;
