import React from "react";
import web from "../../../images/demo/pages/web.svg";
import contact from "../../../images/demo/pages/contact.svg";

function Clinically() {
    return (
        <section className="section-left">
            <div className="d-flex align-items-center pt-5 mt-3 w-100">
                <h1 className="mr-auto text-white">Clinical Risk Assessment Tools</h1>
                {/*<div className="button-group">*/}
                {/*    <a href="#" className="button-company d-flex align-items-center position-relative mb-2"*/}
                {/*       target="_blank">*/}
                {/*        <img className="position-absolute mlbi" src={web} alt=""/>*/}
                {/*        <p className="mb-0 mlb m-auto">Website</p>*/}
                {/*    </a>*/}
                {/*    <a href="#" className="button-company d-flex align-items-center position-relative mb-2"*/}
                {/*       target="_blank">*/}
                {/*        <img className="position-absolute mlbi" src={contact} alt=""/>*/}
                {/*        <p className="mb-0 mlb m-auto">Contact</p>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
            <p className="mb-0 pt-3 pb-2 text-white">
                Clinical risk assessment tools (scales, calculators) are the array of instruments based on results of
                large multidisciplinary cohort studies and clinical trials (such as well-known Framingham study).
                Provided here scales and calculators are adopted by leading world health agencies (e.g., European
                Society of Cardiology, American Diabetes Association) as part of clinical guidelines. Quick access to
                recommended clinical risk assessment tools ensures that the clinician’s work is optimized and results in
                better care of patients.
            </p>
            <hr style={{background: "#6B7BB5"}}/>
            <h1 className="model-name pt-2 pb-2">MODELS</h1>
            <div className="d-flex align-items-center">
                <button onClick={() => document.location.href = "/demo/model/?id=score"}
                        className="model-btn d-flex align-items-center">
                    <p className="mb-0 ml-4">Score</p>
                </button>
                <button onClick={() => document.location.href = "/demo/model/?id=ascvd"}
                        className="model-btn d-flex align-items-center ml-3">
                    <p className="mb-0 ml-4">ASCVD</p>
                </button>
            </div>
        </section>
    );
}

export default Clinically;