import React from "react";
import logo from "../../../images/demo/cardio-shadow.svg";

function Cardiovascular() {
    return (
        <section className="section-left">
            <div className="d-flex align-items-center pt-5 mt-3 w-100">
                <img className="img-by-system" src={logo} alt=""/>
                <div className="d-grid">
                    <h1 className="title-by-system mb-0">Cardiovascular</h1>
                    <h1 className="title-by-system">system</h1>
                </div>
            </div>
            <p className="mb-0 pt-3 pb-2 text-white">
                Cardiovascular diseases (CVDs), such as coronary heart disease, cerebrovascular disease, rheumatic heart
                disease, are the leading cause of mortality worldwide. Influence of environmental and behavioral risk
                factors manifests as changes in clinical biomarkers of CVDs, that serve as indicators of risk for
                certain CVDs and complications. The most significant biomarkers that represent cardiovascular system in
                health and disease are listed below.
            </p>
            <div className="d-flex align-items-center">
                <ul>
                    <li className="text-white">Blood pressure</li>
                    <li className="text-white">Chronological age</li>
                    <li className="text-white">International normalized ratio</li>
                    <li className="text-white">High-density lipoprotein cholesterol</li>
                    <li className="text-white">Low-density lipoprotein cholesterol</li>
                    <li className="text-white">Very low-density lipoprotein cholesterol</li>
                    <li className="text-white">Hemoglobin</li>
                    <li className="text-white">Lymphocytes count</li>
                    <li className="text-white">Uric acid</li>
                    <li className="text-white">Sodium</li>
                    <li className="text-white">Cardiac ejection fraction</li>
                </ul>
                <ul>
                    <li className="text-white">D-dimer</li>
                    <li className="text-white">ApoB</li>
                    <li className="text-white">ApoA1</li>
                    <li className="text-white">Total cholesterol</li>
                    <li className="text-white">Triglycerides</li>
                    <li className="text-white">Creatine kinase</li>
                    <li className="text-white">Lactate dehydrogenase</li>
                    <li className="text-white">Magnesium</li>
                    <li className="text-white">NT-proBNP</li>
                    <li className="text-white">Troponin I</li>
                    <li className="text-white">Amylase alpha</li>
                </ul>
                <ul>
                    <li className="text-white">MRI- and sonography-derived parameters of blood vessels and heart</li>
                    <li className="text-white">Hemoglobin</li>
                    <li className="text-white">Basophiles count</li>
                    <li className="text-white">Lymphocytes count</li>
                    <li className="text-white">Red blood cells count</li>
                    <li className="text-white">Mean Cell Hemoglobin (MCH)</li>
                    <li className="text-white">Mean cell hemoglobin concentration (MCHC)</li>
                    <li className="text-white">Mean cell volume (MCV)</li>
                    <li className="text-white">Aortic pressure gradient</li>
                    <li className="text-white">Creatinine</li>
                    <li className="text-white">Bilirubin</li>
                    <li className="text-white">ECG parameters</li>
                </ul>
            </div>


            <hr style={{background: "#6B7BB5"}}/>
            <h1 className="model-name pt-2 pb-2">MODELS</h1>
            <div className="d-flex align-items-center">
                <button onClick={() => document.location.href = "/demo/model/?id=score"}
                        className="model-btn-system d-flex align-items-center">
                    <p className="mb-0 ml-4 mr-2">Score</p>
                </button>
                <button onClick={() => document.location.href = "/demo/model/?id=ascvd"}
                        className="model-btn-system d-flex align-items-center ml-3">
                    <p className="mb-0 ml-4 mr-2">ASCVD</p>
                </button>
            </div>
        </section>
    );
}

export default Cardiovascular;