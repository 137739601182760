import React from "react";

function InputDate({title, setValueByType, uid, utype, isDefault, block}) {

    return (
        block ?
            <div className="d-grid mr-1 mb-3">
                <p className="mb-0 title-input-model" dangerouslySetInnerHTML={{__html: title}}></p>
                <div className="d-flex align-items-center">
                    <input lang="fr-CA" id={uid} onChange={(e) => setValueByType(utype, e.target.value)}
                           className="input-input-model-data no-pointer" type="date" value={isDefault[utype]}/>
                </div>
            </div> :
            isDefault ?
                <div className="d-grid mr-1 mb-3">
                    <p className="mb-0 title-input-model" dangerouslySetInnerHTML={{__html: title}}></p>
                    <div className="d-flex align-items-center">
                        <input lang="fr-CA" id={uid} onChange={(e) => setValueByType(utype, e.target.value)}
                               className="input-input-model-data pointer-event-none" type="date"
                               value={isDefault[utype]}/>
                    </div>
                </div> :
                <div className="d-grid mr-1 mb-3">
                    <p className="mb-0 title-input-model" dangerouslySetInnerHTML={{__html: title}}></p>
                    <div className="d-flex align-items-center">
                        <input lang="fr-CA" id={uid} onChange={(e) => setValueByType(utype, e.target.value)}
                               className="input-input-model-data" type="date"/>
                    </div>
                </div>
    );
}

export default InputDate;