import React from "react";
import {VictoryPie} from 'victory';

function VictoryPieWrap({num, color}) {

    const getColor = () => {
        switch (color) {
            case "red":
                return "#FF0000";
            default:
                return "#92A6E9";
        }
    };

    return (
        <div className="wrap-pie">
            <VictoryPie
                padAngle={0}
                labelComponent={<span/>}
                innerRadius={70}
                width={154} height={154}
                data={[{'key': "", 'y': num}, {'key': "", 'y': (100 - num)}]}
                colorScale={[getColor(), "#ffffff"]}
            />
        </div>
    );
}

export default VictoryPieWrap;