import React from "react";
import logo from "../../../images/demo/reproductive-shadow.svg";

function Reproductive() {
    return (
        <section className="section-left">
            <div className="d-flex align-items-center pt-5 mt-3 w-100">
                <img className="img-by-system" src={logo} alt=""/>
                <div className="d-grid">
                    <h1 className="title-by-system mb-0">Reproductive system</h1>
                    <h1 className="title-by-system">Prenatal risks</h1>
                </div>
            </div>
            <p className="mb-0 pt-3 pb-2 text-white">
                The uniqueness of reproductive health status of patients is that in many cases it affects both the
                patients and their offspring. This is why Reproductive System includes models for prenatal risks
                estimation, with Preeclampsia and Trisomy 21 models being available in BioMeter demo.
            </p>
            <p className="mb-0 pt-3 pb-2 text-white">
                According to WHO, among the most impactful reproductive conditions are endometriosis, which occurs in
                approximately 10% of women of reproductive age, and neonatal conditions, specifically birth
                complications, which are among top global causes of death. Some conditions, such as preeclampsia, can be
                prevented by timely pharmacological interventions. Therefore, it is of great importance for medical
                specialists to have a reliable risk assessment tool at their disposal. Reproductive system status is
                represented by biomarkers listed below.
            </p>
            <div className="d-flex align-items-center">
                <ul>
                    <li className="text-white">PSA</li>
                    <li className="text-white">Anti-Mullerian hormone</li>
                    <li className="text-white">Sex hormone-binding globulin</li>
                    <li className="text-white">Inhibin В</li>
                    <li className="text-white">Interleukin 6</li>
                    <li className="text-white">Male and female sex hormones</li>
                    <li className="text-white">Components of complete blood count</li>
                    <li className="text-white">Sonography-derived parameters of reproductive organs</li>
                    <li className="text-white">Chronological age</li>
                    <li className="text-white">Fetal crown rump length</li>
                    <li className="text-white">Fetal nuchal translucency</li>
                </ul>
                <ul>
                    <li className="text-white">Fetal heart rate</li>
                    <li className="text-white">Pregnancy associated plasma protein A</li>
                    <li className="text-white">Human chorionic gonadotropin, beta subunit</li>
                    <li className="text-white">Ductus venosus pulsatility index</li>
                    <li className="text-white">Fetal nasal bone</li>
                    <li className="text-white">Fetal tricuspid regurgitation</li>
                    <li className="text-white">Placental growth factor</li>
                    <li className="text-white">Mean arterial pressure</li>
                    <li className="text-white">Uterine artery pulsatility index</li>
                    <li className="text-white">Placental growth factor</li>
                    <li className="text-white">Soluble fms-like tyrosine kinase-1</li>
                </ul>
            </div>
        </section>
    );
}

export default Reproductive;