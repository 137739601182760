
    import React from "react";
    import Menu from "../Menu/Menu";
    import MobileMenu from "../MobileMenu/MobileMenu";
    import Footer from "../Footer/Footer";
    import TopArrow from "../TopArrow/TopArrow";
    import Contact from "../Contact/Contact";
    import {useSelector} from "react-redux";
    
    function PageContact(props) {

        const { history } = props.history;
        const data = useSelector(state => state.load.data);

        return (
            <div>
                <title>{data && data.Contact[0].pageTitle}</title>
                <MobileMenu history={history}/>
                <Menu history={history}/>
                <Contact/>
                <Footer page={true}/>
                <TopArrow/>
            </div>
        );
    }

export default PageContact;
