
    import React from "react";
    import {useSelector} from "react-redux";

    function ParentFlowViewer() {

        const data = useSelector(state => state.load.data);

        return (
            <div id="product2" className="product_card wow animated animate__fadeIn" data-wow-duration="2s">
                <div className="current_card">
                    <div className="wrap_text_card order-0">
                        <div className="text_card">
                            <div className="begin_products wow animated animate__slideInLeft" data-wow-duration="2s">{data && data.ParentFlowViewer[0].caption}
                            </div>
                            <div className="back_gradient wow animated animate__slideInLeft" data-wow-duration="2s"/>
                        </div>
                            <div>
                                <div className="begin_text_product">{data && data.ParentFlowViewer[0].text[0]}</div>
                                <div className="text_product">{data && data.ParentFlowViewer[0].text[1]}
                                </div>
                            </div>
                        <div id="flowimg"/>
                    </div>
                    <div id="product_img2" className="wrap_notext_card"/>
                </div>
                <div className="button_product">
                    <a href="../products/flowviewer">
                        <button className="product_btn">{data && data.ParentFlowViewer[0].textbtn}</button>
                    </a>
                </div>
            </div>
        )
    }

export default ParentFlowViewer;
