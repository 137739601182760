import React from "react";
import {useSelector} from "react-redux";
import LangButton from "../LangButton/LangButton";
import {useHistory} from "react-router";
import logo from '../../images/logo.png';

function Menu(props) {

    const history = useHistory();
    const curr = history.location.pathname;
    const currs = curr.split('/');

    const lang = useSelector(state => state.load.lang);
    const data = useSelector(state => state.load.data);

    const keys = localStorage.getItem("key");

    const clearLogin = () => {
        localStorage.setItem('key', '');
        window.location.replace("/");
    };

    return(
        <header className="">
            <div className="container d-flex align-items-center">
                <div className="logo mr-auto">
                    <a href="/">
                        <img src={logo} height="50" alt="BioME"/>
                    </a>
                </div>
                    <div className={lang === 'fr' ? "top_menu fr" : "top_menu"}>
                        <a className={currs[1] === 'products' && currs[2] === 'flowviewer' ? "top_menu_href active noclick product" : 'top_menu_href noclick product'}
                           href="/products/flowviewer">
                            {data && data.Menu[0].items[0]}
                        </a>
     
                        <a className={currs[1] === 'products' && currs[2] === 'biometer' ? "top_menu_href active noclick product" : 'top_menu_href noclick product'}
                           href="/products/biometer">
                            {data && data.Menu[0].items[1]}
                        </a>
                 
                        <a className={curr === '/contact' ? "top_menu_href active" : 'top_menu_href'}
                           href="/contact">
                            {data && data.Menu[0].items[3]}
                        </a>
						{
                            keys ?
                                <div className="d-grid">
                                    <a className={curr === '/demo' || curr === '/demo/company/' || curr === '/demo/system/' ? "top_menu_href active" : 'top_menu_href'}
                                       href="/demo">{data && data.Menu[0].items[6]}</a>
                                    <a className="top_menu_href m-0 on-hover" style={{color: "#C4C4C4", width: "87px"}} onClick={() => clearLogin()}>EXIT</a>
                                </div> :
                                <a className={curr === '/demo' || curr === '/demo/company/' || curr === '/demo/system/' ? "top_menu_href active" : 'top_menu_href'}
                                   href="/demo">{data && data.Menu[0].items[6]}
                               </a>
                        }
                        {/* 
                        <LangButton />
                        */}
                    </div>
            </div>
        </header>
    )
}

export default Menu;
