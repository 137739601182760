import React from "react";
import logo from "../../../images/demo/endocrine-shadow.svg";

function Endocrine() {
    return (
        <section className="section-left">
            <div className="d-flex align-items-center pt-5 mt-3 w-100">
                <img className="img-by-system" src={logo} alt=""/>
                <div className="d-grid">
                    <h1 className="title-by-system mb-0">Endocrine</h1>
                    <h1 className="title-by-system">system</h1>
                </div>
            </div>
            <p className="mb-0 pt-3 pb-2 text-white">
                Diseases of the thyroid and diabetes are among the most prevalent endocrine conditions. WHO regularly
                includes diabetes in top-10 mortality causes, with 1.5 million deaths reported in 2019. However,
                diabetes can be treated to delay or even prevent the onset of complications. Risk prediction models can
                identify high-risk individuals at early stages of disease development and ensure maximum benefit from
                appropriate treatment. Endocrine system status is represented by biomarkers listed below.
            </p>
            <div className="d-flex align-items-center">
                <ul>
                    <li className="text-white">17-OH-progesterone</li>
                    <li className="text-white">25-OH vitamin D</li>
                    <li className="text-white">Adrenocorticotropic Hormone</li>
                    <li className="text-white">Albumin</li>
                    <li className="text-white">Alanine aminotransferase</li>
                    <li className="text-white">Anti-thyreoglobulin</li>
                    <li className="text-white">Anti-thyroidea peroxidase</li>
                    <li className="text-white">Aspartate aminotransferase</li>
                    <li className="text-white">Components of complete blood count</li>
                    <li className="text-white">Bilirubin</li>
                    <li className="text-white">Calcium</li>
                </ul>
                <ul>
                    <li className="text-white">Cholesterol total</li>
                    <li className="text-white">Cortisol</li>
                    <li className="text-white">Creatinine</li>
                    <li className="text-white">Dehydroepiandrosterone sulfate</li>
                    <li className="text-white">Erythrocyte sedimentation rate</li>
                    <li className="text-white">Free Triiodothyronine</li>
                    <li className="text-white">Free Thyroxine</li>
                    <li className="text-white">Follicle stimulating hormone</li>
                    <li className="text-white">Gamma glutamyl transpeptidase</li>
                    <li className="text-white">Glucose</li>
                    <li className="text-white">Glycated hemoglobin</li>
                </ul>
                <ul>
                    <li className="text-white">High-density lipoprotein cholesterol</li>
                    <li className="text-white">Insulin</li>
                    <li className="text-white">Low-density lipoprotein cholesterol</li>
                    <li className="text-white">Luteinizing hormone</li>
                    <li className="text-white">Alkaline phosphatase</li>
                    <li className="text-white">Phosphorus</li>
                    <li className="text-white">Prolactin</li>
                    <li className="text-white">Parathyroid hormone (PTH)</li>
                    <li className="text-white">Sonography-derived parameters of endocrine organs</li>
                </ul>
            </div>

            <hr style={{background: "#6B7BB5"}}/>
            <h1 className="model-name pt-2 pb-2">MODELS</h1>
            <div className="d-flex align-items-center">
                <button onClick={() => document.location.href = "/demo/model/?id=type2diabet"}
                        className="model-btn-system d-flex align-items-center">
                    <p className="mb-0 ml-4 mr-2">Type 2 Diabetes Model</p>
                </button>
            </div>
        </section>
    );
}

export default Endocrine;