import React from "react";
import back from '../../../../images/demo/icon/back.svg';
import '../../../../demo-page.css'
import Cardiovascular from "./ByModel/Cardiovascular";
import Endocrine from "./ByModel/Endocrine";
import MetaModel from "./ByModel/Metamodel";
import Respiratory from "./ByModel/Respiratory";

function AsideMenuModel({query, pathname, system}) {
    const getActive = (href) => {
        const p = href.split("?");
        const pa = p[0];
        const q = p[1].split("=");
        const qu = q[1];

        return pathname === pa && qu === query;
    };

    return (
        <div>
            {
                system === "Cardiovascular system" &&
                <React.Fragment>
                    <div className="d-flex align-items-center pb-3">
                        <a href="/demo/system/?id=cardiovascular">
                            <img className="on-hover" src={back} alt=""/>
                        </a>
                        <p className="mb-0 demo-page-title ml-3">DEMO</p>
                    </div>
                    <Cardiovascular getActive={getActive}/>
                </React.Fragment>

            }
            {
                system === "Endocrine system" &&
                <React.Fragment>
                    <div className="d-flex align-items-center pb-3">
                        <a href="/demo/system/?id=endocrine">
                            <img className="on-hover" src={back} alt=""/>
                        </a>
                        <p className="mb-0 demo-page-title ml-3">DEMO</p>
                    </div>
                    <Endocrine getActive={getActive}/>
                </React.Fragment>

            }
            {
                system === "Respiratory system" &&
                <React.Fragment>
                    <div className="d-flex align-items-center pb-3">
                        <a href="/demo/system/?id=respiratory">
                            <img className="on-hover" src={back} alt=""/>
                        </a>
                        <p className="mb-0 demo-page-title ml-3">DEMO</p>
                    </div>
                    <Respiratory getActive={getActive}/>
                </React.Fragment>

            }
            {
                system === "MetaModel" &&
                <React.Fragment>
                    <div className="d-flex align-items-center pb-3">
                        <a href="/demo/company/?id=metamodel">
                            <img className="on-hover" src={back} alt=""/>
                        </a>
                        <p className="mb-0 demo-page-title ml-3">DEMO</p>
                    </div>
                    <MetaModel getActive={getActive}/>
                </React.Fragment>

            }
        </div>
    );
}

export default AsideMenuModel;