import React, { useEffect, useState } from "react";
import { setting } from "../../../CurrBiometer/Demo/setting";
import InputDate from "../Inputs/InputDate";
import InputNumber from "../Inputs/InputNumber";
import InputSelect from "../Inputs/InputSelect";
import defaultParam from './defaultParam';
import input from './input';
import ResultAcidBase from "../Inputs/ResultAcidBase";
import axios from "axios";

function AcidBaseCalculator() {
    const apiUrl = setting.apiUrl;
    const [block, setBlock] = useState(1);

    const initialMeasurements = {},
        requiredMeasurements = [];
    input.forEach(i => {
        initialMeasurements[i.utype] = {
            id_result_argument: i.id_result_argument,
            value: false
        }
        if (i.required) {
            requiredMeasurements.push(i.utype);
        }
    });
    const [measurements, setMeasurements_] = useState(initialMeasurements);
    const setMeasurements = (param, value) => {
        setMeasurements_(prevState => ({
            ...prevState,
            [param]: {
                id_result_argument: prevState[param].id_result_argument,
                value: value
            }
        }));
    };

    const [result, setResult] = useState([]);
    const [findPram, setFindParam] = useState(true);
    const [isDefault, setDefault] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [defaultTexts, setDefaultTexts] = useState(false);

    useEffect(() => {
        findParam();
    }, [measurements]);

    const findParam = () => {
        let foundAll = true;
        requiredMeasurements.forEach(m => {
            if (!measurements[m].value) {
                foundAll = false;
            }
        });
        setFindParam(foundAll);
    };

    const setValueByType = (type, value) => {
        setMeasurements(type, value);
    };

    const defaultText = [
        {
            id: 1,
            text: "Patient 1. Reported symptoms include anxiety, confusion, headache. Discolored skin tone is observed."
        },
        {
            id: 2,
            text: "Patient 2. Reported symptoms include tachycardia, confusion, fatigue, loss of appetite."
        },
        {
            id: 3,
            text: "Patient 3. Reported symptoms include confusion, lightheadedness. Hand tremor is observed."
        }
    ];

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        if (month <= 9) {
            var month_ = "0" + (Number(month));
        } else {
            var month_ = Number(month);
        }
        const dat = date.getDate();
        if (dat <= 9) {
            var dat_ = "0" + (Number(dat));
        } else {
            var dat_ = Number(dat);
        }
        const hour = date.getHours();
        if (hour <= 9) {
            var hour_ = "0" + (Number(hour));
        } else {
            var hour_ = Number(hour);
        }
        const min = date.getMinutes();
        if (min <= 9) {
            var min_ = "0" + (Number(min));
        } else {
            var min_ = Number(min);
        }
        return {
            date: dat_ + '-' + month_ + '-' + year,
            time: hour_ + ':' + min_,
            full: year + '-' + month_ + '-' + dat_ + ' ' + hour_ + ':' + min_ + ':' + '00'
        }
    }

    const setDefaultBySelect = (id) => {
        if (id !== "0") {
            const selectedText = defaultText.find(item => item.id == id);
            setDefaultTexts(selectedText.text);
            const find = defaultParam.find(item => item.id == id),
                dateTime = formatDate((new Date())),
                UH_r = JSON.parse(window.localStorage.getItem("usage_history")),
                UH = (UH_r !== null) ? UH_r : [];

            setDefault(id);
            setResult(find.result);
            UH.push({
                id: Math.floor(Math.random() * (999999 - 111111) + 111111),
                title: "Acid-base disorders calculator",
                date: dateTime.date,
                time: dateTime.time,
                result: find.result
            });
            window.localStorage.setItem("usage_history", JSON.stringify(UH));
        } else {
            setDefaultTexts(false);
            setDefault(false);
            setResult([]);
            input.map((i) => {
                if (i.type == "select") {
                    document.getElementById(i.uid).getElementsByTagName('option')[0].selected = true;
                } else {
                    document.getElementById(i.uid).value = " ";
                }
                setMeasurements(i.utype, false);
            });
        }
    };



    const convert_result = (result) => {
        const diagnosis_enum = {
            10001: "Acute pure respiratory acidosis",
            10002: "Chronic pure respiratory acidosis",
            10003: "Anion gap mixed respiratory acidosis",
            10004: "Non-anion gap mixed respiratory acidosis",
            10008: "Anion gap pure metabolic acidosis",
            10009: "Non-anion gap pure metabolic acidosis",
            100010: "Anion gap mixed metabolic acidosis",
            100011: "Non-anion gap mixed metabolic acidosis",
            10006: "Acute pure respiratory alkalosis",
            10007: "Chronic pure respiratory alkalosis",
            10005: "Mixed respiratory alkalosis",
            100012: "Pure metabolic alkalosis",
            100013: "Mixed metabolic alkalosis"
        },
            measurement_desc = {
                750001: {
                    title: "HCO<sub>3</sub><sup>-</sup>",
                    unit: "mmol/L",
                },
                750002: {
                    title: "pH",
                    unit: "",
                },
                750003: {
                    title: "SBE",
                    unit: "mmol/L",
                },
                750004: {
                    title: "PaCO<sub>2</sub>",
                    unit: "mmHg",
                },
                750005: {
                    title: "Na<sup>+</sup>",
                    unit: "mmol/L",
                },
                750006: {
                    title: "Cl<sup>-</sup>",
                    unit: "mmol/L",
                },
                750007: {
                    title: "Albumin",
                    unit: "g/L",
                },
                750008: {
                    title: "PaO<sub>2</sub>",
                    unit: "mmHg",
                },
                750009: {
                    title: "Lactate",
                    unit: "mmol/L",
                },
                750010: {
                    title: "K<sup>+</sup>",
                    unit: "mmol/L",
                },
                750011: {
                    title: "Urea",
                    unit: "mmol/L",
                },
                750012: {
                    title: "Glucose",
                    unit: "mmol/L",
                },
                750013: {
                    title: "Ca<sup>2+</sup>",
                    unit: "mmol/L",
                },
                750014: {
                    title: "Mg<sup>2+</sup>",
                    unit: "mmol/L",
                },
                750015: {
                    title: "PO<sub>4</sub><sup>3-</sup>",
                    unit: "mmHg",
                },
                750016: {
                    title: "Total protein",
                    unit: "g/L",
                },
                750017: {
                    title: "SBE expected ",
                    unit: "mmol/L",
                },
                750018: {
                    title: "tSBE",
                    unit: "mmol/L",
                },
                750019: {
                    title: "PaCO<sub>2</sub> expected",
                    unit: "mmHg",
                },
                750020: {
                    title: "PaCO<sub>2</sub> + PaO<sub>2</sub>",
                    unit: "mmHg",
                },
                750021: {
                    title: "AG",
                    unit: "mmol/L",
                },
                750022: {
                    title: "AG corrected",
                    unit: "mmol/L",
                },
                750023: {
                    title: "OSM",
                    unit: "mmol/L",
                },
                750024: {
                    title: "SIDa",
                    unit: "mmol/L",
                },
                750025: {
                    title: "SIDe",
                    unit: "mmol/L",
                },
                750026: {
                    title: "SIG",
                    unit: "mmol/L",
                },
                750027: {
                    title: "Atot",
                    unit: "mmol/L"
                }
            }
        let result_ = {
            diagnosis: "Unknown diagnosis",
            measurements: []
        },
            item;
        Object.keys(result.data.models[0].data).forEach(function (key) {
            item = result.data.models[0].data[key];
            if (item.id_result == 750000) {
                result_.diagnosis = (item.result in diagnosis_enum) ? diagnosis_enum[item.result] : "Unknown diagnosis";
            } else {
                if (item.id_result in measurement_desc) {
                    result_.measurements.push({
                        title: measurement_desc[item.id_result].title,
                        unit: measurement_desc[item.id_result].unit,
                        value: item.result
                    });
                }
            }
        });
        return result_;
    };

    const start_calculation = () => {
        setShowResult(true);
        const dateTime = formatDate((new Date())),
            birthday = new Date(measurements["Age"].value),
            UH_r = JSON.parse(window.localStorage.getItem("usage_history")),
            UH = (UH_r !== null) ? UH_r : [],
            data = [];
        Object.keys(measurements).forEach(function (measurement) {
            if (
                measurements[measurement].id_result_argument &&
                measurements[measurement].value !== false &&
                measurements[measurement].value !== ""
            ) {
                data.push({
                    "arguments_type": "measurements",
                    "id_block_argument": null,
                    "id_result_argument": measurements[measurement].id_result_argument,
                    "id_result": 100000,
                    "id_anatomy_addon": null,
                    "name_anatomy_addon": null,
                    "id_anatomy_localization": null,
                    "name_anatomy_localization": null,
                    "result": Number(measurements[measurement].value),
                    "reference_interval_min": 1,
                    "reference_interval_max": 1999,
                    "measure_time": dateTime.full,
                });
            }
        });
        const body = {
            "service_name": "biometer",
            "biometer_run_item_id": 11,
            "biometer_run_result_id": 781568844,
            "models": [
                {
                    "model": {
                        "service_name": "biometer",
                        "biometer_run_item_id": 35,
                        "biometer_run_result_id": 78156844,
                        "technical_model_name": "ACID_BASE_CALC",
                        "model_item_id": 5,
                        "biometer_model_item_id": 8979879,
                        "biometer_model_result_id": 789,
                        "birthday_day": birthday.getDate(),
                        "birthday_month": birthday.getMonth() + 1,
                        "birthday_year": birthday.getFullYear(),
                        "gender_id": Number(measurements["gender"].value),
                        "model_result_id": 1,
                        "patient_id": 4598005
                    },
                    "data": data
                }
            ]
        };
        axios.post(apiUrl, body)
            .then(result => {
                result = convert_result(result);
                setFindParam(false);
                setResult(result);
                const result_ = {
                    id: Math.floor(Math.random() * (999999 - 111111) + 111111),
                    title: "Acid-base disorders calculator",
                    date: dateTime.date,
                    time: dateTime.time,
                    result: result
                };
                UH.push(result_);
                window.localStorage.setItem("usage_history", JSON.stringify(UH));
            });
    };

    const closeResult = () => {
        setShowResult(false);
    };

    const getByType = (type, title, uid, utype, setValueByType, unit, min, max, step, isDefault = false) => {
        switch (type) {
            case "date":
                return <InputDate title={title}
                    uid={uid}
                    utype={utype}
                    setValueByType={setValueByType}
                    isDefault={isDefault}
                />;
            case "select":
                return <InputSelect title={title}
                    uid={uid}
                    unit={unit}
                    setValueByType={setValueByType}
                    utype={utype}
                    isDefault={isDefault}
                />;
            case "number":
                return <InputNumber title={title}
                    uid={uid}
                    unit={unit}
                    utype={utype}
                    step={step}
                    setValueByType={setValueByType}
                    min={min}
                    max={max}
                    isDefault={isDefault}
                />;
        }
    };

    return (
        <section className="section-left">
            {
                showResult &&
                <ResultAcidBase result={result} closeResult={closeResult} />
            }
            <div className="d-flex align-items-center pt-5 pb-3 mt-3 w-100">
                <h1 className="mr-auto text-white">Acid-base disorders calculator</h1>
            </div>

            <div className="switch_block">
                <div className="d-flex align-items-center ml-auto" style={{ width: "fit-content" }}>
                    <button onClick={() => setBlock(1)}
                        className={block === 1 ? "switch-btn active" : "switch-btn"}>description
                    </button>
                    <button onClick={() => setBlock(2)}
                        className={block === 2 ? "switch-btn active" : "switch-btn"}>context
                    </button>
                    <button onClick={() => setBlock(3)}
                        className={block === 3 ? "switch-btn active" : "switch-btn"}>reference
                    </button>
                </div>
                {
                    block === 1 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            Acid-base metabolic disorders are pathologic changes in carbon dioxide partial pressure (PaCO<sub>2</sub>) or serum bicarbonate (HCO<sub>3</sub><sup>-</sup>) levels that result in abnormal blood pH.
                            Primary acid-base disorders are defined as metabolic or respiratory based on the nature of pH change and clinical context.
                        </p>
                    </div>
                }
                {
                    block === 2 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            The model allows for calculation of several important diagnostic parameters, including standard base excess, PaCO<sub>2</sub> compensation,
                            anion gap, etc. Understanding the precise nature of the acid-base pathology is crucial for critical care medicine.
                        </p>
                    </div>
                }
                {
                    block === 3 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            Andronikof M. Troubles acidobasiques. EMC - Médecine d’urgence 2020;0(0):1-12
                        </p>
                    </div>
                }
            </div>
            <div className="parameter">
                <div className="select-wrapper">
                    <select id="default" className="form-control default-case"
                        onChange={(e) => setDefaultBySelect(e.target.value)}>
                        <option value="0">Select case</option>
                        <option value="1">Patient 1</option>
                        <option value="2">Patient 2</option>
                        <option value="3">Patient 3</option>
                    </select>
                    <div className="select" />
                </div>
                {
                    isDefault &&
                    <div className="about-default">
                        {defaultTexts}
                    </div>
                }
                <div className="text-center pt-3">
                    <div className="d-flex align-items-center text-left flex-wrap">
                        {
                            input.map((i) => getByType(
                                i.type,
                                (i.required) ? i.title + "&nbsp;*" : i.title,
                                i.uid,
                                i.utype,
                                setValueByType,
                                i.unit,
                                i.min,
                                i.max,
                                i.step,
                                isDefault ? defaultParam[isDefault - 1] : false
                            ))
                        }
                    </div>
                    {
                        isDefault ?
                            <button className="submit-model mt-4" onClick={() => setShowResult(true)}>Submit</button> :
                            findPram ?
                                <button className="submit-model mt-4" onClick={() => start_calculation()}>Submit</button> :
                                <button className="submit-model mt-4 disabled" disabled>Submit</button>
                    }
                </div>
            </div>
        </section>
    );
}

export default AcidBaseCalculator;