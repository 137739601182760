import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Route,
    Switch
} from "react-router-dom"
import Loading from "./components/System/Loading";
import PageHome from "./components/PageHome/PageHome";
import PageContact from "./components/PageContact/PageContact";
import CurrBiometer from "./components/CurrBiometer/CurrBiometer";
import Demo from "./components/CurrBiometer/Demo/Demo";
import CurrFlowViewer from "./components/CurrFlowViewer/CurrFlowViewer";
import CurrGeneViewer from "./components/CurrGeneViewer/CurrGeneViewer";
import CurrmBioViewer from "./components/CurrmBioViewer/CurrmBioViewer";
import News from "./components/News/News";
import PagePublication from "./components/PagePublication/PagePublication";
import PagePrivacyPolicy from './components/PagePrivacyPolicy/PagePrivacyPolicy';
import PageTermsOfUse from './components/PageTermsOfUse/PageTermsOfUse';
import fr from './lang/fr';
import en from './lang/en';
import Authentication from "./components/Authentication/Authentication";
import {loadLanguageText, isChangeLanguage, setKey} from './store/reducer'
import {useHistory} from "react-router";
import DemoPage from "./components/CurrBiometer/Demo/DemoPage/DemoPage";
import Company from "./components/DemoPages/Company";
import BySystem from "./components/DemoPages/BySystem";
import SimpleModels from "./components/DemoPages/Models/SimpleModel";
import History from "./components/History/History";
import {setting_auth} from './setting_auth';
import Registration from "./components/Authentication/Registration";
import AdministrativePanel from "./components/Authentication/AdministrativePanel";
import {AsyncLoadItem} from "./store/reducer";

function App() {
    const dispatch = useDispatch();
    const {history} = useHistory();
    const apiUrl = setting_auth.apiUrl;
    const lang = useSelector(state => state.load.lang);
    const key = useSelector(state => state.load.key);
    const loader = useSelector(state => state.load.isLoad);

    let keys = (window.localStorage.getItem('key') !== '')

    async function getSession (){
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        };
        fetch(apiUrl+'/get_session', requestOptions)
            //.then(()=>flagOnRender = true)
            .then(response => response.json())
            .then(data => {
                console.log('keyFromBack', data.key)
            });
    }

    useEffect(() => {
        let langs = window.sessionStorage.getItem("lang"),
            uri = window.location.pathname.split('/'),
            isDemoPage = (uri[1] == "demo");
        if (!langs || langs !== 'fr' || isDemoPage) {
            dispatch(loadLanguageText(en));
            dispatch(isChangeLanguage("en"));
        } else {
            dispatch(loadLanguageText(fr));
            dispatch(isChangeLanguage("fr"));
        }
    }, [lang]);


    if (loader) {
        return (
            <div>
                <Loading/>
            </div>
        )
    } else {
        return (
            <React.Fragment>
                <Switch>
                    <Route exact history={history} path='/' component={PageHome}/>
                    <Route exact history={history} path='/contact' component={PageContact}/>

                    <Route exact history={history} path='/products/biometer' component={CurrBiometer}/>
                    <Route exact history={history} path='/login' component={Authentication}/>
                    <Route exact history={history} path='/registration' component={Registration}/>
                    <Route exact history={history} path='/admin' component={AdministrativePanel}/>
                    <Route exact history={history} path='/demo' component={DemoPage}/>
                    <Route exact history={history} path='/demo/company' component={Company}/>
                    <Route exact history={history} path='/demo/system' component={BySystem}/>
                    <Route exact history={history} path='/demo/model' component={SimpleModels}/>
                    <Route exact history={history} path='/demo/history' component={History}/>
                    <Route exact history={history} path='/products/flowviewer' component={CurrFlowViewer}/>
                    <Route exact history={history} path='/products/mbioviewer' component={CurrmBioViewer}/>

                    <Route exact history={history} path='/privacy-policy' component={PagePrivacyPolicy}/>
                    <Route exact history={history} path='/terms-of-use' component={PageTermsOfUse}/>
                    <Route exact history={history} path='/project' component={PagePublication}/>
                    <Route exact history={history} path='/project/news1' component={News}/>
                    <Route exact history={history} path='/project/news2' component={News}/>
                    <Route exact history={history} path='/project/news3' component={News}/>
                    <Route exact history={history} path='/project/news4' component={News}/>
                </Switch>
            </React.Fragment>


        )
    }

}

export default App;
