import React, {useState} from "react";
import VictoryPieWrap from "./VictoryPieWrap";

function PieComponents({id, data}) {

    const datas = [
        {id: 1, title: "Cardiovascular system"},
        {id: 2, title: "Digestive system"},
        {id: 3, title: "Endocrine system"},
        {id: 4, title: "Urinary system"},
        {id: 5, title: "Respiratory system"}
    ];

    const title = () => {
        const data_ = datas.find(item => item.id === id);
        return data_.title;
    };

    const getData = () => {
        switch (id) {
            case 1:
                return data.cardiovascular;
                break;
            case 2:
                return data.digestive;
                break;
            case 3:
                return data.endocrine;
                break;
            case 4:
                return data.urinary;
                break;
            case 5:
                return data.respiratory;
                break;
        }
    };

    const getDanger = () => {
        const result = getData().danger * 100;
        return Math.round(result * 100) / 100;
    };

    const getConfidence = () => {
        const result = getData().confidence * 100;
        return Math.round(result * 100) / 100;
    };

    const getMeasurement = () => {

        const Biochemistry_requires_attention = [];
        const Functional_requires_attention = [];
        const Imaging_requires_attention = [];

        const keys_requires_attention = Object.keys(getData().measurements.requires_attention);

        for (let i = 0; i < keys_requires_attention.length; i++) {
            var datas = getData().measurements.requires_attention[keys_requires_attention[i]];

            if (datas.type === "Biochemistry, Cells") {
                var datas_ = {
                    reference_interval: datas.reference_interval,
                    type: datas.type,
                    unit: datas.unit,
                    value: datas.value,
                    title: keys_requires_attention[i],
                };
                Biochemistry_requires_attention.push(datas_)
            } else if (datas.type === "Functional Diagnostics") {
                var datas_ = {
                    reference_interval: datas.reference_interval,
                    type: datas.type,
                    unit: datas.unit,
                    value: datas.value,
                    title: keys_requires_attention[i],
                };
                Functional_requires_attention.push(datas_)
            } else if (datas.type === "Imaging") {
                var datas_ = {
                    reference_interval: datas.reference_interval,
                    type: datas.type,
                    unit: datas.unit,
                    value: datas.value,
                    title: keys_requires_attention[i],
                };
                Imaging_requires_attention.push(datas_)
            }
        }

        const Biochemistry_optimal_parameters = [];
        const Functional_optimal_parameters = [];
        const Imaging_optimal_parameters = [];

        const keys = Object.keys(getData().measurements.optimal_parameters);


        for (let i = 0; i < keys.length; i++) {
            var datas = getData().measurements.optimal_parameters[keys[i]];

            if (datas.type === "Biochemistry, Cells") {
                var datas_ = {
                    reference_interval: datas.reference_interval,
                    type: datas.type,
                    unit: datas.unit,
                    value: datas.value,
                    title: keys[i],
                };
                Biochemistry_optimal_parameters.push(datas_)
            } else if (datas.type === "Functional Diagnostics") {
                var datas_ = {
                    reference_interval: datas.reference_interval,
                    type: datas.type,
                    unit: datas.unit,
                    value: datas.value,
                    title: keys[i],
                };
                Functional_optimal_parameters.push(datas_)
            } else if (datas.type === "Imaging") {
                var datas_ = {
                    reference_interval: datas.reference_interval,
                    type: datas.type,
                    unit: datas.unit,
                    value: datas.value,
                    title: keys[i],
                };
                Imaging_optimal_parameters.push(datas_)
            }
        }

        return {
            requires_attention: {
                Biochemistry: Biochemistry_requires_attention,
                Functional: Functional_requires_attention,
                Imaging: Imaging_requires_attention,
            },
            optimal_parameters: {
                Biochemistry: Biochemistry_optimal_parameters,
                Functional: Functional_optimal_parameters,
                Imaging: Imaging_optimal_parameters,
            }
        };

    };

    const [hideR, setHideR] = useState(true);
    const [hideO, setHideO] = useState(false);

    const findZero = (x) => {
        const find = x.toString().includes('.') ? x.toString().split('.').pop().length : 0;
        if (find > 6) {
            return x.toFixed(2);
        } else {
            return x;
        }
    };


    return (
        <div className="inset-switcher">

            <div className="d-flex align-items-center pb-3">
                <p className="title-meta-result mb-0 mr-auto">{title()}</p>

                <div className="meta-result-quadro-wrap mr-4">
                    <div className="meta-result-quadro red">
                        <p className="mb-0 meta-result-quadro-r">{getDanger()}%</p>
                        <p className="mb-0 meta-result-quadro-text">Critical</p>
                    </div>
                    <VictoryPieWrap num={getDanger()} color={"red"}/>
                </div>

                <div className="meta-result-quadro-wrap">
                    <div className="meta-result-quadro blue">
                        <p className="mb-0 meta-result-quadro-r">{getConfidence()}%</p>
                        <p className="mb-0 meta-result-quadro-text">Complete</p>
                    </div>
                    <VictoryPieWrap num={getConfidence()} color={"blue"}/>
                </div>
            </div>

            <div className="overfow-this">
                <div className="mt-3">
                    <div className="d-flex align-items-center">
                        <p className="mb-0 mr-auto title-models">requires attention</p>
                        <p className={hideR ? "mb-0 mr-3 hide-models active" : "mb-0 mr-3 hide-models"}
                           onClick={() => setHideR(!hideR)}>{hideR ? "Hide" : "Show"}</p>
                    </div>
                    {
                        hideR &&
                        <div className="wrap-hide-requires">

                            {
                                getMeasurement().requires_attention.Biochemistry.find(item => item.type === "Biochemistry, Cells") &&
                                <React.Fragment>
                                    <p className="mb-0 titless p-1">Biochemistry, Cells</p>
                                    <div className="rowses p-1 d-flex align-items-center">
                                        <p className="mb-0 w-100 mr-auto">Marker</p>
                                        <p className="mb-0 w-25 mr-auto">val</p>
                                        <p className="mb-0 w-25 mr-auto">units</p>
                                        <p className="mb-0 w-35 mr-auto">reference_range</p>
                                    </div>
                                </React.Fragment>
                            }

                            {
                                getMeasurement().requires_attention.Biochemistry.map((d, index) =>
                                    <React.Fragment key={index}>
                                        <div className="rowsess p-1 d-flex align-items-center">
                                            <p className="mb-0 w-100 mr-auto"><b>{d.title}</b></p>
                                            <p className="mb-0 w-25 mr-auto">{findZero(d.value)}</p>
                                            <p className="mb-0 w-25 mr-auto">{d.unit}</p>
                                            <p className="mb-0 w-35 mr-auto">{d.reference_interval}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            {
                                getMeasurement().requires_attention.Functional.find(item => item.type === "Functional Diagnostics") &&
                                <React.Fragment>
                                    <p className="mb-0 titless p-1">Functional Diagnostics</p>
                                    <div className="rowses p-1 d-flex align-items-center">
                                        <p className="mb-0 w-100 mr-auto">Marker</p>
                                        <p className="mb-0 w-25 mr-auto">val</p>
                                        <p className="mb-0 w-25 mr-auto">units</p>
                                        <p className="mb-0 w-35 mr-auto">reference_range</p>
                                    </div>
                                </React.Fragment>
                            }

                            {
                                getMeasurement().requires_attention.Functional.map((d, index) =>
                                    <React.Fragment key={index}>
                                        <div className="rowsess p-1 d-flex align-items-center">
                                            <p className="mb-0 w-100 mr-auto"><b>{d.title}</b></p>
                                            <p className="mb-0 w-25 mr-auto">{findZero(d.value)}</p>
                                            <p className="mb-0 w-25 mr-auto">{d.unit}</p>
                                            <p className="mb-0 w-35 mr-auto">{d.reference_interval}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            {
                                getMeasurement().requires_attention.Imaging.find(item => item.type === "Imaging") &&
                                <React.Fragment>
                                    <p className="mb-0 titless p-1">Imaging</p>
                                    <div className="rowses p-1 d-flex align-items-center">
                                        <p className="mb-0 w-100 mr-auto">Marker</p>
                                        <p className="mb-0 w-25 mr-auto">val</p>
                                        <p className="mb-0 w-25 mr-auto">units</p>
                                        <p className="mb-0 w-35 mr-auto">reference_range</p>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                getMeasurement().requires_attention.Imaging.map((d, index) =>
                                    <React.Fragment key={index}>
                                        <div className="rowsess p-1 d-flex align-items-center">
                                            <p className="mb-0 w-100 mr-auto"><b>{d.title}</b></p>
                                            <p className="mb-0 w-25 mr-auto">{findZero(d.value)}</p>
                                            <p className="mb-0 w-25 mr-auto">{d.unit}</p>
                                            <p className="mb-0 w-35 mr-auto">{d.reference_interval}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    }
                </div>
                <div className="mt-3">
                    <div className="d-flex align-items-center">
                        <p className="mb-0 mr-auto title-models-green">optimal parameters</p>
                        <p className={hideO ? "mb-0 mr-3 hide-models-green active" : "mb-0 mr-3 hide-models-green"}
                           onClick={() => setHideO(!hideO)}>{hideO ? "Hide" : "Show"}</p>
                    </div>
                    {
                        hideO &&
                        <div className="wrap-hide-requires">
                            {
                                getMeasurement().optimal_parameters.Biochemistry.find(item => item.type === "Biochemistry, Cells") &&
                                <React.Fragment>
                                    <p className="mb-0 titless-green p-1">Biochemistry, Cells</p>
                                    <div className="rowses-green p-1 d-flex align-items-center">
                                        <p className="mb-0 w-100 mr-auto">Marker</p>
                                        <p className="mb-0 w-25 mr-auto">val</p>
                                        <p className="mb-0 w-25 mr-auto">units</p>
                                        <p className="mb-0 w-35 mr-auto">reference_range</p>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                getMeasurement().optimal_parameters.Biochemistry.map((d, index) =>
                                    <React.Fragment key={index}>
                                        <div className="rowsess p-1 d-flex align-items-center">
                                            <p className="mb-0 w-100 mr-auto"><b>{d.title}</b></p>
                                            <p className="mb-0 w-25 mr-auto">{findZero(d.value)}</p>
                                            <p className="mb-0 w-25 mr-auto">{d.unit}</p>
                                            <p className="mb-0 w-35 mr-auto">{d.reference_interval}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            {
                                getMeasurement().optimal_parameters.Functional.find(item => item.type === "Functional Diagnostics") &&
                                <React.Fragment>
                                    <p className="mb-0 titless-green p-1">Functional Diagnostics</p>
                                    <div className="rowses-green p-1 d-flex align-items-center">
                                        <p className="mb-0 w-100 mr-auto">Marker</p>
                                        <p className="mb-0 w-25 mr-auto">val</p>
                                        <p className="mb-0 w-25 mr-auto">units</p>
                                        <p className="mb-0 w-35 mr-auto">reference_range</p>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                getMeasurement().optimal_parameters.Functional.map((d, index) =>
                                    <React.Fragment key={index}>
                                        <div className="rowsess p-1 d-flex align-items-center">
                                            <p className="mb-0 w-100 mr-auto"><b>{d.title}</b></p>
                                            <p className="mb-0 w-25 mr-auto">{findZero(d.value)}</p>
                                            <p className="mb-0 w-25 mr-auto">{d.unit}</p>
                                            <p className="mb-0 w-35 mr-auto">{d.reference_interval}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            {
                                getMeasurement().optimal_parameters.Imaging.find(item => item.type === "Imaging") &&
                                <React.Fragment>
                                    <p className="mb-0 titless-green p-1">Imaging</p>
                                    <div className="rowses-green p-1 d-flex align-items-center">
                                        <p className="mb-0 w-100 mr-auto">Marker</p>
                                        <p className="mb-0 w-25 mr-auto">val</p>
                                        <p className="mb-0 w-25 mr-auto">units</p>
                                        <p className="mb-0 w-35 mr-auto">reference_range</p>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                getMeasurement().optimal_parameters.Imaging.map((d, index) =>
                                    <React.Fragment key={index}>
                                        <div className="rowsess p-1 d-flex align-items-center">
                                            <p className="mb-0 w-100 mr-auto"><b>{d.title}</b></p>
                                            <p className="mb-0 w-25 mr-auto">{findZero(d.value)}</p>
                                            <p className="mb-0 w-25 mr-auto">{d.unit}</p>
                                            <p className="mb-0 w-35 mr-auto">{d.reference_interval}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default PieComponents;