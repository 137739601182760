
    import React from "react";
    import {useSelector} from "react-redux";

function News4() {

    const data = useSelector(state => state.load.data);

    return (
        <div className="container">
            <div className="current_img">
                <img className="curr_img_news" src="../images/news/4.jpg" alt=""/>
            </div>
            <div className="current_new">
                <p className="begin_current_news">{data && data.News[0].news4[0]}</p>
                <p className="end_current_news">
                    {data && data.News[0].news4[1]}
                    <sup>1</sup>.
                    <br/>
                    <br/>
                    {data && data.News[0].news4[2]}
                    <br/>
                    <br/>
                    {data && data.News[0].news4[3]}
                    <br/>
                    <br/>
                    {data && data.News[0].news4[4]}
                    <sup>2</sup>
                    <br/>
                    <br/>
                    {data && data.News[0].news4[5]}
                    <br/>
                    <br/>
                    {data && data.News[0].news4[6]}
                    <br/>
                    {data && <div dangerouslySetInnerHTML={{__html: data.News[0].news4[7]}}></div>}
                </p>
            </div>
        </div>
    );

}

export default News4;
