import React from "react";
import {useSelector} from "react-redux";

import slide1 from './slider1.jpg';
import slide2 from './slider2.jpg';
import slide4 from './slider4.jpg';
import slide5 from './slider5.jpg';
import slide6 from './slider6.jpg';
import slide7 from './slider7.jpg';
import slide8 from './slider8.jpg';
import slide9 from './slider9.jpg';
import slide10 from './slider10.jpg';
import slide11 from './slider11.jpg';


function Slider() {

    const data = useSelector(state => state.load.data);

    return (
        <section className="wrap-slider wow animate__fadeIn" data-wow-duration="2s">
            <div className="container">
                <p className="begin_product pt-5 pb-4">{data && data.Slider[0].caption}</p>
                <div className="peppermint peppermint-inactive" id="peppermint">
                <figure>
                        <a className="slider_a" href="https://doi.org/10.1101/2022.10.08.511400"
                           target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-3">
                                        <img src={slide11} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_begin">{data && data.Slider[0].slide11[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                <li>
                                                    {data && data.Slider[0].slide11[1]}
                                                </li>
                                                <li>
                                                    {data && data.Slider[0].slide11[2]}
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>
                    <figure>
                        <a className="slider_a" href="https://www.nature.com/articles/s41591-021-01339-0"
                           target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-3">
                                        <img src={slide10} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_begin">{data && data.Slider[0].slide1[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                <li>
                                                    {data && data.Slider[0].slide1[1]}
                                                </li>
                                                <li>
                                                    {data && data.Slider[0].slide1[2]}
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>
                    <figure>
                        <a className="slider_a" href="https://academic.oup.com/eurheartj/article/41/24/2313/5735221"
                           target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-3">
                                        <img src={slide9} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_begin">{data && data.Slider[0].slide2[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                    <li>
                                                        {data && data.Slider[0].slide2[1]}
                                                    </li>
                                                    <li>
                                                        {data && data.Slider[0].slide2[2]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>
                    <figure>
                        <a className="slider_a" href="https://youtu.be/3-uBFx-LhWI" target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-3">
                                        <img src={slide8} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_begin">{data && data.Slider[0].slide3[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                    <li>
                                                        {data && data.Slider[0].slide3[1]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>
                    <figure>
                        <a className="slider_a" href="https://doi.org/10.1159/000508233" target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-2">
                                        <img src={slide7} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_begin">{data && data.Slider[0].slide4[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                    <li>
                                                        {data && data.Slider[0].slide4[1]}
                                                    </li>
                                                    <li>
                                                        {data && data.Slider[0].slide4[2]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>

                    <figure>
                        <a className="slider_a" href="https://doi.org/10.1016/j.talanta.2019.120270"
                           target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-2">
                                        <img src={slide6} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_begin">{data && data.Slider[0].slide5[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                    <li>
                                                        {data && data.Slider[0].slide5[1]}
                                                    </li>
                                                    <li>
                                                        {data && data.Slider[0].slide5[2]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>
                    <figure>
                        <a className="slider_a" href="https://www.youtube.com/watch?v=H6JzPCbyVSM" target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-3">
                                        <img src={slide5} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                    <p className="slider_begin">{data && data.Slider[0].slide6[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                    <li>
                                                        {data && data.Slider[0].slide6[1]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>
                    <figure>
                        <a className="slider_a"
                           href="https://programme.escardio.org/ESC2020/Abstracts/219106-addition-of-biomarker-high-sensitivity-troponin-i-hstropi-to-qrisk3-score-in-predicting-significant-coronary-artery-disease-cad?R=/"
                           target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-3">
                                        <img src={slide4} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_begin">{data && data.Slider[0].slide7[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                    <li>
                                                        {data && data.Slider[0].slide7[1]}
                                                    </li>
                                                    <li>
                                                        {data && data.Slider[0].slide7[2]}<br/>
                                                        {data && data.Slider[0].slide7[3]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>
                    <figure>
                        <a className="slider_a"
                           href="https://programme.escardio.org/ESC2020/Abstracts/217803-performance-of-coronary-calcium-score-and-epicardial-fat-volume-in-early-diagnostic-of-coronary-artery-disease?R="
                           target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-3">
                                        <img src={slide4} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_begin">{data && data.Slider[0].slide8[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                    <li>
                                                        {data && data.Slider[0].slide8[1]}
                                                    </li>
                                                    <li>
                                                        {data && data.Slider[0].slide8[2]}<br/>
                                                        {data && data.Slider[0].slide8[3]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>

                    <figure>
                        <a className="slider_a" href="https://doi.org/10.1186/s12859-020-3509-7" target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-2">
                                        <img src={slide1} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                    <p className="slider_begin">{data && data.Slider[0].slide9[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ul className="slider_second">
                                                    <li>
                                                        {data && data.Slider[0].slide9[1]}
                                                    </li>
                                                    <li>
                                                        {data && data.Slider[0].slide9[2]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>

                    <figure>
                        <a className="slider_a" href="https://doi.org/10.1089/cmb.2019.0492" target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-2">
                                        <img src={slide2} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                    <p className="slider_begin">{data && data.Slider[0].slide10[0]}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_second">
                                                    <ul className="slider_second">
                                                        <li>
                                                            {data && data.Slider[0].slide10[1]}
                                                        </li>
                                                        <li>
                                                            {data && data.Slider[0].slide10[2]}
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </figure>

                    {/*<figure>
                            <a className="slider_a" href="" target="_blank">
                            <div className="slide" data="1">
                                <div className="row">
                                    <div className="col-3">
                                        <img src={slide3} alt=""/>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_begin">
                                                    VPHi Keynote Webinar Series
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <p className="slider_second">
                                                    Computational Modeling Tools
                                                    for Cardiovascular Desease Research, Surgical Planning and
                                                    Diagnostics
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </figure>*/}
                </div>

            </div>
        </section>
    );
}

export default Slider;
