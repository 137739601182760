import React, { useEffect, useState } from "react";
import {setting} from "../../../CurrBiometer/Demo/setting";
import InputDate from "../Inputs/InputDate";
import InputNumber from "../Inputs/InputNumber";
import InputSelect from "../Inputs/InputSelect";
import defaultParam from './defaultParam';
import input from './input';
import ResultSimple from "../Inputs/ResultSimple";
import axios from "axios";

function Type2Diabet() {
    const apiUrl = setting.apiUrl;
    const [block, setBlock] = useState(1);

    const initialMeasurements = {},
        requiredMeasurements = [];
    input.forEach(i => {
        initialMeasurements[i.utype] = {
            id_result_argument: i.id_result_argument,
            value: false
        }
        if (i.required) {
            requiredMeasurements.push(i.utype);
        }
    });
    const [measurements, setMeasurements_] = useState(initialMeasurements);
    const setMeasurements = (param, value) => {
        setMeasurements_(prevState => ({
            ...prevState,
            [param]: {
                id_result_argument: prevState[param].id_result_argument,
                value: value
            }
        }));
    };

    const [result, setResult] = useState([]);
    const [findPram, setFindParam] = useState(true);
    const [isDefault, setDefault] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [defaultTexts, setDefaultTexts] = useState(false);

    useEffect(() => {
        findParam();
    }, [measurements]);

    const findParam = () => {
        let foundAll = true;
        requiredMeasurements.forEach(m => {
            if (!measurements[m].value) {
                foundAll = false;
            }
        });
        setFindParam(foundAll);
    };

    const setValueByType = (type, value) => {
        setMeasurements(type, value);
    };

    const defaultText = [
        {
            id: 1,
            text: "Male, 58. During general medical examination of the asymptomatic patient, Type 2 Diabetes Model is used by a primary care provider in addition to general procedures to inform the patient about age-associated diabetes risk. In cooperation with an endocrinologist, annual diabetes screening is initiated."
        },
        {
            id: 2,
            text: "Female, 71. The patient was diagnosed with type 2 diabetes some time ago and is currently on medication (metformin). As a part of treatment strategy, specific diet was prescribed by an endocrinologist. The aim of the appointment is to assess efficacy of chosen therapy mode by comparing current risk to the previous one (the previous result determined by BioMeter was 59%)."
        },
        {
            id: 3,
            text: "Female, 58. The patient displays no apparent clinical signs of diabetes, but has family history of type 2 diabetes. Having a diabetes risk factor, the patient is eligible for screening using BioMeter."
        }
    ];

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        if (month <= 9) {
            var month_ = "0" + (Number(month));
        } else {
            var month_ = Number(month);
        }
        const dat = date.getDate();
        if (dat <= 9) {
            var dat_ = "0" + (Number(dat));
        } else {
            var dat_ = Number(dat);
        }
        const hour = date.getHours();
        if (hour <= 9) {
            var hour_ = "0" + (Number(hour));
        } else {
            var hour_ = Number(hour);
        }
        const min = date.getMinutes();
        if (min <= 9) {
            var min_ = "0" + (Number(min));
        } else {
            var min_ = Number(min);
        }
        return {
            date: dat_ + '-' + month_ + '-' + year,
            time: hour_ + ':' + min_,
            full: year + '-' + month_ + '-' + dat_ + ' ' + hour_ + ':' + min_ + ':' + '00'
        }
    }

    const setDefaultBySelect = (id) => {
        if (id !== "0") {
            const finds = defaultText.find(item => item.id == id);
            setDefaultTexts(finds.text);
        } else {
            setDefaultTexts(false);
        }

        if (id !== "0") {
            const find = defaultParam.find(item => item.id == id),
                dateTime = formatDate((new Date())),
                UH_r = JSON.parse(window.localStorage.getItem("usage_history")),
                UH = (UH_r !== null) ? UH_r : [];

            setDefault(id);
            setResult(find.result);
            UH.push({
                id: Math.floor(Math.random() * (999999 - 111111) + 111111),
                title: "Type 2 Diabetes Model",
                date: dateTime.date,
                time: dateTime.time,
                result: find.result
            });
            window.localStorage.setItem("usage_history", JSON.stringify(UH));
        } else {
            setDefaultTexts(false);
            setDefault(false);
            setResult([]);
            input.map((i) => {
                if (i.type == "select") {
                    document.getElementById(i.uid).getElementsByTagName('option')[0].selected = true;
                } else {
                    document.getElementById(i.uid).value = " ";
                }
                setMeasurements(i.utype, false);
            });
        }
    };

    const start_calculation = () => {
        setShowResult(true);
        const dateTime = formatDate((new Date())),
            birthday = new Date(measurements["Age"].value),
            UH_r = JSON.parse(window.localStorage.getItem("usage_history")),
            UH = (UH_r !== null) ? UH_r : [],
            data = [];
        Object.keys(measurements).forEach(function (measurement) {
            if (
                measurements[measurement].id_result_argument &&
                measurements[measurement].value !== false &&
                measurements[measurement].value !== ""
            ) {
                data.push({
                    "arguments_type": "measurements",
                    "id_block_argument": null,
                    "id_result_argument": measurements[measurement].id_result_argument,
                    "id_result": 100000,
                    "id_anatomy_addon": null,
                    "name_anatomy_addon": null,
                    "id_anatomy_localization": null,
                    "name_anatomy_localization": null,
                    "result": Number(measurements[measurement].value),
                    "reference_interval_min": 1,
                    "reference_interval_max": 1999,
                    "measure_time": dateTime.full,
                });
            }
        });
        const body = {
            "service_name": "biometer",
            "biometer_run_item_id": 11,
            "biometer_run_result_id": 781568844,
            "models": [
                {
                    "model": {
                        "service_name": "biometer",
                        "biometer_run_item_id": 35,
                        "biometer_run_result_id": 78156844,
                        "technical_model_name": "T2DMDiagnosticModel",
                        "model_item_id": 5,
                        "biometer_model_item_id": 8979879,
                        "biometer_model_result_id": 789,
                        "birthday_day": birthday.getDate(),
                        "birthday_month": birthday.getMonth() + 1,
                        "birthday_year": birthday.getFullYear(),
                        "gender_id": Number(measurements["gender"].value)
                    },
                    "data": data
                }
            ]
        };
        axios.post(apiUrl, body)
            .then(result => {
                let result_;
                try {
                    result_ = result.data.models;
                    result_[0].data = [result_[0].data[0]];
                    result_[0].data[0].result = Math.round(Number(result_[0].data[0].result) * 10000) / 100;
                } catch (error) {
                    result_ = [
                        {
                            data: null
                        }
                    ];
                }
                setFindParam(false);
                setResult(result_);
                UH.push({
                    id: Math.floor(Math.random() * (999999 - 111111) + 111111),
                    title: "Type 2 Diabetes Model",
                    date: dateTime.date,
                    time: dateTime.time,
                    result: result_
                });
                window.localStorage.setItem("usage_history", JSON.stringify(UH));
            });
    };

    const closeResult = () => {
        setShowResult(false);
    };

    const getByType = (type, title, uid, utype, setValueByType, unit, min, max, step, isDefault = false) => {
        switch (type) {
            case "date":
                return <InputDate title={title}
                    uid={uid}
                    utype={utype}
                    setValueByType={setValueByType}
                    isDefault={isDefault}
                />;
            case "select":
                return <InputSelect title={title}
                    uid={uid}
                    unit={unit}
                    setValueByType={setValueByType}
                    utype={utype}
                    isDefault={isDefault}
                />;
            case "number":
                return <InputNumber title={title}
                    uid={uid}
                    unit={unit}
                    utype={utype}
                    step={step}
                    setValueByType={setValueByType}
                    min={min}
                    max={max}
                    isDefault={isDefault}
                />;
        }
    };

    const data = {
        diabet: [
            {
                title: "Low",
                text: "It is important to maintain current lifestyle and medication choices. Regular risk estimation is advised to prevent the onset of diabetes"
            },
            { title: "High", text: "It is strongly recommended to undergo comprehensive laboratory diabetes screening" },
        ]
    };

    return (
        <section className="section-left">
            {
                showResult &&
                <ResultSimple data={data} result={result} closeResult={closeResult} min={0} max={50} middle={4} />
            }
            <div className="d-flex align-items-center pt-5 pb-3 mt-3 w-100">
                <h1 className="mr-auto text-white">Type 2 Diabetes Model</h1>
            </div>

            <div className="switch_block">
                <div className="d-flex align-items-center ml-auto" style={{ width: "fit-content" }}>
                    <button onClick={() => setBlock(1)}
                        className={block === 1 ? "switch-btn active" : "switch-btn"}>description
                    </button>
                    <button onClick={() => setBlock(2)}
                        className={block === 2 ? "switch-btn active" : "switch-btn"}>context
                    </button>
                    <button onClick={() => setBlock(3)}
                        className={block === 3 ? "switch-btn active" : "switch-btn"}>reference
                    </button>
                </div>
                {
                    block === 1 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            The machine learning algorithm uses patient-generated data of electronic health records to
                            estimate probability of having type 2 diabetes. Despite well-established diagnostic
                            criteria, diabetes often remains undiagnosed, detected only when clinical manifestations of
                            the disease become apparent. This makes diabetic complications (retinopathy, nephropathy,
                            neuropathy, cardiovascular complications) significantly harder to prevent. Type 2 Diabetes
                            Model by BioME is capable of detecting the disease at early stages, thus allowing both the
                            physician and the patient to make timely interventions.
                        </p>
                    </div>
                }
                {
                    block === 2 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            Type 2 Diabetes Model is intended for use in populations of ambulatory patients (symptomatic
                            or non-symptomatic) without confirmed diabetes, and classifies patients as being at either
                            high risk or low risk. The model output represents a confidence index (displayed as %) with
                            a cut-off value of 4. Results equal or above this value correspond to high risk of having
                            diabetes. In this case, endocrinologist or primary care provider is advised to recommend the
                            patient to undergo full screening procedure.
                        </p>
                    </div>
                }
                {
                    block === 3 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            Glanz V., Dudenkov V., Velikorodny A. (2022). Development and Validation of Type 2 Diabetes Machine Learning Classification Model for Clinical Decision Support Framework. BioME report Jan 2022.
                        </p>
                    </div>
                }
            </div>
            <div className="parameter">
                <div className="select-wrapper">
                    <select id="default" className="form-control default-case"
                        onChange={(e) => setDefaultBySelect(e.target.value)}>
                        <option value="0">Select case</option>
                        <option value="1">Patient 1</option>
                        <option value="2">Patient 2</option>
                        <option value="3">Patient 3</option>
                    </select>
                    <div className="select" />
                </div>
                {
                    isDefault &&
                    <div className="about-default">
                        {defaultTexts}
                    </div>
                }
                <div className="text-center pt-3">
                    {
                        isDefault ?
                            <button className="submit-model mt-4" onClick={() => setShowResult(true)}>Submit</button> :
                            findPram ?
                                <button className="submit-model mt-4" onClick={() => start_calculation()}>Submit</button> :
                                <button className="submit-model mt-4 disabled" disabled>Submit</button>
                    }
                    <div className="d-flex align-items-center text-left flex-wrap">
                        {
                            input.map((i) => getByType(
                                i.type,
                                (i.required) ? i.title + " *" : i.title,
                                i.uid,
                                i.utype,
                                setValueByType,
                                i.unit,
                                i.min,
                                i.max,
                                i.step,
                                isDefault ? defaultParam[isDefault - 1] : false
                            ))
                        }
                    </div>
                    {
                        isDefault ?
                            <button className="submit-model mt-4" onClick={() => setShowResult(true)}>Submit</button> :
                            findPram ?
                                <button className="submit-model mt-4" onClick={() => start_calculation()}>Submit</button> :
                                <button className="submit-model mt-4 disabled" disabled>Submit</button>
                    }
                </div>
            </div>
        </section>
    );
}

export default Type2Diabet;