import React, {useEffect, useState} from "react";
import title from "../../images/demo/pages/title.png";
import MobileMenu from "../MobileMenu/MobileMenu";
import Menu from "../Menu/Menu";
import {useSelector} from "react-redux";
import AsideMenu from "../CurrBiometer/Demo/DemoPage/AsideMenu";
import Endocrine from "./BySystem/Endocrine";
import Cardiovascular from "./BySystem/Cardiovascular";
import Digestive from "./BySystem/Digestive";
import Reproductive from "./BySystem/Reproductive";
import Urinary from "./BySystem/Urinary";
import Respiratory from "./BySystem/Respiratory";
import Footer from "../Footer/Footer";
import Authentication from "../Authentication/Authentication";

function BySystem(props) {
    const {history} = props.history;
    const data = useSelector(state => state.load.data);

    const [page, setPage] = useState(false);

    useEffect(() => {
        setPage(getQuery());
    }, []);

    const getQuery = () => {
        const pathname = window.location.pathname;
        const c = window.location.search;
        const query = c.split("=");

        return {
            query: query[1],
            pathname: pathname
        };
    };

    const keys = window.localStorage.getItem('key');
    return (
        keys ?
            <React.Fragment>
                <title>{data && data.PageDemoSingle[0].pageTitle}</title>
                <MobileMenu history={history}/>
                <Menu history={history}/>
                <div className="container pt-5 pb-5">
                    <div className="d-flex pt-5">
                        <AsideMenu backTo={"/demo"} query={page.query} pathname={page.pathname}/>
                        {page.query === "endocrine" && <Endocrine/>}
                        {page.query === "cardiovascular" && <Cardiovascular/>}
                        {page.query === "digestive" && <Digestive/>}
                        {page.query === "reproductive" && <Reproductive/>}
                        {page.query === "urinary" && <Urinary/>}
                        {page.query === "respiratory" && <Respiratory/>}
                    </div>
                </div>
                <Footer/>
            </React.Fragment> :
            <Authentication history={history}/>
    );
}

export default BySystem;