import React from "react";
import { useSelector } from "react-redux";
import logofooter from '../../images/logofooter.png';
import phone from '../../images/phone.svg';
import mail from '../../images/mail.svg';
import youtube from '../../images/youtube.png';
import linkedin from '../../images/linkedin.png';

function Footer(props) {

    let con;

    const { page } = props;

    if (page) {
        con = 'contact_footer';
    }

    const lang = useSelector(state => state.load.lang);
    const data = useSelector(state => state.load.data);

    return (
        <footer id="about" className={"pt-5 pb-4 " + con}>
            <div className="container">
                <div className="row">
                    <div className="col">

                        <div className="row pb-2">
                            <div className="col">
                                <img src={logofooter} alt="" />
                            </div>
                        </div>

                        <div className="row pb-2">
                            <div className="col">
                                <p className="text-justify">{data && data.Footer[0].text}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p>{data && data.Footer[0].rights}</p>
                            </div>
                        </div>

                    </div>
                    <div className="col">
                        <div className={lang === 'fr' ? "row fr mt-3" : "row mt-3"}>
                            <div className="col d-flex footerm justify-content-center justify-content-lg-end">
                                <a className="ml-lg-4 mr-lg-0 ml-auto mr-auto text-white footer_menu noclick products"
                                    href="/products/flowviewer">
                                    {data && data.Menu[0].items[0]}
                                </a>
                                <a className="ml-lg-4 mr-lg-0 ml-auto mr-auto text-white footer_menu noclick products"
                                    href="/products/biometer">
                                    {data && data.Menu[0].items[1]}
                                </a>
                                {/* 
                                <a className="mr-auto ml-auto text-white footer_menu noclick project"
                                    href="/project">
                                    {data && data.Menu[0].items[1]}
                                </a>
                                <a className="mr-auto ml-auto text-white footer_menu "
                                    href="/#about">
                                    {data && data.Menu[0].items[2]}
                                </a>
                                 */}
                                <a className="ml-lg-4 mr-lg-0 ml-auto mr-auto text-white footer_menu "
                                    href="/contact">
                                    {data && data.Menu[0].items[3]}
                                </a>

                                <a className="ml-lg-4 mr-lg-0 ml-auto mr-auto text-white footer_menu "
                                    href="/demo">
                                    {data && data.Menu[0].items[6]}
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-lg-end justify-content-center footerm footer-contact">
                                <a href="tel:+330189168583" className="footer_menu text-white mr-4">
                                    <img className="pr-2" src={phone} alt="" />
                                    +33(0)1 89 16 85 83
                                </a>
                                <a href="mailto:info@biome-science.com" className="footer_menu text-white"><img
                                    className="pr-2" src={mail} alt="" />
                                    info@biome-science.com
                                </a>
                            </div>
                            <div className="col-12 d-flex justify-content-lg-end justify-content-center mt-4 pb-3 footerm">
                                <a className="footer_menu text-white mr-4"
                                    href="/privacy-policy">
                                    {data && data.Menu[0].items[4]}
                                </a>
                                <a className="footer_menu text-white"
                                    href="/terms-of-use">
                                    {data && data.Menu[0].items[5]}
                                </a>
                            </div>
                        </div>
                        {/*                             
                            <div className="col">
                                <a href="mailto:info@biome-science.net" className="footer_menu text-white"><img
                                    className="pr-2" src={mail} alt=""/>{data && data.Footer[0].email}</a>
                            </div>
                            <div className="wrap_fot">
                                <div className="col-1 mr-1 ftr">
                                    <a href="https://www.youtube.com/channel/UCKZJeX54WTVnZE0MntDoo1A"><img
                                        className="img-footer" src={youtube} alt=""/></a>
                                </div>
                                <div className="col-1 ftr">
                                    <a href="/"><img className="img-footer" src={linkedin} alt=""/></a>
                                </div>
                            </div>
                        */}
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
