import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Authentication from "./Registration";
import {setting_auth} from "../../setting_auth";

function AdministrativePanel(props) {
    const apiUrl = setting_auth.apiUrl;
    const {history} = props.history;
    const data = useSelector(state => state.load.data);

    const [users, setUsers] =  useState([]);
    const [viewedUsers, setViewedUsers] = useState([]);
    const [activatedUsers, setActivatedUsers] = useState([]);
    const [notActivatedUsers, setNotActivatedUsers] = useState([]);
    const [notViewedUsers, setNotViewedUsers] = useState([]);
    const [errorViewedUsers, setErrorViewedUsers] = useState([]);

    const containerStyle = {
        background: '#FFFFFF'
    }

    async function getUsers (){
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        };

        fetch(apiUrl+'get_users', requestOptions)
            //.then(()=>flagOnRender = true)
            .then(response => response.json())
            .then(data => {
                setUsers(data[0]);
                filterUsers(data[0]);
            });
    }

    async function register (email, access){
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                {
                    email: email,
                    access: access
                })
        };
        fetch(apiUrl+'registration', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log('status', data.pass);
            });
    }

    function filterUsers(users){
        let viewedUsers = [];
        let notViewedUsers = [];
        let errorViewedUsers = [];

        let activatedUsers = [];
        let notActivatedUsers = [];

        users.map((user, index)=>{
            if (user['viewed'] === '1') {
                viewedUsers.push(user)
            } else if (user['viewed'] === '0') {
                notViewedUsers.push(user)
            } else {
                errorViewedUsers.push(user)
            }
        })

        viewedUsers.map((user, index)=>{
            if (user['status'] === '1'){
                activatedUsers.push(user)
            } else if (user['status'] === '0'){
                notActivatedUsers.push(user)
            }
        })
        setViewedUsers(viewedUsers);
        setNotViewedUsers(notViewedUsers);
        setActivatedUsers(activatedUsers);
        setNotActivatedUsers(notActivatedUsers);

        setErrorViewedUsers(errorViewedUsers);
    }

    function viewUserAndGiveAccess(email, access){
        let user = users.find((user, index)=>{
            return (user['email'] === email )
        })
        user['viewed'] = '1';
        if (access){
            user['status'] = '1';
        } else {
            user['status'] = '0';
        }
        filterUsers(users);
    }

    async function pickupAccess(email){
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                {
                    email: email,
                })
        };
        fetch(apiUrl+'pick_up_access', requestOptions)
        viewUserAndGiveAccess(email, false);
    }

    async function giveAccess(email){
        await register(email, true);
        //сделать пользователя просмотренным
        viewUserAndGiveAccess(email, true);
    }

    async function denyAccess(email){
        await register(email, false);
        //сделать пользователя просмотренным
        viewUserAndGiveAccess(email, false);
    }

    useEffect( () => {
        getUsers();
    }, [])

    return (
        <React.Fragment>
            <div style={containerStyle}>
                {   Boolean(notViewedUsers.length !== 0) &&
                    <div>
                        <div>
                            <h2>Новые пользователи</h2>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <td>name</td>
                                <td>surname</td>
                                <td>phone</td>
                                <td>email</td>
                                <td>about</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                notViewedUsers.map((user, index)=>(
                                    <tr key={index}>
                                        <td>{user['name']}</td>
                                        <td>{user['surname']}</td>
                                        <td>{user['phone']}</td>
                                        <td>{user['email']}</td>
                                        <td>{user['about']}</td>
                                        <td>{Boolean(user['status'] === '0') && <button onClick={()=>giveAccess(user['email'])}> Дать доступ</button>}</td>
                                        <td>{Boolean(user['status'] === '0') && <button onClick={()=>denyAccess(user['email'])}> Отказать в доступе</button>}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                }

                <br/>
                {   Boolean(viewedUsers.length !== 0) &&
                    <div>
                        <div>
                            <h2>Просмотренные пользователи</h2>
                        </div>
                        <div>
                            {
                                Boolean(notActivatedUsers.length !== 0) &&
                                <div>
                                    <div><h3>Неактивированные пользователи</h3></div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <td>name</td>
                                            <td>surname</td>
                                            <td>phone</td>
                                            <td>email</td>
                                            <td>about</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            notActivatedUsers.map((user, index) => (
                                                <tr key={index}>
                                                    <td>{user['name']}</td>
                                                    <td>{user['surname']}</td>
                                                    <td>{user['phone']}</td>
                                                    <td>{user['email']}</td>
                                                    <td>{user['about']}</td>
                                                    <td>{Boolean(user['status'] === '0') && <button onClick={()=>giveAccess(user['email'])}> Дать доступ</button>}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {
                                Boolean(activatedUsers.length !== 0) &&
                                <div>
                                    <div><h3>Активированные пользователи</h3></div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <td>name</td>
                                            <td>surname</td>
                                            <td>phone</td>
                                            <td>email</td>
                                            <td>about</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            activatedUsers.map((user, index)=>(
                                                <tr key={index}>
                                                    <td>{user['name']}</td>
                                                    <td>{user['surname']}</td>
                                                    <td>{user['phone']}</td>
                                                    <td>{user['email']}</td>
                                                    <td>{user['about']}</td>
                                                    <td>{Boolean(user['status'] === '1') && <button onClick={()=>pickupAccess(user['email'])}>Запретить доступ</button>}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )

}

export default AdministrativePanel;