import React from "react";
import logo from "../../../images/demo/respiratory-shadow.svg";

function Respiratory() {
    return (
        <section className="section-left">
            <div className="d-flex align-items-center pt-5 mt-3 w-100">
                <img className="img-by-system" src={logo} alt=""/>
                <div className="d-grid">
                    <h1 className="title-by-system mb-0">Respiratory</h1>
                    <h1 className="title-by-system">system</h1>
                </div>
            </div>
            <p className="mb-0 pt-3 pb-2 text-white">
                Respiratory conditions are pathologies that either affect respiratory tract (pneumonia, asthma, chronic obstructive pulmonary disease, 
                tuberculosis, bronchitis, COVID-19), or in any other way impair gas exchange (respiratory acidosis, respiratory alkalosis). 
                The most common biomarkers of the respiratory diseases are:
            </p>
            <div className="d-flex align-items-center">
                <ul>
                    <li className="text-white">C-reactive protein</li>
                    <li className="text-white">Blood protein fractions (total protein, albumin)</li>
                    <li className="text-white">Arterial blood gas</li>
                </ul>
                <ul>
                    <li className="text-white">D-dimer</li>
                    <li className="text-white">International normalized ratio</li>
                    <li className="text-white">Procalcitonin</li>
                </ul>
            </div>
            <hr style={{background: "#6B7BB5"}}/>
            <h1 className="model-name pt-2 pb-2">MODELS</h1>
            <div className="d-flex align-items-center">
                <button onClick={() => document.location.href = "/demo/model/?id=acid-base-calculator"}
                        className="model-btn-system d-flex align-items-center">
                    <p className="mb-0 ml-0 mr-4">Acid-base disorders calculator</p>
                </button>
            </div>
        </section>
    );
}

export default Respiratory;