import React, {useState} from "react";
import {useSelector} from "react-redux";

import Menu from "../Menu/Menu";
import MobileMenu from "../MobileMenu/MobileMenu";
import Home from "../Home/Home";
import ParentBiometer from "../ParentBiometer/ParentBiometer";
import ParentFlowViewer from "../ParentFlowViewer/ParentFlowViewer";
import ParentGeneViewer from "../ParentGeneViewer/ParentGeneViewer";
import ParentMBioViewer from "../ParentMBioViewer/ParentMBioViewer";
import Project from "../Project/Project";
import Slider from "../Slider/Slider";
import Partner from "../Partner/Partner";
import Footer from "../Footer/Footer";
import TopArrow from "../TopArrow/TopArrow";
import '../../demo-home.css';
import digestive from '../../images/demo/digestive-shadow.svg';
import cardio from '../../images/demo/cardio-shadow.svg';
import meta from '../../images/demo/meta-shadow.svg';
import reproductive from '../../images/demo/reproductive-shadow.svg';
import urinary from '../../images/demo/urinari-shadow.svg';
import endocrine from '../../images/demo/endocrine-shadow.svg';
import respiratory from '../../images/demo/respiratory-shadow.svg';
import humans from '../../images/demo/human/humans.jpg';
import humanCardio from '../../images/demo/human/human-cardio.svg';
import humanDigestive from '../../images/demo/human/human-digestive.svg';
import humanEndocrine from '../../images/demo/human/human-endocrine.svg';
import humanRespiratory from '../../images/demo/human/human-respiratory.svg';
import humanUrinary from '../../images/demo/human/human-urinary.svg';
import humanReproductive from '../../images/demo/human/human-reproductive.svg';
import humanMeta from '../../images/demo/human/human-meta.svg';

function PageHome(props) {

    const {history} = props.history;
    const data = useSelector(state => state.load.data);

    const [humanHover, setHumanHover] = useState(0);

    const hoverHuman = (id) => {
        const human = [
            humans,
            humanCardio,
            humanDigestive,
            humanEndocrine,
            humanRespiratory,
            humanUrinary,
            humanReproductive,
            humanMeta
        ];

        return human[id];
    };

    return (
        <React.Fragment>
            <title>{data && data.PageHome[0].pageTitle}</title>
            <MobileMenu history={history}/>
            <Menu history={history}/>
            <Home/>
            
            <div id="products" className="container">
                <p className="begin_product">{data && data.PageHome[0].caption}</p>
            </div>
            <div className="container">
                <div className="wrap-product">
                    <ParentFlowViewer/>
                    <div className="pb-5"/>
                    <div className="pb-5"/>
                    <ParentBiometer/>
                    <div className="pb-5"/>
                    <div className="pb-5"/>
                </div>
            </div>

            <div className="demo-container pt-5 pb-5">
                <div className="container">
                    <div className="demo">
                        <div className="d-flex align-items-center">
                            <div className="main-systems">
                                <div className="d-flex flex-wrap align-items-center">
                                    <div className="d-grid wrap-first-home mb-5">
                                        <h1 className="demo-title">DEMO</h1>
                                        <p className="mb-0 demo-text-blue">
                                            BioMeter transforms Electronic Health Records into a valuable asset for health risks assessment, analyzing biomarker data patterns to detect various clinical outcomes corresponding to several anatomical systems.
                                        </p>
                                    </div>
                                    <a href="/demo" className="item-demo-home mr-auto mb-5">
                                        <div className="item-demo-home-inset"
                                             onMouseOut={() => setHumanHover(0)}
                                             onMouseMove={() => setHumanHover(2)}>
                                            <img className="img-item-demo-home" src={digestive} alt=""/>
                                            <p className="mb-0 item-demo-home-text">Digestive system</p>
                                        </div>
                                    </a>
                                    <a href="/demo/system/?id=cardiovascular" className="item-demo-home mr-auto mb-5">
                                        <div className="item-demo-home-inset"
                                            onMouseOut={() => setHumanHover(0)}
                                             onMouseMove={() => setHumanHover(1)}>
                                            <img className="img-item-demo-home" src={cardio} alt=""/>
                                            <p className="mb-0 item-demo-home-text">Cardiovascular <br/> system</p>
                                        </div>
                                    </a>
                                    <a href="/demo" className="item-demo-home mr-auto mb-5">
                                        <div className="item-demo-home-inset"
                                            onMouseOut={() => setHumanHover(0)}
                                             onMouseMove={() => setHumanHover(7)}>
                                            <img className="img-item-demo-home" src={meta} alt=""/>
                                            <p className="mb-0 item-demo-home-text">MetaModel</p>
                                        </div>
                                    </a>
                                    <a href="/demo" className="item-demo-home mr-auto mb-5">
                                        <div className="item-demo-home-inset"
                                            onMouseOut={() => setHumanHover(0)}
                                             onMouseMove={() => setHumanHover(6)}>
                                            <img className="img-item-demo-home" src={reproductive} alt=""/>
                                            <p className="mb-0 item-demo-home-text">Reproductive system <br/> Prenatal
                                                risks
                                            </p>
                                        </div>
                                    </a>
                                    <a href="/demo" className="item-demo-home mr-auto mb-5">
                                        <div className="item-demo-home-inset"
                                             onMouseOut={() => setHumanHover(0)}
                                             onMouseMove={() => setHumanHover(5)}>
                                            <img className="img-item-demo-home" src={urinary} alt=""/>
                                            <p className="mb-0 item-demo-home-text">Urinary system</p>
                                        </div>
                                    </a>
                                    <a href="/demo/system/?id=endocrine" className="item-demo-home mr-auto mb-5">
                                        <div className="item-demo-home-inset"
                                             onMouseOut={() => setHumanHover(0)}
                                             onMouseMove={() => setHumanHover(3)}>
                                            <img className="img-item-demo-home" src={endocrine} alt=""/>
                                            <p className="mb-0 item-demo-home-text">Endocrine system</p>
                                        </div>
                                    </a>
                                    <a href="/demo" className="item-demo-home mr-auto mb-5">
                                        <div className="item-demo-home-inset"
                                             onMouseOut={() => setHumanHover(0)}
                                             onMouseMove={() => setHumanHover(4)}>
                                            <img className="img-item-demo-home" src={respiratory} alt=""/>
                                            <p className="mb-0 item-demo-home-text">Respiratory system</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="human d-none d-xl-block">
                                <img className='w-100' src={hoverHuman(humanHover)} alt=""/>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {/* 
            <Project/>
            */}
            <Slider/>
            <Partner/>
            <Footer/>
            <TopArrow/>
        </React.Fragment>
    )
}

export default PageHome;
