import React from "react";
import video from './video/newvideocut.mkv';
import violet from './violet.png';
import arrow from './arrow_down.png'
import {useSelector} from "react-redux";


function Home() {

    const data = useSelector(state => state.load.data);

    return(
        <main>
            <div className="backvideo">
                <video id="backvid" className="invideo" src={video} autoPlay muted loop></video>
            </div>

            <section
                className="wh1000 wh100m second_view">
                <div className="container section_first">
                    <div className="wrap_main align-self-center">
                        <img className="violet_text" src={violet} alt=""/>
                        <p className="white_text_small w-65">{data && data.Home[0].text}</p>
                    </div>
                    <div className="bottom_btn">
                        <a href="products/flowviewer">
                            <button className="btn-violet products">{data && data.Home[0].textbtn}</button>
                        </a>
                        <div className="arrow_bottom">
                            <img src={arrow} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Home;
