
    import React from "react";
    import {useSelector} from "react-redux";

function ParentBiometer() {

    const data = useSelector(state => state.load.data);

    return (
        <div id="product1" className="product_card wow animated fadeIn" data-wow-duration="2s">
            <div className="current_card">
                <div className="wrap_text_card order-1">
                    <div className="text_card">
                        <div className="begin_products wow animated animate__slideInRight" data-wow-duration="2s">{data && data.ParentBiometer[0].caption}
                        </div>
                        <div className="back_gradient wow animated animate__slideInRight" data-wow-duration="2s"/>
                    </div>
                    <div>
                        <div className="begin_text_product">
                            {data && data.ParentBiometer[0].text[0]}</div>
                        <div className="text_product">
                            {data && data.ParentBiometer[0].text[1]}
                        </div>
                    </div>
                </div>
                <div id="product_img1" className="wrap_notext_card">
                </div>
            </div>
            <div className="button_product">
                <a href="../products/biometer">
                <button className="product_btn">{data && data.ParentBiometer[0].textbtn}</button>
                </a>
            </div>
        </div>
    );
}

export default ParentBiometer;
