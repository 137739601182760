
    import React from "react";
    import MobileMenu from "../MobileMenu/MobileMenu";
    import Menu from "../Menu/Menu";
    import Footer from "../Footer/Footer";
    import TopArrow from "../TopArrow/TopArrow";
    import News1 from "../News/News1";
    import News2 from "../News/News2";
    import News3 from "../News/News3";
    import News4 from "../News/News4";
    import {useSelector} from "react-redux";

    function PagePublication(props) {

        const { history } = props.history;
        const data = useSelector(state => state.load.data);

        return (
            <div>
                <title>{data && data.PagePublication[0].pageTitle}</title>
                <MobileMenu history={history}/>
                <Menu history={history}/>
                <div id="products" className="container pt-5 pb-5">
                    <p className="begin_product pt-5">{data && data.PagePublication[0].caption}</p>
                </div>
                <News1/>
                <div className="pb-5"/>
                <News2/>
                <div className="pb-5"/>
                <News3/>
                <div className="pb-5"/>
                <News4/>
                <div className="pb-5"/>
                <Footer/>
                <TopArrow/>
            </div>
        );
    }

export default PagePublication;
