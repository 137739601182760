import React from "react";
import usage from "../../../../../images/demo/icon/usage.svg";

function Respiratory({getActive}) {
    const model = [
        {id: 1, href: "/demo/model/?id=acid-base-calculator", title: "Acid-base disorders calculator"},
    ];

    return (
        <aside className="shadow">
            <div className="d-grid">
                <div className="title-aside">
                    <p className="title-aside-text mb-0">Respiratory system</p>
                </div>
                {
                    model.map((m) =>
                        <a href={m.href} key={m.id}>
                            <div className={getActive(m.href) ? "item-aside on-hover-aside active" : "item-aside on-hover-aside"}>
                                <p className={getActive(m.href) ? "item-aside-text mb-0 active" : "item-aside-text mb-0"}>{m.title}</p>
                            </div>
                        </a>
                    )
                }
                <a href="/demo/history" className="mt-5 d-block">
                    <div className="item-aside-usage on-hover-aside active">
                        <p className="item-aside-text-usage mb-0 active">
                            <img className="mr-2" src={usage} alt=""/>
                            Usage history</p>
                    </div>
                </a>
            </div>
        </aside>
    );
}

export default Respiratory;