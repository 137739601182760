import React from "react";
import MobileMenu from "../../../MobileMenu/MobileMenu";
import Menu from "../../../Menu/Menu";
import {useSelector} from "react-redux";
import AsideMenu from "./AsideMenu";
import title from '../../../../images/demo/pages/title.png';
import Footer from "../../../Footer/Footer";
import Authentication from "../../../Authentication/Authentication";

function DemoPage(props) {
    const history = props.history;
    const data = useSelector(state => state.load.data);

    return (
            <React.Fragment>
                <title>{data && data.PageDemo[0].pageTitle}</title>
                <MobileMenu history={history}/>
                <Menu history={history}/>
                <div className="container pt-5">
                    <div className="d-flex pt-5">
                        <AsideMenu backTo={"/"}/>
                        <section className="pt-3 section-left">
                            <img src={title} alt=""/>
                            <div className="wrap-demo-single-text plm">
                                <p className="demo-single-text">
                                    Electronic Health Records become a valuable asset in health risks assessment
                                    with the
                                    help of BioMeter. This product is essentially, a machine-learning-powered
                                    clinical
                                    decision support system, which uses patient-generated medical data to
                                    investigate
                                    patterns in biomarkers levels alterations and to identify specific ones that
                                    correlate
                                    to certain conditions (diseases, clinical outcomes). These conditions can be
                                    attributed
                                    to one or several anatomical systems, so it is possible to measure the rate of
                                    given
                                    anatomical system deterioration by the number and nature of identified
                                    conditions.
                                </p>
                                <p className="demo-single-text">BioMeter demo possesses several characteristics that
                                    distinguish it from the final product:</p>
                                <ul>
                                    <li className="demo-single-text">
                                        The ability of BioMeter to quickly processes massive
                                        amount of data without user involvement is emulated with pre-defined use
                                        cases. In
                                        practice, optimal performance is achieved by integration of BioMeter with a
                                        Medical
                                        Information System.
                                    </li>
                                    <li className="demo-single-text">
                                        The array of features available to Demo users is designed in a way that
                                        allows
                                        utilizing “must-have” medical models, familiar to any physician, alongside
                                        with
                                        certain innovative BioMeter-specific capabilities, such as Metamodel.
                                    </li>
                                    <li className="demo-single-text">
                                        Currently, only three anatomical systems (cardiovascular, endocrine,
                                        reproductive)
                                        are available to users for assessing system-specific health risks.
                                    </li>
                                    <li className="demo-single-text">
                                        Health status monitoring, forecasts and prevention analysis are not included
                                        in the
                                        demo.
                                    </li>
                                </ul>


                            </div>
                            {/*
                        <div className="d-flex align-items-center plm">

                            <div className="item-bottom-demo first mr-auto">
                                <h1 className="item-bottom-demo-title">First</h1>
                                <p className="mb-0 item-bottom-demo-text">
                                    the array of features available to the user is designed in a way that allows
                                    utilizing "must-have" features, familiar to any physician, alongside with innovative
                                    machine-learning-based capabilities, such as Metamodel.
                                </p>
                            </div>

                            <div className="item-bottom-demo second">
                                <h1 className="item-bottom-demo-title">Second</h1>
                                <p className="mb-0 item-bottom-demo-text">
                                    unlike the full version, the demo does not provide tools to make future predictions
                                    based on dynamic changes in medical data. Finally, the focus here on what the
                                    product can do rather than on how it works, so the user interface and navigation can
                                    (and most likely, will) change in the final version.
                                </p>
                            </div>
                        </div>
                         */}
                        </section>
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
    );
}

export default DemoPage;