import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom"
import {createBrowserHistory} from 'history'
import {Provider} from "react-redux";
import {store} from './store/store'


import './style.css';

import App from './App';
import initializeAnalytics from './analytics';

const history = createBrowserHistory();
initializeAnalytics();

ReactDOM.render((
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    ), document.getElementById('root')
);
