import React from "react";
import web from "../../../images/demo/pages/web.svg";
import contact from "../../../images/demo/pages/contact.svg";

function Metamodel() {
    return (
        <section className="section-left">
            <div className="d-flex align-items-center pt-5 mt-3 w-100">
                <h1 className="mr-auto text-white">MetaModel</h1>
                {/*<div className="button-group">*/}
                {/*    <a href="#" className="button-company d-flex align-items-center position-relative mb-2"*/}
                {/*       target="_blank">*/}
                {/*        <img className="position-absolute mlbi" src={web} alt=""/>*/}
                {/*        <p className="mb-0 mlb m-auto">Website</p>*/}
                {/*    </a>*/}
                {/*    <a href="#" className="button-company d-flex align-items-center position-relative mb-2"*/}
                {/*       target="_blank">*/}
                {/*        <img className="position-absolute mlbi" src={contact} alt=""/>*/}
                {/*        <p className="mb-0 mlb m-auto">Contact</p>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
            <p className="mb-0 pt-3 pb-2 text-white">
                Metamodel by BioME provides means to get the whole picture of patient’s health by analyzing medical
                tests results and giving visual representation of how much individual anatomical systems contribute to
                current health status. Multiple visits to a clinic often result in gradual accumulation of sets of tests
                results that describe different aspects of patient’s health, but are unstructured and often lack
                context. This makes it harder for a doctor to organize and process the data and for a patient to do
                basic interpretation of it. Metamodel turns gathering of patient-generated medical data into continuous
                comprehensive health check-up highlighting situations that need attention from both the doctor and the
                patient. By using Metamodel, it is possible to visualize known conditions in context of systemic risks
                and to reveal pathologic trends and even undiagnosed diseases.
            </p>
            <hr style={{background: "#6B7BB5"}}/>
            <div className="d-flex align-items-center">
                <button onClick={() => document.location.href = "/demo/model/?id=metamodel"}
                        className="model-btn d-flex align-items-center">
                    <p className="mb-0 ml-4">MetaModel</p>
                </button>
            </div>
        </section>
    );
}

export default Metamodel;