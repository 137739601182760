import React, {useEffect, useState} from "react";
import axios from "axios";
import {setting} from "../../../CurrBiometer/Demo/setting";
import InputDate from "../Inputs/InputDate";
import InputNumber from "../Inputs/InputNumber";
import InputSelect from "../Inputs/InputSelect";
import defaultParam from './defaultParam';
import input from './input';
import Result from "../Inputs/Result";

function ASCVD() {
    const apiUrl = setting.apiUrl;
    const [block, setBlock] = useState(1);

    const [birthday_time, setBirthdayTime] = useState(false);
    const [cholesterol, setCholesterol] = useState(false);
    const [sbp, setSbp] = useState(false);
    const [hdl, setHdl] = useState(false);
    const [ldl, setLdl] = useState(false);
    const [gender, setGender] = useState(false);
    const [race, seTrace] = useState(false);
    const [smoking_status, setSmokingStatus] = useState(false);
    const [drugs_status, setDrugsStatus] = useState(false);
    const [diabetes_status, setDiabetesStatus] = useState(false);

    const [result, setResult] = useState([]);
    const [findPram, setFindParam] = useState(true);
    const [isDefault, setDefault] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [defaultTexts, setDefaultTexts] = useState(false);

    useEffect(() => {
        findParam();
    }, [birthday_time,
        cholesterol,
        sbp,
        hdl,
        ldl,
        gender,
        race,
        smoking_status,
        drugs_status,
        diabetes_status]);

    const findParam = () => {
        if (birthday_time &&
            cholesterol &&
            sbp &&
            hdl &&
            ldl &&
            gender &&
            race &&
            smoking_status &&
            drugs_status &&
            diabetes_status) {
            setFindParam(true);
        } else {
            setFindParam(false);
        }
    };

    const start_calculation = () => {
        setShowResult(true);
        const dates = new Date();
        const bt = new Date(birthday_time);
        const year = dates.getFullYear();
        const month = dates.getMonth() + 1;
        if (month <= 9) {
            var month_ = "0" + (Number(month));
        } else {
            var month_ = Number(month);
        }
        const dat = dates.getDate();
        if (dat <= 9) {
            var dat_ = "0" + (Number(dat));
        } else {
            var dat_ = Number(dat);
        }
        const hour = dates.getHours();
        if (hour <= 9) {
            var hour_ = "0" + (Number(hour));
        } else {
            var hour_ = Number(hour);
        }
        const min = dates.getMinutes();
        if (min <= 9) {
            var min_ = "0" + (Number(min));
        } else {
            var min_ = Number(min);
        }
        const sec = dates.getSeconds();
        if (sec <= 9) {
            var sec_ = "0" + (Number(sec));
        } else {
            var sec_ = Number(sec);
        }

        const date = year + '-' + month_ + '-' + dat_ + ' ' + hour_ + ':' + min_ + ':' + sec_;


        const data = {
            "service_name": "biometer",
            "biometer_run_item_id": 11,
            "biometer_run_result_id": 781568844,
            "models": [
                {
                    "model": {
                        "service_name": "biometer",
                        "biometer_run_item_id": 35,
                        "biometer_run_result_id": 78156844,
                        "technical_model_name": "ASCVDRisk13Value",
                        "model_item_id": 5,
                        "biometer_model_item_id": 8979879,
                        "biometer_model_result_id": 789,
                        "birthday_day": bt.getDate(),
                        "birthday_month": bt.getMonth() + 1,
                        "birthday_year": bt.getFullYear(),
                        "gender_id": Number(gender)
                    },
                    "data": [
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 37,
                            "id_result": 313131,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(cholesterol),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        },
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 1581,
                            "id_result": 12312312,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(sbp),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        },
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 38,
                            "id_result": 12312312,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(hdl),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        },
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 39,
                            "id_result": 12312312,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(ldl),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        },
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 1,
                            "id_result": 12312312,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(diabetes_status),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        },
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 2,
                            "id_result": 12312312,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(smoking_status),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        },
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 3,
                            "id_result": 12312312,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(drugs_status),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        },
                        {
                            "arguments_type": "measurements",
                            "id_block_argument": null,
                            "id_result_argument": 6,
                            "id_result": 12312312,
                            "id_anatomy_addon": null,
                            "name_anatomy_addon": null,
                            "id_anatomy_localization": null,
                            "name_anatomy_localization": null,
                            "result": Number(race),
                            "reference_interval_min": 1,
                            "reference_interval_max": 1999,
                            "measure_time": date
                        }
                    ]
                }
            ],
            "create_time": date
        };

        var UH = [];
        const UH_r = JSON.parse(window.localStorage.getItem("usage_history"));

        if (UH_r !== null) {
            UH = UH_r;
        }

        axios.post(apiUrl, data)
            .then(result => {
                setFindParam(false);
                setResult(result.data.models);

                const result_ = {
                    id: Math.floor(Math.random() * (999999 - 111111) + 111111),
                    title: "ASCVD Risk Model",
                    date: dat_ + '-' + month_ + '-' + year,
                    time: hour_ + ':' + min_,
                    result: result.data.models
                };
                UH.push(result_);
                window.localStorage.setItem("usage_history", JSON.stringify(UH));
            });
    };

    function getAge(dateString, min, max) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return !(age < min || age > max);
    }

    const setValueByType = (type, value) => {
        switch (type) {
            case "birthday_time":
                getAge(value, 40, 79) ? setBirthdayTime(value) : setBirthdayTime(false);
                break;
            case "cholesterol":
                setCholesterol(value);
                break;
            case "sbp":
                setSbp(value);
                break;
            case "hdl":
                setHdl(value);
                break;
            case "ldl":
                setLdl(value);
                break;
            case "gender":
                setGender(value);
                break;
            case "race":
                seTrace(value);
                break;
            case "smoking_status":
                setSmokingStatus(value);
                break;
            case "drugs_status":
                setDrugsStatus(value);
                break;
            case "diabetes_status":
                setDiabetesStatus(value);
                break;
        }
    };

    const defaultText = [
        {
            id: 1,
            text: "Male, 55. Follow-up examination of Patient 1 shows high cardiovascular SCORE risk (16%) despite previously chosen risk reduction strategy (statin use). Based on obtained results, a specialist decides to assess additional outcomes with ASCVD Model in order to determine optimal dose of statins."
        },
        {
            id: 2,
            text: "Female, 40. BioMeter is used by a cardiologist to demonstrate to the patient the effect of certain risk factors variations on ASCVD risk."
        }
    ];

    const setDefaultBySelect = (id) => {
        if (id !== "0") {
            const find = defaultParam.find(item => item.id == id);
            const finds = defaultText.find(item => item.id == id);
            setDefaultTexts(finds.text);
            setDefault(id);
            setResult(find.result);
            setBirthdayTime(find.birthday_time);
            setCholesterol(find.cholesterol);
            setSbp(find.sbp);
            setHdl(find.hdl);
            setLdl(find.ldl);
            setGender(find.gender);
            seTrace(find.race);
            setSmokingStatus(find.smoking_status);
            setDrugsStatus(find.drugs_status);
            setDiabetesStatus(find.diabetes_status);

            const dates = new Date();
            const year = dates.getFullYear();
            const month = dates.getMonth() + 1;
            if (month <= 9) {
                var month_ = "0" + (Number(month));
            } else {
                var month_ = Number(month);
            }
            const dat = dates.getDate();
            if (dat <= 9) {
                var dat_ = "0" + (Number(dat));
            } else {
                var dat_ = Number(dat);
            }
            const hour = dates.getHours();
            if (hour <= 9) {
                var hour_ = "0" + (Number(hour));
            } else {
                var hour_ = Number(hour);
            }
            const min = dates.getMinutes();
            if (min <= 9) {
                var min_ = "0" + (Number(min));
            } else {
                var min_ = Number(min);
            }
            const sec = dates.getSeconds();
            if (sec <= 9) {
                var sec_ = "0" + (Number(sec));
            } else {
                var sec_ = Number(sec);
            }

            var UH = [];
            const UH_r = JSON.parse(window.localStorage.getItem("usage_history"));

            if (UH_r !== null) {
                UH = UH_r;
            }

            const result_ = {
                id: Math.floor(Math.random() * (999999 - 111111) + 111111),
                title: "ASCVD Risk Model",
                date: dat_ + '-' + month_ + '-' + year,
                time: hour_ + ':' + min_,
                result: find.result
            };
            UH.push(result_);
            window.localStorage.setItem("usage_history", JSON.stringify(UH));
        } else {
            setDefaultTexts(false);
            setDefault(false);
            setResult([]);
            setBirthdayTime(false);
            setCholesterol(false);
            setSbp(false);
            setHdl(false);
            setLdl(false);
            setGender(false);
            seTrace(false);
            setSmokingStatus(false);
            setDrugsStatus(false);
            setDiabetesStatus(false);

            document.getElementById("default").getElementsByTagName('option')[0].selected = true;

            document.getElementById("input-gender").getElementsByTagName('option')[0].selected = true;
            document.getElementById("input-race").getElementsByTagName('option')[0].selected = true;
            document.getElementById("input-smoking_status").getElementsByTagName('option')[0].selected = true;
            document.getElementById("input-drugs_status").getElementsByTagName('option')[0].selected = true;
            document.getElementById("input-diabetes_status").getElementsByTagName('option')[0].selected = true;

            document.getElementById("input-date").value = " ";
            document.getElementById("input-cholesterol").value = " ";
            document.getElementById("input-sbp").value = " "
            document.getElementById("input-hdl").value = " "
            document.getElementById("input-ldl").value = " "
        }
    };

    const closeResult = () => {
        setShowResult(false);
    };

    const getByType = (type, title, uid, utype, setValueByType, unit, min, max, step, isDefault = false) => {
        switch (type) {
            case "date":
                return <InputDate title={title}
                                  uid={uid}
                                  utype={utype}
                                  setValueByType={setValueByType}
                                  isDefault={isDefault}
                />;
            case "select":
                return <InputSelect title={title}
                                    uid={uid}
                                    unit={unit}
                                    setValueByType={setValueByType}
                                    utype={utype}
                                    isDefault={isDefault}
                />;
            case "number":
                return <InputNumber title={title}
                                    uid={uid}
                                    unit={unit}
                                    utype={utype}
                                    step={step}
                                    setValueByType={setValueByType}
                                    min={min}
                                    max={max}
                                    isDefault={isDefault}
                />;
        }
    };

    const data = [
        {
            ascvd: [
                {title: "Low", text: "No statin recommended"},
                {title: "Moderate", text: "Moderate statin dose recommended"},
                {title: "High", text: "High-intensity statin recommended"},
            ]
        }
    ];

    return (
        <section className="section-left">
            {
                showResult &&
                <Result data={data} result={result} closeResult={closeResult} min={0} max={20} middle={7.5}
                        moderate={5}/>
            }
            <div className="d-flex align-items-center pt-5 pb-3 mt-3 w-100">
                <h1 className="mr-auto text-white">ASCVD Risk Model</h1>
            </div>

            <div className="switch_block">
                <div className="d-flex align-items-center ml-auto" style={{width: "fit-content"}}>
                    <button onClick={() => setBlock(1)}
                            className={block === 1 ? "switch-btn active" : "switch-btn"}>description
                    </button>
                    <button onClick={() => setBlock(2)}
                            className={block === 2 ? "switch-btn active" : "switch-btn"}>context
                    </button>
                    <button onClick={() => setBlock(3)}
                            className={block === 3 ? "switch-btn active" : "switch-btn"}>reference
                    </button>
                </div>
                {
                    block === 1 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            Atherosclerotic cardiovascular disease (ASCVD) results in a number of clinical outcomes,
                            both fatal and non-fatal. ASCVD Risk model estimates 10-year risk of any of the following:
                            coronary death, non-fatal myocardial infarction, stroke (fatal or non-fatal). The model is
                            based on clinically validated Pooled Cohort Equations and uses patient’s medical history and
                            several common laboratory tests results as input.
                        </p>
                    </div>
                }
                {
                    block === 2 &&
                    <div className="item-switch">
                        <p className="mb-0">
                            ASCVD Risk model use is critical for implementing the 2013 American College of
                            Cardiology/American Heart Association guidelines in clinical practice. The model should be
                            used in patients without ASCVD, having LDL-C levels {"<"} 190 mg/dL (4.92 mmol/L) and taking
                            LDL-C lowering medications.
                        </p>
                    </div>
                }
                {
                    block === 3 &&
                    <div className="item-switch">
                        <ol>
                            <li className="mb-0">
                                Goff DC Jr, et. al. 2013 ACC/AHA Guideline on the Assessment of Cardiovascular Risk A
                                Report of the American College of Cardiology/American Heart Association Task Force on
                                Practice Guidelines. Circulation. 2014 Jun 24;129(25 Suppl 2):S49-73. doi:
                                10.1161/01.cir.0000437741.48606.98. Epub 2013 Nov 12.
                            </li>
                            <li className="mb-0">
                                Stone NJ, et al.Circulation. 2013 ACC/AHA guideline on the treatment of blood
                                cholesterol
                                to reduce atherosclerotic cardiovascular risk in adults: a report of the American
                                College of
                                Cardiology/American Heart Association Task Force on Practice Guidelines. 2014 Jun
                                24;129(25
                                Suppl 2):S1-45. doi: 10.1161/01.cir.0000437738.63853.7a. Epub 2013 Nov 12
                            </li>
                        </ol>
                    </div>
                }
            </div>
            <div className="parameter">
                <div className="select-wrapper">
                    <select id="default" className="form-control default-case"
                            onChange={(e) => setDefaultBySelect(e.target.value)}>
                        <option value="0">Select case</option>
                        <option value="1">Patient 1. Male, 55 years old</option>
                        <option value="2">Patient 2. Female, 40 years old</option>
                    </select>
                    <div className="select"/>
                </div>
                {
                    isDefault &&
                    <div className="about-default">
                        {defaultTexts}
                    </div>
                }
                <div className="text-center pt-3">

                    <div className="d-flex align-items-center text-left flex-wrap">
                        {
                            input.map((i) =>
                                isDefault ?
                                    getByType(i.type, i.title, i.uid, i.utype, setValueByType, i.unit, i.min, i.max, i.step, defaultParam[isDefault - 1]) :
                                    getByType(i.type, i.title, i.uid, i.utype, setValueByType, i.unit, i.min, i.max, i.step)
                            )
                        }
                    </div>
                    {
                        isDefault ?
                            <button className="submit-model mt-4" onClick={() => setShowResult(true)}>Submit</button> :
                            findPram ?
                                <button className="submit-model mt-4"
                                        onClick={() => start_calculation()}>Submit</button> :
                                <button className="submit-model mt-4 disabled" disabled>Submit</button>
                    }
                </div>
            </div>
        </section>
    );
}

export default ASCVD;