
    import React from "react";
    import {useSelector} from "react-redux";

function News3() {

    const data = useSelector(state => state.load.data);

    return (
        <div className="container">
            <div className="current_img">
                <img className="curr_img_news" src="../images/news/3.jpg" alt=""/>
            </div>
            <div className="current_new">
                <p className="begin_current_news">{data && data.News[0].news3[0]}</p>
                <p className="end_current_news">
                    {data && data.News[0].news3[1]}
                    <br/>
                    <br/>
                </p>
            </div>
        </div>
    );
}

export default News3;
