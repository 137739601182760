import React, {useState} from "react";

function InputNumber({title, setValueByType, min, max, unit, uid, utype, step, isDefault, block}) {
    const [error, setError] = useState(false);

    const getError = (value) => {
        if (value.length > 0) {
            if (value < min) {
                setError(true);
            } else if (value > max) {
                setError(true);
            } else {
                setError(false);
            }
        } else {
            setError(false);
        }
    };

    return (
        block ?
            <div className="d-grid mr-1 mb-3">
                <p className={error ? "mb-0 title-input-model error-input" : "mb-0 title-input-model"} dangerouslySetInnerHTML={{__html: title}}></p>
                <div className="d-flex align-items-center">
                    <input id={uid} onChange={(e) => setValueByType(utype, e.target.value, getError(e.target.value))}
                           className={error ? "input-input-model no-pointer error-input" : "input-input-model no-pointer"} type="number"
                           min={min} max={max} step={step} value={isDefault[utype]}/>
                    <p className="input-unit-model">{unit}</p>
                </div>
            </div> :
        isDefault ?
            <div className="d-grid mr-1 mb-3">
                <p className="mb-0 title-input-model" dangerouslySetInnerHTML={{__html: title}}></p>
                <div className="d-flex align-items-center">
                    <input id={uid} onChange={(e) => setValueByType(utype, e.target.value, getError(e.target.value))}
                           className="input-input-model pointer-event-none" type="number"
                           min={min} max={max} step={step} value={isDefault[utype]}/>
                    <p className="input-unit-model">{unit}</p>
                </div>
            </div> :
            <div className="d-grid mr-1 mb-3">
                <p className={error ? "mb-0 title-input-model error-input" : "mb-0 title-input-model"} dangerouslySetInnerHTML={{__html: title}}></p>
                <div className="d-flex align-items-center">
                    <input id={uid} onChange={(e) => setValueByType(utype, e.target.value, getError(e.target.value))}
                           className={error ? "input-input-model error-input" : "input-input-model"} type="number"
                           min={min} max={max} step={step}/>
                    <p className={error ? "input-unit-model error-input" : "input-unit-model"}>{unit}</p>
                </div>
            </div>
    );
}

export default InputNumber;