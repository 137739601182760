import React from "react";
import close from "../../../../images/demo/pages/closeresult.svg";
import ResultViewThree from "../ResultViewThree";

function ResultHistory({result, min, max, middle, moderate, data, print}) {
    return (
        result.length > 0 &&
        <div className="result-model-history pt-2">
            <div className="form-result-history">
                    {
                        result.map((r, index) =>
                                r.data ?
                                <div key={index} className="mb-4 mt-4">
                                    {
                                        r.data.map((rd, index) =>
                                                <div key={index}>
                                                    <ResultViewThree print={print} dataText={data[0].ascvd} max={max} min={min} middle={middle} moderate={moderate} data={rd.result}/>
                                                </div>
                                        )
                                    }
                                </div> :
                                    <div key={index} className="mb-4 mt-4">
                                        <h1 className="title-result text-danger">Data error</h1>
                                    </div>
                        )
                    }
            </div>
        </div>
    );
}

export default ResultHistory;