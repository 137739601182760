
    import React from "react";
    import Menu from "../Menu/Menu";
    import MobileMenu from "../MobileMenu/MobileMenu";
    import Footer from "../Footer/Footer";
    import TopArrow from "../TopArrow/TopArrow";
    import News1 from "./News1";
    import News2 from "./News2";
    import News3 from "./News3";
    import News4 from "./News4";
    import {useHistory} from "react-router";
    import {useSelector} from "react-redux";
    
    function News() {

        const history = useHistory();
        const curr = history.location.pathname;
        const currs = curr.split('/');
        const data = useSelector(state => state.load.data);

        if (currs['2'] === 'news1') {
            return (
                <div>
                    <title>{data && data.News[0].pageTitle}</title>
                    <MobileMenu history={history}/>
                    <Menu history={history}/>
                    <div className="pt-5"/>
                    <div className="pt-5"/>
                    <News1/>
                    <Footer/>
                    <TopArrow/>
                </div>
            );
        } else if (currs['2'] === 'news2') {
            return (
                <div>
                    <title>{data && data.News[0].pageTitle}</title>
                    <MobileMenu history={history}/>
                    <Menu history={history}/>
                    <div className="pt-5"/>
                    <div className="pt-5"/>
                    <News2/>
                    <Footer/>
                    <TopArrow/>
                </div>
            );
        } else if (currs['2'] === 'news3') {
            return (
                <div>
                    <title>{data && data.News[0].pageTitle}</title>
                    <MobileMenu history={history}/>
                    <Menu history={history}/>
                    <div className="pt-5"/>
                    <div className="pt-5"/>
                    <News3/>
                    <Footer/>
                    <TopArrow/>
                </div>
            );
        } else if (currs['2'] === 'news4') {
            return (
                <div>
                    <title>{data && data.News[0].pageTitle}</title>
                    <MobileMenu history={history}/>
                    <Menu history={history}/>
                    <div className="pt-5"/>
                    <div className="pt-5"/>
                    <News4/>
                    <Footer/>
                    <TopArrow/>
                </div>
            );
        }

    }

export default News;
