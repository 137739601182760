
// function getApiAdres() {
//     return "http://auth/";
// }

function getApiAdres() {
    return document.location.origin+"/function/Users.php/";
}

// function getApiAdres() {
//     return "https://"+document.location.hostname+"/function/Users.php/";
// }

export const setting_auth = {
    apiUrl: getApiAdres()
};