
    import React from "react";
    import {useSelector} from "react-redux";
    import LangButton from "../LangButton/LangButton";
    import {useHistory} from "react-router";

    function MobileMenu() {

        const history = useHistory();
        const curr = history.location.pathname;

        const data = useSelector(state => state.load.data);

        return(
            <div>
                <div className="top_menu_mobile">
                    <div className="bourger active">
                        <div className="item_bourger"></div>
                        <div className="item_bourger"></div>
                        <div className="item_bourger"></div>
                    </div>
                    <div className="bourger_cross">
                        <div className="item_bourger_cross_left"></div>
                        <div className="item_bourger_cross_right"></div>
                    </div>
                </div>
                <div>
                    <div className="aside_mobile">
                        <a className={curr === 'products' ? "top_menu_href_aside active noclick product act" : 'top_menu_href_aside noclick product'}
                           href="/products/flowviewer">
                            {data && data.Menu[0].items[0]}
                        </a>
                        <a className={curr === 'products' ? "top_menu_href_aside active noclick product act" : 'top_menu_href_aside noclick product'}
                           href="/products/biometer">
                            {data && data.Menu[0].items[1]}
                        </a>
                        {/* 
                        <a className={curr === 'project' ? "top_menu_href_aside active noclick project act" : 'top_menu_href_aside noclick project'}
                           href="/project">
                            {data && data.Menu[0].items[1]}
                        </a>
                         */}
                        <a className={curr === '/contact' ? "top_menu_href_aside active act" : 'top_menu_href_aside'}
                           href="/contact">
                            {data && data.Menu[0].items[3]}
                        </a>
                        <a className={curr === '/products/biometer/demo' ? "top_menu_href_aside active act" : 'top_menu_href_aside'}
                           href="/demo">
                           {data && data.Menu[0].items[6]}
                        </a>
                        {/* 
                        <LangButton />
                        */}
                    </div>
                </div>
            </div>
        )
    }

export default MobileMenu;
