import React from "react";
import back from '../../../../images/demo/icon/back.svg';
import '../../../../demo-page.css'
import usage from '../../../../images/demo/icon/usage.svg';

function AsideMenu({backTo, query, pathname}) {
    const model = [
        {id: 1, status: true, href: "/demo/system/?id=cardiovascular", title: "Cardiovascular System"},
        {id: 2, status: true, href: "/demo/system/?id=endocrine", title: "Endocrine System"},
        {id: 10, status: true, href: "/demo/system/?id=respiratory", title: "Respiratory System"},
        {id: 3, status: false, href: "/demo/system/?id=reproductive", title: "Reproductive system & prenatal risks"},
        {id: 9, status: false, href: "/demo/system/?id=#", title: "Digestive System"},
        {id: 11, status: false, href: "/demo/system/?id=#", title: "Urinary System"},
    ];

    const company = [
        {id: 1, href: "/demo/company/?id=biome", title: "BioME Models"},
        {id: 3, href: "/demo/company/?id=metamodel", title: "MetaModel"},
        {id: 2, href: "/demo/company/?id=partners", title: "BioME Collaborations"},
        {id: 4, href: "/demo/company/?id=clinically", title: "Clinical Risk Assessment Tools"}
    ];

    const usage_history = [
        {id: 1, href: "/demo/history/", title: "Usage history"},
    ];

    const getActive = (href) => {
        const p = href.split("?");
        const pa = p[0];
        const q = p[1].split("=");
        const qu = q[1];

        return pathname === pa && qu === query;
    };

    return (
        <div>
            <div className="d-flex align-items-center pb-3">
                <a href={backTo}>
                    <img className="on-hover" src={back} alt=""/>
                </a>
                <p className="mb-0 demo-page-title ml-3">DEMO</p>
            </div>
            <aside className="shadow">
                <div className="d-grid">
                    <div className="title-aside">
                        <p className="title-aside-text mb-0">Anatomical Systems</p>
                    </div>
                    {
                        model.map((m) =>
                            m.status ?
                                <a href={m.href} key={m.id}>
                                    <div
                                        className={getActive(m.href) ? "item-aside on-hover-aside active" : "item-aside on-hover-aside"}>
                                        <p className={getActive(m.href) ? "item-aside-text mb-0 active" : "item-aside-text mb-0"}>{m.title}</p>
                                    </div>
                                </a> :
                                <div key={m.id}>
                                    <div
                                        className={getActive(m.href) ? "item-aside on-hover-aside active" : "item-aside-disabled on-hover-aside"}>
                                        <p className={getActive(m.href) ? "item-aside-text mb-0 active" : "item-aside-text-disabled mb-0"}>{m.title}</p>
                                    </div>
                                </div>
                        )
                    }
                    <div className="title-aside">
                        <p className="title-aside-text mb-0">Model Types</p>
                    </div>
                    {
                        company.map((m) =>
                            <a href={m.href} key={m.id}>
                                <div
                                    className={getActive(m.href) ? "item-aside on-hover-aside active" : "item-aside on-hover-aside"}>
                                    <p className={getActive(m.href) ? "item-aside-text mb-0 active" : "item-aside-text mb-0"}>{m.title}</p>
                                </div>
                            </a>
                        )
                    }
                </div>

                <a href="/demo/history" className="mt-5 d-block">
                    <div className="item-aside-usage on-hover-aside active">
                        <p className="item-aside-text-usage mb-0 active">
                            <img className="mr-2" src={usage} alt=""/>
                            Usage history</p>
                    </div>
                </a>


            </aside>
        </div>
    );
}

export default AsideMenu;