import React from "react";
import {useSelector} from "react-redux";

function News2() {

    const data = useSelector(state => state.load.data);

    return (
        <div className="container">
            <div className="current_img">
                <img className="curr_img_news" src="../images/news/2.jpg" alt=""/>
            </div>
            <div className="current_new">
                <p className="begin_current_news">{data && data.News[0].news2[0]}</p>
                <p className="end_current_news">
                    {data && data.News[0].news2[1]}
                    <br/>
                    <br/>
                    {data && data.News[0].news2[2]}
                    <br/>
                    <br/>
                    {data && data.News[0].news2[3]}
                    <br/>
                    <br/>
                    {data && data.News[0].news2[4]}
                    <br/>
                    <br/>
                    {data && data.News[0].news2[5]}
                    <br/>
                    <br/>
                    {data && data.News[0].news2[6]}
                    <br/>
                    <br/>
                    {data && data.News[0].news2[7]}
                    <br/>
                    <br/>
                    {data && data.News[0].news2[8]}
                    <br/>
                    <br/>
                </p>
            </div>
        </div>
    );
}

export default News2;
